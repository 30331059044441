import React, { useCallback, useEffect, useState } from "react";
import { Button, Input, Modal, Space } from "antd";
import { EditOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import ApiUtils from "../../../utils/ApiUtils";
import AppUtils from "../../../utils/AppUtils";
import * as yup from "yup";
import DataTable from "../../../shared/components/DataTable/DataTable";
import DocumentTypeEditExternalField from "./DocumentTypeEditExternalField";
import SelectDepartmentWithAll from "../Departments/SelectDepartmentWithAll";

function DocumentTypeEdit({
  selectedDocType,
  isModalEditVisible,
  handleAfterSaved,
  handleEditCancel,
}) {
  const [editingLoading, setEditingLoading] = useState(false);
  const validationSchema = yup.object({
    DocumentTypeKey: yup.string().required(),
  });

  const formik = useFormik({
    initialValues: {
      DocumentTypeKey: "",
      DocumentTypeName: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setEditingLoading(true);
      if (values.DocumentTypeID) {
        const newValues = { ...values };
        delete newValues.DepartmentID;
        ApiUtils.documentTypeUpdate(newValues, (responseData) => {
          setEditingLoading(false);
          if (responseData?.Message) {
            AppUtils.showMessage("Thông báo", responseData?.Message);
          }
          if (!responseData?.Success) {
            return;
          }
          handleAfterSaved && handleAfterSaved();
        });
      } else {
        ApiUtils.documentTypeInsert(values, (responseData) => {
          AppUtils.showMessage("Thông báo", responseData?.Message);
          setEditingLoading(false);
          if (responseData?.Message) {
            AppUtils.showMessage("Thông báo", responseData?.Message);
          }
          if (!responseData?.Success) {
            return;
          }
          handleAfterSaved && handleAfterSaved();
        });
      }
    },
  });

  const [deleteLoading, setDeleteLoading] = useState(false);
  const deleteDocumentTypeHandle = () => {
    if (window.confirm("Xác nhận xóa loại văn bản này?")) {
      setDeleteLoading(true);
      ApiUtils.documentTypeDelete(
        selectedDocType.DocumentTypeID,
        (responseData) => {
          setDeleteLoading(false);
          if (responseData?.Message) {
            AppUtils.showMessage("Thông báo", responseData?.Message);
          }
          if (!responseData?.Success) {
            return;
          }

          setDeleteLoading(false);
          handleAfterSaved && handleAfterSaved();
        }
      );
    }
  };

  const changeToDefault = () => {
    setEditingLoading(true);
    ApiUtils.documentTypeUpdateToRoot(
      selectedDocType.DocumentTypeID,
      (responseData) => {
        AppUtils.showMessage("Thông báo", responseData?.Message);
        setEditingLoading(false);
        handleAfterSaved && handleAfterSaved();
      }
    );
  };

  useEffect(() => {
    if (!selectedDocType) {
      return;
    }
    setEditingLoading(false);
    fields_InitColumns();
    formik.setValues({
      ...selectedDocType,
      DepartmentID: selectedDocType.DocumentTypeID
        ? selectedDocType.DepartmentID
        : AppUtils.ROOT_ID,
    });
    fields_getData();
  }, [selectedDocType]);

  //Fields
  const [fieldCols, setFieldCols] = useState([]);
  const [fields, setFields] = useState([]);
  const [selectedField, setSelectedField] = useState({});
  const [fieldEditModal, setFieldEditModal] = useState(false);
  const fields_getData = () => {
    if (!selectedDocType.DocumentTypeID) {
      setFields([]);
      return;
    }
    ApiUtils.documentTypeFieldGet(
      selectedDocType.DocumentTypeID,
      (responseData) => {
        if (responseData.length === 1 && !responseData[0].Success) {
          if (responseData[0].Message === AppUtils.TOKEN_EXPIRED_MESSAGE) {
            AppUtils.logout();
            return;
          }
          AppUtils.showMessage("Thông báo", responseData[0].Message);
          return;
        }
        setFields(responseData);
      }
    );
  };
  const fields_InitColumns = () => {
    let cols = [
      {
        title: "Thứ tự",
        dataIndex: "SortNumber",
        key: "SortNumber",
      },
      {
        title: "Từ khóa",
        key: "FieldKey",
        render: (_, record) =>
          record?.AllowEdit ? (
            <div>
              <a
                href="javascript:void(0);"
                onClick={() => fieldEdit(record.FieldID)}
                style={{ color: "#242526" }}
              >
                {record.FieldKey}
              </a>
            </div>
          ) : (
            <div>{record.FieldKey}</div>
          ),
      },
      {
        title: "Tên hiển thị",
        key: "FieldTitle",
        render: (_, record) =>
          record?.AllowEdit ? (
            <div>
              <a
                href="javascript:void(0);"
                onClick={() => fieldEdit(record.FieldID)}
                style={{ color: "#242526" }}
              >
                {record.FieldTitle}
              </a>
            </div>
          ) : (
            <div>{record.FieldTitle}</div>
          ),
      },
      {
        title: "Kiểu dữ liệu",
        dataIndex: "DataFormat",
        key: "DataFormat",
      },
      {
        title: "",
        key: "action",
        render: (_, record) =>
          record?.AllowEdit ? (
            <Space size="middle">
              <Button
                icon={<EditOutlined />}
                onClick={() => fieldEdit(record.FieldID)}
              ></Button>
            </Space>
          ) : (
            ""
          ),
      },
    ];
    setFieldCols(cols);
  };

  const fieldEdit = (FieldID) => {
    ApiUtils.documentTypeFieldGetById(FieldID, (responseData) => {
      if (!responseData?.Success) {
        AppUtils.showMessage("Thông báo", responseData?.Message);
        return;
      }
      setSelectedField(responseData);
      setFieldEditModal(true);
    });
  };
  const fieldSaved = () => {
    setFieldEditModal(false);
    fields_getData();
  };
  const fieldEditCancel = () => {
    setFieldEditModal(false);
  };
  const fieldAddNew = () => {
    if (!selectedDocType?.DocumentTypeID) {
      AppUtils.showMessage("Cảnh báo", "Lưu mới loại văn bản trước!");
      return;
    }
    if (!selectedDocType?.AllowEdit) {
      AppUtils.showMessage("Cảnh báo", "Không có quyền!");
      return;
    }
    if (
      selectedDocType?.DocumentTypeMaxFields > 0 &&
      fields?.length >= selectedDocType.DocumentTypeMaxFields
    ) {
      AppUtils.showMessage(
        "Cảnh báo",
        `Hệ thống chỉ cho phép tối đa ${selectedDocType.DocumentTypeMaxFields} trường đặc tả`
      );
      return;
    }
    setSelectedField({ FieldID: "" });
    setFieldEditModal(true);
  };

  const onSelectDepartment = useCallback(
    (department) => {
      formik.setFieldValue("DepartmentID", department.DepartmentID);
    },
    [formik]
  );

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Modal
          title="Loại văn bản"
          visible={isModalEditVisible}
          onOk={formik.submitForm}
          onCancel={handleEditCancel}
          maskClosable={false}
          footer={[
            <Button
              key="submit"
              type="primary"
              loading={editingLoading}
              onClick={formik.submitForm}
            >
              Lưu
            </Button>,
            <Button
              type="primary"
              disabled={
                !selectedDocType?.AllowEdit || !AppUtils.userIsSystemAdmin()
              }
              onClick={changeToDefault}
            >
              Chuyển Mặc định
            </Button>,
            <Button
              danger
              loading={deleteLoading}
              disabled={!selectedDocType?.AllowEdit}
              onClick={deleteDocumentTypeHandle}
            >
              Xóa
            </Button>,
            <Button key="back" onClick={handleEditCancel}>
              Thoát
            </Button>,
          ]}
        >
          <br />
          Mã{" "}
          <Input
            name="DocumentTypeKey"
            value={formik.values.DocumentTypeKey}
            onChange={formik.handleChange}
          />
          <br />
          Tên{" "}
          <Input
            name="DocumentTypeName"
            value={formik.values.DocumentTypeName}
            onChange={formik.handleChange}
          />
          {AppUtils.userIsSystemAdmin() && !selectedDocType.DocumentTypeID ? (
            <>
              Đơn vị{" "}
              <SelectDepartmentWithAll
                simpleMode
                handleSelectDepartment={onSelectDepartment}
                selectedDepartment={{
                  DepartmentID: formik.values.DepartmentID,
                }}
              />
            </>
          ) : (
            formik.values?.DepartmentName
          )}
          <h3 style={{ paddingTop: "10px" }}>Đặc tả</h3>
          <DataTable data={fields} columns={fieldCols}></DataTable>
          <DocumentTypeEditExternalField
            selectedField={selectedField}
            documentTypeId={selectedDocType?.DocumentTypeID}
            isModalEditVisible={fieldEditModal}
            handleAfterSaved={fieldSaved}
            handleEditCancel={fieldEditCancel}
          />
          <Button onClick={fieldAddNew}>Thêm đặc tả</Button>
        </Modal>
      </form>
    </>
  );
}

DocumentTypeEdit.propTypes = {
  selectedDocType: PropTypes.object,
  isModalEditVisible: PropTypes.bool,
  handleAfterSaved: PropTypes.func,
  handleEditCancel: PropTypes.func,
};

export default DocumentTypeEdit;
