import { Button, Input, Space } from "antd";
import React, { useState, useEffect, useCallback, useRef } from "react";
import { FileTextOutlined } from "@ant-design/icons";
import ApiUtils from "../../../utils/ApiUtils";
import AppUtils from "../../../utils/AppUtils";
import PageContainer from "../../../shared/components/PageContainer/PageContainer";
import AdministrativeProcedureDetail from "./AdministrativeProcedureDetail";
import DataTable from "../../../shared/components/DataTable/DataTable";
import {
  SearchOutlined,
  FileExcelOutlined,
  FileWordOutlined,
} from "@ant-design/icons";
import styled from "styled-components";

const CustomerInfoWrapper = styled.div`
  cursor: ${({ selectable }) => (selectable ? "pointer" : "initial")};
`;

const DEFAULT_PAGINATION = {
  current: 1,
  pageSize: 10,
};

function DicOfAdministrativeProcedures({ handleCustomerSelected }) {
  const [aps, setAPS] = useState([{ APID: "" }]);
  const [selectedAP, setSelectedAP] = useState({ APID: "" });
  const [isModalDetailVisible, setModalDetailVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [searchAPCode, setSearchAPCode] = useState("");
  const [searchAPName, setSearchAPName] = useState("");
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [total, setTotal] = useState(0);

  const columns = [
    {
      title: "Mã",
      dataIndex: "APCode",
      key: "APCode",
    },
    {
      title: "Tên",
      key: "APName",
      render: (_, record) =>
        handleCustomerSelected ? (
          <CustomerInfoWrapper
            onClick={() => {
              handleCustomerSelected && handleCustomerSelected(record);
            }}
          >
            <a
              href="javascript:void(0);"
              onClick={() => {
                !handleCustomerSelected && openAP(record.APID);
              }}
              style={{ color: "#242526" }}
            >
              {record.APName}
            </a>
          </CustomerInfoWrapper>
        ) : <>{record.APName}</>
    },
    {
      title: "",
      key: "action",
      render: (_, record) =>
        <Space size="middle">
          <Button
            icon={<FileTextOutlined />}
            onClick={() => openAP(record.APID)}>
            Chi tiết
          </Button>
        </Space>
    },
  ];

  useEffect(() => {
    searchData();
  }, [pagination]);

  const searchData = useCallback(() => {
    setLoading(true);
    const { current, pageSize } = pagination;
    let searchData = { APCode: searchAPCode, APName: searchAPName };
    ApiUtils.administrativeProcedureSearch(
      (current - 1) * pageSize,
      pageSize,
      searchData,
      (responseData) => {
        setLoading(false);
        if (responseData.length === 1 && !responseData[0].Success) {
          if (responseData[0].Message === AppUtils.TOKEN_EXPIRED_MESSAGE) {
            AppUtils.logout();
            return;
          }
          AppUtils.showMessage("Thông báo", responseData[0].Message);
          return;
        }
        setAPS(responseData);
        const currentTotal = responseData[0]?.TotalRecords || 0;
        if (currentTotal === total) {
          return;
        }
        setTotal(currentTotal);
      }
    );
  }, [searchAPCode, searchAPName, pagination, total]);

  const handleTableChange = useCallback(
    (pagination) => {
      setPagination(pagination);
      setTotal(pagination.total);
    },
    [setPagination, setTotal]
  );

  const handleDetailCancel = () => {
    setModalDetailVisible(false);
  };

  const handleExternalSearchChange = useCallback(() => { });

  const openAP = (id) => {
    ApiUtils.administrativeProcedureGetById(id, (responseData) => {
      if (!responseData?.Success) {
        AppUtils.showMessage("Thông báo", responseData.Message);
        return;
      }
      setSelectedAP(responseData);
      setModalDetailVisible(true);
    });
  };

  //Export-Data
  const exportExcel = () => {
    let searchData = { APCode: searchAPCode, APName: searchAPName };
    ApiUtils.administrativeProcedureSearchExport(searchData, (responseData) => {
      if (!responseData?.Success) {
        AppUtils.showMessage("Thông báo", responseData?.Message);
        return;
      }
      AppUtils.excelDownloadXLS(responseData, responseData.Name);
    });
  };
  const exportDocx = () => {
    let searchData = { APCode: searchAPCode, APName: searchAPName };
    ApiUtils.administrativeProcedureSearchExport(searchData, (responseData) => {
      if (!responseData?.Success) {
        AppUtils.showMessage("Thông báo", responseData?.Message);
        return;
      }
      AppUtils.docExport(responseData, responseData.Name);
    });
  };

  return (
    <PageContainer>
      <div className="row">
        <div className="col-md-12">
          Mã{" "}
          <Input
            value={searchAPCode}
            onChange={(event) => {
              setSearchAPCode(event.target.value);
            }}
          />
          <br />
          <br />
          Tên{" "}
          <Input
            value={searchAPName}
            onChange={(event) => {
              setSearchAPName(event.target.value);
            }}
          />
          <br />
          <br />
          <Button icon={<SearchOutlined />} onClick={() => searchData()}>
            Tra cứu
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <DataTable
            loading={loading}
            data={aps}
            columns={columns}
            searchText={searchText}
            isSearchable={false}
            handleTableChange={handleTableChange}
            pagination={{ ...pagination, total }}
          />
        </div>
      </div>
      {!handleCustomerSelected && (
        <div className="row">
          <div className="col-md-12">
            <Button
              className="psc-center-icon"
              onClick={exportExcel}
              icon={<FileExcelOutlined />}
            >
              Excel
            </Button>{" "}
            <Button
              className="psc-center-icon"
              onClick={exportDocx}
              icon={<FileWordOutlined />}
            >
              Word
            </Button>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-md-12">
          <AdministrativeProcedureDetail
            isModalDetailVisible={isModalDetailVisible}
            selectedAP={selectedAP}
            handleDetailCancel={handleDetailCancel}
          ></AdministrativeProcedureDetail>
        </div>
      </div>
    </PageContainer>
  );
}
export default DicOfAdministrativeProcedures;
