import React, { useEffect, useState } from "react";
import { Button, Input, Modal } from "antd";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import ApiUtils from "../../../utils/ApiUtils";
import AppUtils from "../../../utils/AppUtils";
import * as yup from "yup";

function RecordFreezeNew({
  editingRecord,
  isModalVisible,
  handleAfterSaved,
  handleCancel,
}) {
  const [editingLoading, setEditingLoading] = useState(false);

  const validationSchema = yup.object({
    FreezeContent: yup.string().required(),
  });

  const formik = useFormik({
    initialValues: {
      ReceiptKey: "",
      Sumary: "",
      FreezeContent: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setEditingLoading(true);
      ApiUtils.recordFreezeInsert(values, (data) => {
        AppUtils.showMessage("Thông báo", data?.Message);
        setEditingLoading(false);
      });
      handleAfterSaved && handleAfterSaved();
    },
  });

  useEffect(() => {
    if (!editingRecord) {
      return;
    }
    formik.setValues(editingRecord);
  }, [editingRecord]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Modal
        title="Đóng băng hồ sơ"
        visible={isModalVisible}
        onOk={formik.submitForm}
        onCancel={handleCancel}
        maskClosable={false}
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={editingLoading}
            onClick={formik.submitForm}
          >
            Tiến hành đóng băng
          </Button>,
          <Button key="back" onClick={handleCancel}>
            Thoát
          </Button>,
        ]}
      >
        <br />
        Số biên nhận{" "}
        <Input
          name="ReceiptKey"
          value={formik.values.ReceiptKey}
          onChange={formik.handleChange}
          readOnly={true}
        />
        <br />
        Trích yếu hồ sơ{" "}
        <Input
          name="Sumary"
          value={formik.values.Sumary}
          onChange={formik.handleChange}
          readOnly={true}
        />
        Lý do{" "}
        <Input
          name="FreezeContent"
          value={formik.values.FreezeContent}
          onChange={formik.handleChange}
        />
      </Modal>
    </form>
  );
}

RecordFreezeNew.propTypes = {
  editingRecord: PropTypes.object,
  isModalVisible: PropTypes.bool,
  handleAfterSaved: PropTypes.func,
  handleCancel: PropTypes.func,
};

export default RecordFreezeNew;
