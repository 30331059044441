import React from "react";
import { Navigate } from "react-router-dom";

// Department
import Dashboard from "../components/HomePage/Dashboard/Dashboard";
import SystemAdminDashboard from "../components/HomePage/Dashboard/SystemAdminDashboard";
import DepartmentAdminDashboard from "../components/HomePage/Dashboard/DepartmentAdminDashboard";

import Records from "../components/HomePage/Records/Records";
import RecordBelowWarningDay from "../components/HomePage/Records/RecordBelowWarningDay";
import RecordDetail from "../components/HomePage/Records/RecordDetail";
import RecordCollect from "../components/HomePage/RecordCollect/RecordCollect";

import Documents from "../components/HomePage/Documents/Documents";
import DocumentExpiring from "../components/HomePage/Documents/RecordExpiring";
import RecordUnlockRequests from "../components/HomePage/RecordUnlockRequests/RecordUnlockRequests";
import RecordFreezes from "../components/HomePage/RecordFreezes/RecordFreezes";
import RecordBatchJobs from "../components/HomePage/RecordBatchJobs/RecordBatchJobs";

import DicOfStorageLife from "../components/HomePage/StorageLifes/DicOfStorageLife";
import DicOfDocumentType from "../components/HomePage/DocumentTypes/DicOfDocumentType";
import DicOfAdministrativeProcedures from "../components/HomePage/AdministrativeProcedures/DicOfAdministrativeProcedures";
import DicOfCustomers from "../components/HomePage/Customers/DicOfCustomers";

import DocumentDOwnloadLogTotal from "../components/HomePage/Reports/DocumentDownloadLogTotal";
import ReportLogTitleCount from "../components/HomePage/Reports/ReportLogTitleCount";
import LogSearch from "../components/HomePage/Log/LogSearch";

import Users from "../components/HomePage/Users/Users";
import Departments from "../components/HomePage/Departments/Departments";
import Settings from "../components/HomePage/Config/Settings";
import SystemSettings from "../components/HomePage/Config/SystemSettings";
import AccountInfo from "../components/HomePage/Config/AccountInfo";

//Auth
import Login from "../components/LoginPage/LoginPage";
import AppUtils from "../utils/AppUtils";

const userRoutes = [
  { path: "/Records", component: Records }, //Danh sách hồ sơ
  { path: "/Record/:id", component: RecordDetail }, //Chi tiết hồ sơ
  { path: "/RecordBelowWarningDay", component: RecordBelowWarningDay }, //Thu thập hồ sơ
  { path: "/RecordCollect", component: RecordCollect }, //Thu thập hồ sơ
  { path: "/Documents", component: Documents }, //Danh sách văn bản
  { path: "/DocumentExpiring", component: DocumentExpiring }, //Văn bản sắp hết hạn
  { path: "/RecordUnlockRequests", component: RecordUnlockRequests }, //Duyệt chỉnh sửa hồ sơ
  { path: "/RecordFreezes", component: RecordFreezes }, //Đóng băng hồ sơ
  { path: "/RecordBatchJobs", component: RecordBatchJobs }, //Xử lý hồ sơ
  { path: "/DicOfDocumentTypes", component: DicOfDocumentType }, //TĐ Loại văn bản
  { path: "/DicOfStorageLifes", component: DicOfStorageLife }, //TĐ Thời hạn bảo quản
  {
    path: "/DicOfAdministrativeProcedures",
    component: DicOfAdministrativeProcedures,
  }, //TĐ Thủ tục hành chính
  { path: "/DicOfCustomers", component: DicOfCustomers }, //TĐ Khách hàng
  { path: "/Users", component: Users }, //Tài khoản người dùng
  { path: "/Departments", component: Departments }, //Danh sách đơn vị
  { path: "/Settings", component: Settings }, //Cấu hình
  { path: "/SystemSettings", component: SystemSettings }, //Cấu hình hệ thống
  { path: "/AccountInfo", component: AccountInfo }, //Thông tin tài khoản

  {
    path: "/ReportDocumentDownloadLogTotal",
    component: DocumentDOwnloadLogTotal,
  }, //Thống kê lượt tải
  { path: "/ReportLogTitleCount", component: ReportLogTitleCount }, //Lượt tương tác hệ thống
  { path: "/ReportLogSearch", component: LogSearch }, //Log hệ thống
  { path: "/Dashboard", name: "Bảng điều khiển", component: Dashboard },
  {
    path: "/SystemAdminDashboard",
    name: "Bảng điều khiển",
    component: SystemAdminDashboard,
  },
  {
    path: "/DepartmentAdminDashboard",
    name: "Bảng điều khiển",
    component: DepartmentAdminDashboard,
  },

  // this route should be at the end of all other routes
  {
    path: "/",
    exact: true,
    component: () => <Navigate to={"/SystemAdminDashboard"} />,
  },
];

const authRoutes = [{ path: "/Login", component: Login }];

export const RouteNames = {
  "/Records": "Danh sách hồ sơ",
  "/RecordBelowWarningDay": "Danh sách hồ sơ sắp hết hạn bảo quản",
  "/Record/:id": "Chi tiết hồ sơ",
  "/RecordCollect": "Thu thập hồ sơ",
  "/Documents": "Danh sách văn bản",
  "/Document1": "Văn bản còn hiệu lực",
  "/Document2": "Văn bản hết hiệu lực",
  "/RecordUnlockRequests": "Duyệt chỉnh sửa hồ sơ",
  "/RecordFreezes": "Đóng băng hồ sơ",
  "/RecordBatchJobs": "Xử lý hồ sơ",
  "/DicOfDocumentTypes": "TĐ Loại văn bản",
  "/DicOfStorageLifes": "TĐ Thời hạn bảo quản",
  "/DicOfAdministrativeProcedures": "TĐ Thủ tục hành chính",
  "/DicOfCustomers": "TĐ Khách hàng",
  "/Users": "Tài khoản người dùng",
  "/Departments": "Danh sách đơn vị",
  "/Settings": "Cấu hình",
  "/SystemSettings": "Cấu hình hệ thống",
  "/Dashboard": "Bảng điều khiển",
  "/SystemAdminDashboard": "Bảng điều khiển",
  "/DepartmentAdminDashboard": "Bảng điều khiển",
  "/ReportDocumentDownloadLogTotal": "Lượt tải tài liệu",
  "/ReportLogTitleCount": "Lượt tương tác hệ thống",
  "/ReportLogSearch": "Log hệ thống",
  "/DocumentExpiring": "Văn bản sắp hết hạn",
  "/AccountInfo": "Thông tin tài khoản",
};

export { userRoutes, authRoutes };
