import { Table } from "antd";
import React, { useState, useEffect, useCallback } from "react";
import ApiUtils from "../../../utils/ApiUtils";
import AppUtils from "../../../utils/AppUtils";
import SelectDepartmentWithAll from "../Departments/SelectDepartmentWithAll";
import PageContainer from "../../../shared/components/PageContainer/PageContainer";
import RecordFreezeDetail from "./RecordFreezeDetail";

function RecordFreezes() {
  const [records, setRecords] = useState([{ RecordFreezeID: "" }]);
  const [selectedFreeze, setSelectedFreeze] = useState({ RecordFreezeID: "" });
  const [isModalDetailVisible, setModalDetailVisible] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const columns = [
    {
      title: "Hồ sơ",
      key: "ReceiptKey",
      render: (_, record) =>
        <a
          href="javascript:void(0);"
          onClick={() => openDetail(record.RecordFreezeID)}
          style={{ color: "#242526" }}
        >
          {record.ReceiptKey}<br />{record.Sumary}
        </a>
    },
    {
      title: "Nội dung",
      key: "FreezeContent",
      render: (_, record) =>
        <a
          href="javascript:void(0);"
          onClick={() => openDetail(record.RecordFreezeID)}
          style={{ color: "#242526" }}
        >
          {record.FreezeContent}
          <br />{record.FreezeDate}. - {record.FreezeUser}
        </a>
    },
    {
      title: "Đơn vị",
      dataIndex: "DepartmentName",
      key: "DepartmentName",
    },
  ];

  const getData = useCallback(() => {
      ApiUtils.recordFreezeGet(selectedDepartment?.DepartmentID, "", "", (data) => {
        setRecords(data);
      });
  }, [selectedDepartment]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleDetailCancel = () => {
    setModalDetailVisible(false);
  };

  const openDetail = (id) => {
    ApiUtils.recordFreezeGetById(id, (data) => {
      if (!data?.Success) {
        AppUtils.showMessage("Thông báo", data.Message);
        return;
      }
      setSelectedFreeze(data);
      setModalDetailVisible(true);
    });
  };

  const handleSelectDepartment = (department) => {
    setSelectedDepartment(department);
  };

  return (
    <PageContainer>
      <SelectDepartmentWithAll handleSelectDepartment={handleSelectDepartment} />
      <div className="row">
        <div className="col-md-12">
          <Table dataSource={records} columns={columns} />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <RecordFreezeDetail
            isModalDetailVisible={isModalDetailVisible}
            selectedFreeze={selectedFreeze}
            handleDetailCancel={handleDetailCancel}
          ></RecordFreezeDetail>
        </div>
      </div>
    </PageContainer>
  );
}
export default RecordFreezes;