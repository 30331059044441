import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import styled from "styled-components";
import { Button, Input, Modal, Select, Space } from "antd";
import {
  EditOutlined,
  FileOutlined,
  HistoryOutlined,
  PicRightOutlined,
} from "@ant-design/icons";
import ApiUtils from "../../../utils/ApiUtils";
import AppUtils from "../../../utils/AppUtils";
import DataTable from "../../../shared/components/DataTable/DataTable";
import RecordFreezeNew from "../RecordFreezes/RecordFreezeNew";
import RecordEditStorage from "./RecordEditStorage";
import DocumentDetail from "../Documents/DocumentDetail";
import DocumentEdit from "../Documents/DocumentEdit";
import LogByReference from "../Log/LogByReference";
import RecordDocumentCatalog from "./RecordDocumentCatalog";
import { Form, Formik } from "formik";
import DicOfCustomers from "../Customers/DicOfCustomers";
import DicOfAdministrativeProcedures from "../AdministrativeProcedures/DicOfAdministrativeProcedures";
import DicOfReceivingAgency from "../ReceivingAgency";

const DicInputWrapper = styled.div`
  display: flex;
  gap: 4px;
`;

const CustomSelect = styled(Select)`
  width: 100%;
`;

function RecordDetail({
  isEditMode,
  department,
  selectedRecord,
  isModalDetailVisible,
  handleDetailCancel,
  handleAfterSaved,
}) {
  const [editingLoading, setEditingLoading] = useState(false);
  const currentDepartment = useRef(department);
  const [currentRecord, setCurrentRecord] = useState({ RecordID: "" });
  const [docCols, setDocCols] = useState(null);
  const isAdmin =
    AppUtils.userIsSystemAdmin() || AppUtils.userIsDepartmentAdmin();
  const [initialForm, setInitialForm] = useState({
    externalList: [],
  });
  const [isCustomerListVisible, setIsCustomerListVisible] = useState(false);
  const [isProcedureListVisible, setIsProcedureListVisible] = useState(false);
  const [isReceivingAgencyListVisible, setIsReceivingAgencyListVisible] =
    useState(false);
  const [fieldLoading, setFieldLoading] = useState(false);
  const [fieldNameList, setFieldNameList] = useState([]);

  const isCreatingNewRecord = useMemo(
    () => isEditMode && !currentRecord.RecordID
  );

  useEffect(() => {
    setCurrentRecord(selectedRecord);
    setInitialForm(selectedRecord);
    let cols = [
      {
        title: "Số ký hiệu",
        key: "SignatureNumber",
        render: (_, record) => <div>{record.SignatureNumber}</div>,
      },
      {
        title: "Nội dung",
        key: "Title",
        render: (_, record) => (
          <div>
            {record.DocumentGroupName}
            {record?.DocumentTypeName ? `. - ${record.DocumentTypeName}` : ""}
            <br />
            {record.Title}
          </div>
        ),
      },
      {
        title: "Thông tin khác",
        key: "Others",
        render: (_, record) => (
          <div>
            - Sở hữu: {record.Owner}
            <br />- Ngày hiệu lực: {record.ValidDate}
          </div>
        ),
      },
      {
        title: "File lưu trữ",
        key: "FileStorage",
        render: (_, record) =>
          record.Files.map((item, i) => (
            <>
              <a href="javascript:void()" onClick={() => openFile(item.FileID)}>
                <FileOutlined /> {item.FileName}
                <br />
              </a>
            </>
          )),
      },
      {
        title: "",
        key: "action",
        render: (_, record) => (
          <Space size="middle">
            <Button
              icon={<EditOutlined />}
              onClick={() => openDocument(record.DocumentID)}
            >
              Chi tiết
            </Button>
            <Button
              icon={<EditOutlined />}
              onClick={() => editDocument(record.DocumentID)}
            >
              Hiệu chỉnh
            </Button>
          </Space>
        ),
      },
    ];
    setDocCols(cols);
  }, [selectedRecord]);

  const reloadRecord = () => {
    ApiUtils.recordGetById(currentRecord.RecordID, (data) => {
      setCurrentRecord(data);
    });
  };

  //Document
  const [isModalDocumentVisible, setModalDocumentVisible] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState({ DocumentID: "" });
  const openDocument = (DocumentID) => {
    ApiUtils.documentGetByIdAndRecord(
      DocumentID,
      currentRecord.RecordID,
      (data) => {
        setSelectedDocument(data);
        setModalDocumentVisible(true);
      }
    );
  };
  const handleDocumentUpdated = () => {
    setModalDocumentVisible(false);
    setModalDocumentEditVisible(false);
    reloadRecord();
  };
  const handleDocumentCancel = () => {
    setModalDocumentVisible(false);
    setModalDocumentEditVisible(false);
  };

  //edit Doc
  const [isModalDocumentEditVisible, setModalDocumentEditVisible] =
    useState(false);
  const editDocument = (DocumentID) => {
    ApiUtils.documentGetById(DocumentID, (data) => {
      setSelectedDocument(data);
      setModalDocumentEditVisible(true);
    });
  };

  const createDocument = () => {
    setSelectedDocument({});
    setModalDocumentEditVisible(true);
  };

  //File
  const openFile = (FileID) => {
    ApiUtils.documentFileGetById(FileID, (responseData) => {
      if (!responseData?.Success) {
        AppUtils.showMessage("Thông báo", responseData?.Message);
        return;
      }
      if (responseData?.DownloadUrl) {
        window.open(responseData?.DownloadUrl);
      } else {
        AppUtils.showMessage("Thông báo", "Văn bản này không có file");
        return;
      }
    });
  };
  const handleDownloadAllDocuments = () => {
    ApiUtils.documentFileGetByRecordForDownload(
      currentRecord.RecordID,
      (responseData) => {
        console.log(responseData);
        if (!responseData) {
          AppUtils.showMessage("Thông báo", "Lỗi kết nối!");
          return;
        }
        if (responseData?.length == 1 && !responseData[0].Success) {
          AppUtils.showMessage("Thông báo", responseData[0].Message);
          return;
        }
        responseData.map((item) => {
          if (item.DownloadUrl) {
            AppUtils.downloadFileFromLink(item.DownloadUrl, item.FileName);
          }
        });
      }
    );
  };

  //Khóa hồ sơ
  const handleLockRecord = () => {
    ApiUtils.recordLock(currentRecord.RecordID, (data) => {
      AppUtils.showMessage("Thông báo", data?.Message);
      reloadRecord();
    });
  };

  //Đóng băng hồ sơ
  const [isModalFreezeVisible, setModalFreezeVisible] = useState(false);
  const [recordFreeze, setRecordFreeze] = useState({ RecordID: "" });
  const handleFreezeRecord = () => {
    setRecordFreeze({
      RecordID: currentRecord.RecordID,
      ReceiptKey: currentRecord.ReceiptKey,
      Sumary: currentRecord.Sumary,
      FreezeContent: "",
    });
    setModalFreezeVisible(true);
  };
  const handleAfterFreezed = () => {
    setModalFreezeVisible(false);
    reloadRecord();
  };
  const handleAfterFreezeCancel = () => {
    setModalFreezeVisible(false);
  };

  //Thiết lập thời hạn bảo quản
  const [isModalStorageVisible, setModalStorageVisible] = useState(false);
  const [recordStorage, setRecordStorage] = useState({ RecordID: "" });
  const handleStorageLifeEdit = () => {
    if (!isAdmin) {
      return;
    }
    ApiUtils.recordGetForEditStorage(currentRecord.RecordID, (data) => {
      setRecordStorage(data);
      setModalStorageVisible(true);
    });
  };
  const handleAfterStoraged = () => {
    setModalStorageVisible(false);
    reloadRecord();
  };
  const handleAfterStorageCancel = () => {
    setModalStorageVisible(false);
  };

  //Catalog
  const [isModalCatalogVisible, setIsModalCatalogVisible] = useState(false);
  const [catalogData, setCatalogData] = useState([]);
  const handleCatalog = () => {
    ApiUtils.recordDocumentGetForPrintCatalog(
      currentRecord.RecordID,
      (responseData) => {
        if (!responseData) {
          AppUtils.showMessage("Thông báo", "Lỗi kết nối!");
          return;
        }
        if (responseData?.length == 1 && !responseData[0].Success) {
          AppUtils.showMessage("Thông báo", responseData[0].Message);
          return;
        }
        setCatalogData(responseData);
        setIsModalCatalogVisible(true);
      }
    );
  };
  const catalogCancel = () => {
    setIsModalCatalogVisible(false);
  };

  //Log
  const [isModalLogVisible, setIsModalLogVisible] = useState(false);
  const [referenceId, setReferenceId] = useState("");
  const checkLogs = (id) => {
    setReferenceId(id);
    setIsModalLogVisible(true);
  };
  const checkLogCancel = () => {
    setIsModalLogVisible(false);
  };

  const saveRecord = useCallback(
    (values) => {
      const newValues = {
        RecordID: values.RecordID,
        RecordKey: values.RecordKey,
        AdministrativeFileNumber: values.AdministrativeFileNumber,
        ReceiptKey: values.ReceiptKey,
        CustomerKey: values.CustomerKey,
        CustomerName: values.CustomerName,
        ReceivingAgency: values.ReceivingAgency,
        AdministrativeProcedureKey: values.AdministrativeProcedureKey,
        AdministrativeProcedureName: values.AdministrativeProcedureName,
        Sumary: values.Sumary,
        FieldName: values.FieldName,
        Note: values.Note,
      };
      if (!currentRecord.RecordID) {
        ApiUtils.recordInsert(newValues, (responseData) => {
          if (!responseData) {
            AppUtils.showMessage("Thông báo", "Lỗi kết nối");
            return;
          }
          AppUtils.showMessage("Thông báo", responseData?.Message);
          if (responseData?.Success) {
            handleAfterSaved();
          }
        });
      } else {
        if (!currentRecord?.AllowEdit) {
          AppUtils.showMessage("Không được phép cập nhật!");
          return;
        }
        ApiUtils.recordUpdate(newValues, (responseData) => {
          if (!responseData) {
            AppUtils.showMessage("Thông báo", "Lỗi kết nối");
            return;
          }
          AppUtils.showMessage("Thông báo", responseData?.Message);
          if (responseData?.Success) {
            handleAfterSaved();
          }
        });
      }
    },
    [currentRecord]
  );

  const saveNote = (noteString) => {
    ApiUtils.recordUpdateNote(
      { RecordID: currentRecord.RecordID, Note: noteString },
      (responseData) => {
        if (!responseData) {
          AppUtils.showMessage("Thông báo", "Lỗi kết nối!");
          return;
        }
        AppUtils.showMessage("Thông báo", responseData.Message);
      }
    );
  };

  const handleCustomersCancel = () => {
    setIsCustomerListVisible(false);
  };
  const handleProcedureCancel = () => {
    setIsProcedureListVisible(false);
  };
  const handleAgenciesCancel = () => {
    setIsReceivingAgencyListVisible(false);
  };

  const onCustomerSelected = (customer, setFieldValue) => {
    setIsCustomerListVisible(false);
    setFieldValue("CustomerName", customer.CustomerName);
  };
  const onCustomerKeySelected = (key, setFieldValue) => {
    setIsCustomerListVisible(false);
    setFieldValue("CustomerKey", key);
  };

  const onProcedureSelected = (value, setFieldValue) => {
    setIsProcedureListVisible(false);
    setFieldValue("AdministrativeProcedureName", value.APName);
  };

  const onAgencySelected = (value, setFieldValue) => {
    setIsReceivingAgencyListVisible(false);
    setFieldValue("ReceivingAgency", value.Message);
  };

  const onFieldNameOpen = useCallback(
    (open) => {
      if (
        !open ||
        (fieldNameList.length && department === currentDepartment.current)
      ) {
        return;
      }
      setFieldLoading(true);
      setFieldNameList([]);
      ApiUtils.recordListFieldName(department, (data) => {
        setFieldNameList(data);
        currentDepartment.current = department;
        setFieldLoading(false);
      });
    },
    [fieldNameList, department]
  );

  return (
    <Formik
      initialValues={initialForm}
      enableReinitialize={true}
      onSubmit={(values) => {
        saveRecord(values);
      }}
    >
      {({ handleSubmit, values, handleChange, setFieldValue }) => {
        return (
          <Form>
            <Modal
              title="Thông tin hồ sơ"
              visible={isModalDetailVisible}
              onCancel={handleDetailCancel}
              maskClosable={true}
              width="90%"
              footer={[
                <>
                  {isEditMode ? (
                    <Button
                      onClick={() => handleSubmit()}
                      loading={editingLoading}
                      style={{ color: "var(--green-color)" }}
                    >
                      Lưu
                    </Button>
                  ) : (
                    isAdmin && (
                      <Button
                        onClick={() => saveNote(values.Note)}
                        loading={editingLoading}
                        style={{ color: "var(--green-color)" }}
                      >
                        Lưu Ghi Chú
                      </Button>
                    )
                  )}
                </>,
                <Button
                  disabled={
                    currentRecord?.IsLocked ||
                    currentRecord?.IsFreeze ||
                    !AppUtils.userIsAdmin()
                  }
                  onClick={() => handleLockRecord()}
                  loading={editingLoading}
                  style={{ color: "#bb0a1e" }}
                >
                  Khóa
                </Button>,
                <Button
                  disabled={
                    currentRecord?.IsFreeze || !AppUtils.userIsSystemAdmin()
                  }
                  onClick={() => handleFreezeRecord()}
                  loading={editingLoading}
                  style={{ color: "#5f8dce" }}
                >
                  Đóng băng
                </Button>,
                <Button
                  disabled={!AppUtils.userIsAdmin()}
                  onClick={() => handleDownloadAllDocuments()}
                >
                  Tải văn bản
                </Button>,
                <Button
                  disabled={!AppUtils.userIsSystemAdmin()}
                  icon={<HistoryOutlined />}
                  onClick={() => {
                    checkLogs(currentRecord?.RecordID);
                  }}
                >
                  Logs
                </Button>,
                <Button
                  icon={<PicRightOutlined />}
                  onClick={() => {
                    handleCatalog();
                  }}
                >
                  In Mục lục
                </Button>,
                <Button key="back" onClick={handleDetailCancel}>
                  Thoát
                </Button>,
              ]}
            >
              <table className="table table-bordered table-striped">
                <tbody>
                  {isCreatingNewRecord && (
                    <tr>
                      <td>
                        <h5>Mã hồ sơ</h5>
                      </td>
                      <td>
                        <Input
                          name="RecordKey"
                          value={values.RecordKey}
                          onChange={handleChange}
                        ></Input>
                      </td>
                    </tr>
                  )}
                  {!isCreatingNewRecord && (
                    <tr>
                      <td>
                        <h5>Trạng thái</h5>
                      </td>
                      <td>
                        <span style={{ color: currentRecord?.StatusColor }}>
                          {currentRecord?.StatusDisplay}
                        </span>
                      </td>
                    </tr>
                  )}
                  {!isCreatingNewRecord && (
                    <tr>
                      <td width="30%">
                        <h5>Bảo quản</h5>
                      </td>
                      <td>
                        <a
                          href="javascript:void(0);"
                          style={{ color: "#033500" }}
                          onClick={() => handleStorageLifeEdit()}
                        >
                          <img
                            src="/assets/SmartAdmin/img/govfiles/ic_calendar_16px.png"
                            alt=""
                          />{" "}
                          {currentRecord?.StorageLifeName}{" "}
                          {currentRecord?.StorageDate
                            ? ` - Dự kiến đến ${selectedRecord?.StorageDate}`
                            : ""}
                        </a>
                      </td>
                    </tr>
                  )}

                  <tr>
                    <td>
                      <h5>Số biên nhận</h5>
                    </td>
                    <td>
                      {isEditMode ? (
                        <Input
                          name="ReceiptKey"
                          value={values.ReceiptKey}
                          onChange={handleChange}
                        ></Input>
                      ) : (
                        currentRecord?.ReceiptKey
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5>Mã HS DVCQG</h5>
                    </td>
                    <td>
                      {isEditMode ? (
                        <Input
                          name="AdministrativeFileNumber"
                          value={values.AdministrativeFileNumber}
                          onChange={handleChange}
                        ></Input>
                      ) : (
                        currentRecord?.AdministrativeFileNumber
                      )}
                    </td>
                  </tr>
                  {!isCreatingNewRecord && (
                    <tr>
                      <td>
                        <h5>Đơn vị</h5>
                      </td>
                      <td>{currentRecord?.DepartmentName}</td>
                    </tr>
                  )}
                  <tr>
                    <td>
                      <h5>Mã định danh</h5>
                    </td>
                    <td>
                      {isEditMode ? (
                        <Input
                          name="CustomerKey"
                          value={values.CustomerKey}
                          onChange={handleChange}
                        ></Input>
                      ) : (
                        currentRecord?.CustomerKey
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5>Họ tên khách hàng</h5>
                    </td>
                    <td>
                      {isEditMode ? (
                        <DicInputWrapper>
                          <Input
                            name="CustomerName"
                            value={values.CustomerName}
                            onChange={handleChange}
                          />
                          <Button
                            onClick={() => {
                              setIsCustomerListVisible(true);
                            }}
                          >
                            Tìm kiếm
                          </Button>
                        </DicInputWrapper>
                      ) : (
                        currentRecord?.CustomerName
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5>Cơ quan tiếp nhận</h5>
                    </td>
                    <td>
                      {isEditMode ? (
                        <DicInputWrapper>
                          <Input
                            name="ReceivingAgency"
                            value={values.ReceivingAgency}
                            onChange={handleChange}
                          />
                          <Button
                            onClick={() => {
                              setIsReceivingAgencyListVisible(true);
                            }}
                          >
                            Tìm kiếm
                          </Button>
                        </DicInputWrapper>
                      ) : (
                        currentRecord?.ReceivingAgency
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5>Tên thủ tục hành chính</h5>
                    </td>
                    <td>
                      {isEditMode ? (
                        <DicInputWrapper>
                          <Input
                            name="AdministrativeProcedureName"
                            value={values.AdministrativeProcedureName}
                            onChange={handleChange}
                          />
                          <Button
                            onClick={() => {
                              setIsProcedureListVisible(true);
                            }}
                          >
                            Tìm kiếm
                          </Button>
                        </DicInputWrapper>
                      ) : (
                        currentRecord?.AdministrativeProcedureName
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5>Trích yếu hồ sơ</h5>
                    </td>
                    <td>
                      {isEditMode ? (
                        <Input
                          name="Sumary"
                          value={values.Sumary}
                          onChange={handleChange}
                        ></Input>
                      ) : (
                        currentRecord?.Sumary
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5>Mã đơn vị quốc gia</h5>
                    </td>
                    <td>
                      {isEditMode ? (
                        <Input
                          name="NationDepartmentKey"
                          value={values.NationDepartmentKey}
                          onChange={handleChange}
                        ></Input>
                      ) : (
                        currentRecord?.NationDepartmentKey
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5>Mã thủ tục quốc gia</h5>
                    </td>
                    <td>
                      {isEditMode ? (
                        <Input
                          name="NationProcedureKey"
                          value={values.NationProcedureKey}
                          onChange={handleChange}
                        ></Input>
                      ) : (
                        currentRecord?.NationProcedureKey
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5>Tên lĩnh vực</h5>
                    </td>
                    <td>
                      {isEditMode ? (
                        <CustomSelect
                          name="FieldName"
                          placeholder="Please select"
                          defaultValue={null}
                          loading={fieldLoading}
                          value={values.FieldName}
                          options={fieldNameList}
                          showSearch
                          allowClear
                          fieldNames={{
                            label: "Message",
                            value: "Message",
                            options: "options",
                          }}
                          onChange={(value) => {
                            setFieldValue("FieldName", value);
                          }}
                          onDropdownVisibleChange={onFieldNameOpen}
                        />
                      ) : (
                        currentRecord?.FieldName
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5>Ghi chú</h5>
                    </td>
                    <td>
                      {isEditMode ? (
                        <Input
                          name="Note"
                          value={values.Note}
                          onChange={handleChange}
                        ></Input>
                      ) : isAdmin ? (
                        <Input
                          name="Note"
                          value={values.Note}
                          onChange={handleChange}
                        ></Input>
                      ) : (
                        currentRecord?.Note
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>

              <h4 className="py-2">Danh sách văn bản</h4>
              {isEditMode && (
                <Button onClick={createDocument}>Tạo mới văn bản</Button>
              )}
              <DataTable
                data={selectedRecord.Documents}
                columns={docCols}
                pagination={false}
              ></DataTable>
              <RecordFreezeNew
                isModalVisible={isModalFreezeVisible}
                editingRecord={recordFreeze}
                handleAfterSaved={handleAfterFreezed}
                handleCancel={handleAfterFreezeCancel}
              />
              <RecordEditStorage
                isModalVisible={isModalStorageVisible}
                editingRecord={recordStorage}
                handleAfterSaved={handleAfterStoraged}
                handleCancel={handleAfterStorageCancel}
              />
              <DocumentDetail
                isModalVisible={isModalDocumentVisible}
                selectedDocument={selectedDocument}
                handleAfterSaved={handleDocumentUpdated}
                handleCancel={handleDocumentCancel}
              />
              <DocumentEdit
                isModalVisible={isModalDocumentEditVisible}
                selectedDocument={selectedDocument}
                handleAfterSaved={handleDocumentUpdated}
                handleCancel={handleDocumentCancel}
                recordId={currentRecord.RecordID}
              />
              <LogByReference
                referenceId={referenceId}
                referenceType={"record"}
                isModallVisible={isModalLogVisible}
                handleCancel={checkLogCancel}
              />
              <RecordDocumentCatalog
                currentRecord={currentRecord}
                listDocuments={catalogData}
                isModalVisible={isModalCatalogVisible}
                handleCancel={catalogCancel}
              />
            </Modal>
            <Modal
              title="Từ điển khách hàng"
              visible={isCustomerListVisible}
              onOk={handleCustomersCancel}
              onCancel={handleCustomersCancel}
              width={800}
            >
              <DicOfCustomers
                handleCustomerSelected={(value) =>
                  onCustomerSelected(value, setFieldValue)
                }
                handleCustomerKeySelected={(value) =>
                  onCustomerKeySelected(value, setFieldValue)
                }
              ></DicOfCustomers>
            </Modal>
            <Modal
              title="Từ điển thủ tục hành chính"
              visible={isProcedureListVisible}
              onOk={handleProcedureCancel}
              onCancel={handleProcedureCancel}
              width={800}
            >
              <DicOfAdministrativeProcedures
                handleCustomerSelected={(value) =>
                  onProcedureSelected(value, setFieldValue)
                }
              ></DicOfAdministrativeProcedures>
            </Modal>
            <Modal
              title="Từ điển cơ quan tiếp nhận"
              visible={isReceivingAgencyListVisible}
              onOk={handleAgenciesCancel}
              onCancel={handleAgenciesCancel}
              width={800}
            >
              <DicOfReceivingAgency
                handleCustomerSelected={(value) =>
                  onAgencySelected(value, setFieldValue)
                }
              ></DicOfReceivingAgency>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
}

export default RecordDetail;
