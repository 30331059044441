import React from "react";

function Footer() {
  return (
    <footer class="page-footer" role="contentinfo">
      <div class="d-flex align-items-center flex-1 text-muted">
        <span class="hidden-md-down fw-700">
          2022 © Kho Lưu Trữ Điện Tử Tỉnh Khánh Hòa . &nbsp;
          <a
            href="http://psctelecom.com.vn/"
            class="text-primary fw-500"
            title="psctelecom.com.vn/"
            target="_blank"
          >
            Được phát triển bởi PSC
          </a>
        </span>
      </div>
      <div>
        <ul class="list-table m-0 hidden">
          <li>
            <a href="intel_introduction.html" class="text-secondary fw-700">
              About
            </a>
          </li>
          <li class="pl-3">
            <a href="info_app_licensing.html" class="text-secondary fw-700">
              License
            </a>
          </li>
          <li class="pl-3">
            <a href="info_app_docs.html" class="text-secondary fw-700">
              Documentation
            </a>
          </li>
          <li class="pl-3 fs-xl">
            <a
              href="https://wrapbootstrap.com/user/MyOrange"
              class="text-secondary"
              target="_blank"
            >
              <i class="fal fa-question-circle" aria-hidden="true"></i>
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
