import { Button, Space } from "antd";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { EditOutlined } from "@ant-design/icons";
import ApiUtils from "../../../utils/ApiUtils";
import AppUtils from "../../../utils/AppUtils";
import PageContainer from "../../../shared/components/PageContainer/PageContainer";
import DocumentTypeEdit from "./DocumentTypeEdit";
import DataTable from "../../../shared/components/DataTable/DataTable";
import SelectDepartmentWithAll from "../Departments/SelectDepartmentWithAll";

function DicOfDocumentType() {
  const [documentTypes, setDocumentTypes] = useState([{ DocumentTypeID: "" }]);
  const [selectedDocType, setSelectedDocType] = useState({
    DocumentTypeID: "",
  });
  const [isModalEditVisible, setModalEditVisible] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState({
    DepartmentID: AppUtils.departmentCurrent().DepartmentID,
  });
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const shouldDisplayCreateNewBtn = useMemo(
    () =>
      !!(
        AppUtils.userIsSystemAdmin() ||
        (AppUtils.userIsDepartmentAdmin() &&
          AppUtils.getAuthUser()?.DepartmentID ===
            selectedDepartment.DepartmentID)
      ),
    [selectedDepartment]
  );

  const columns = [
    {
      title: "Mã",
      dataIndex: "DocumentTypeKey",
      key: "DocumentTypeKey",
    },
    {
      title: "Tên",
      dataIndex: "DocumentTypeName",
      key: "DocumentTypeName",
    },
    {
      title: "Đơn vị",
      dataIndex: "DepartmentName",
      key: "DepartmentName",
    },
    {
      title: "",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            icon={<EditOutlined />}
            onClick={() => openDocType(record.DocumentTypeID)}
          >
            Hiệu chỉnh
          </Button>
        </Space>
      ),
    },
  ];

  const getDocumentTypes = useCallback(() => {
    if (!selectedDepartment) {
      setSelectedDepartment({
        DepartmentID: AppUtils.userIsSystemAdmin()
          ? AppUtils.ROOT_ID
          : AppUtils.departmentCurrent().DepartmentID,
      });
    }
    setLoading(true);
    ApiUtils.documentTypeGetByDepartment(
      selectedDepartment?.DepartmentID,
      (responseData) => {
        setLoading(false);
        if (!responseData) {
          AppUtils.showMessage("Thông báo", "Lỗi dữ liệu");
          return;
        }
        if (responseData.length == 1 && !responseData[0].Success) {
          AppUtils.showMessage("Thông báo", responseData[0].Message);
          return;
        }
        setDocumentTypes(responseData);
      }
    );
  }, [selectedDepartment]);

  useEffect(() => {
    getDocumentTypes();
  }, [getDocumentTypes]);

  const handleEditCancel = () => {
    setModalEditVisible(false);
  };

  const openDocType = (id) => {
    ApiUtils.documentTypeGetById(id, (data) => {
      if (!data?.Success) {
        AppUtils.showMessage("Thông báo", data.Message);
        return;
      }
      setSelectedDocType(data);
      setModalEditVisible(true);
    });
  };

  const handleAfterSaved = () => {
    setModalEditVisible(false);
    getDocumentTypes();
  };

  const handleSelectDepartment = (department) => {
    setSelectedDepartment(department);
  };

  const handleNewDocumentType = () => {
    setSelectedDocType({ DocumentTypeID: "" });
    setModalEditVisible(true);
  };

  return (
    <PageContainer>
      <SelectDepartmentWithAll
        handleSelectDepartment={handleSelectDepartment}
      />
      <div className="row">
        <div className="col-md-12">
          <DataTable
            data={documentTypes}
            loading={loading}
            columns={columns}
            searchText={searchText}
            isSearchable
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <DocumentTypeEdit
            isModalEditVisible={isModalEditVisible}
            selectedDocType={selectedDocType}
            handleAfterSaved={handleAfterSaved}
            handleEditCancel={handleEditCancel}
          ></DocumentTypeEdit>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          {shouldDisplayCreateNewBtn && (
            <Button onClick={() => handleNewDocumentType()}>Tạo mới</Button>
          )}
        </div>
      </div>
    </PageContainer>
  );
}

export default DicOfDocumentType;
