import React from "react";
import { Button, Modal } from "antd";

function RecordFreezeDetail({
  selectedFreeze,
  isModalDetailVisible,
  handleDetailCancel,
}) {
  return (
    <Modal
      title="Đóng băng hồ sơ"
      visible={isModalDetailVisible}
      onCancel={handleDetailCancel}
      maskClosable={true}
      footer={[
        <Button key="back" onClick={handleDetailCancel}>
          Thoát
        </Button>,
      ]}
    >
      <table border={2} cellPadding={5} style={{ width: "100%" }}>
        <tbody>
          <tr>
            <td>Số biên nhận</td>
            <td>{selectedFreeze.ReceiptKey}</td>
          </tr>
          <tr>
            <td>Trích yếu</td>
            <td>{selectedFreeze.Sumary}</td>
          </tr>
          <tr>
            <td>Đơn vị</td>
            <td>{selectedFreeze.DepartmentName}</td>
          </tr>
          <tr>
            <td>Lý do</td>
            <td>{selectedFreeze.FreezeContent}</td>
          </tr>
          <tr>
            <td>Ngày đóng băng</td>
            <td>{selectedFreeze.FreezeDate}</td>
          </tr>
          <tr>
            <td>Người xử lý</td>
            <td>{selectedFreeze.FreezeUser}</td>
          </tr>
        </tbody>
      </table>
    </Modal>
  );
}

export default RecordFreezeDetail;