import axios from "axios";
import fileDownload from "js-file-download";
import { notification } from "antd";

class AppUtils {
  static hostApiUrl = "https://govfileservicekhanhhoa.azurewebsites.net/";
  static TOKEN_EXPIRED_MESSAGE = "HET_HAN_DANG_NHAP_VUI_LONG_DANG_NHAP_LAI";
  static ROOT_ID = "00000000-0000-0000-0000-000000000000";
  static DATE_FORMAT = "DD/MM/YYYY";
  static CONTROL_BORDER_COLOR = "#d9d9d9";

  static showMessage(title, message, action = null) {
    notification.open({
      message: title,
      description: message,
    });
  }

  static isNull(obj) {
    return obj === null || typeof obj === "undefined";
  }

  static getUserFromCookie() {
    let uc = this.getCookie("UserLogin");
    if (uc) {
      return JSON.parse(uc);
    } else {
      return { Success: false, Message: "Chưa đăng nhập" };
    }
  }

  static getAuthUser() {
    let uc = localStorage.getItem("libUser");
    if (uc) {
      return JSON.parse(uc);
    } else {
      return { Success: false, Message: "Chưa đăng nhập" };
    }
  }

  static setAuthUser(user) {
    localStorage.setItem("libUser", JSON.stringify(user));
  }

  static removeAuthUser() {
    localStorage.removeItem("libUser");
  }

  static logout() {
    localStorage.removeItem("libUser");
    window.open("Login");
  }

  static userIsLogin() {
    let user = this.getAuthUser();
    if (user?.Success) {
      return true;
    } else {
      return false;
    }
  }

  static userIsRootAdmin() {
    let user = this.getAuthUser();
    if (user?.Username === "psc") {
      return true;
    } else {
      return false;
    }
  }
  static userIsSystemAdmin() {
    let user = this.getAuthUser();
    if (user?.IsAdmin && user.DepartmentID === this.ROOT_ID) {
      return true;
    } else {
      return false;
    }
  }
  static userIsDepartmentAdmin() {
    let user = this.getAuthUser();
    if (user?.IsAdmin && user.DepartmentID !== this.ROOT_ID) {
      return true;
    } else {
      return false;
    }
  }
  static userIsAdmin() {
    let user = this.getAuthUser();
    if (user?.IsAdmin) {
      return true;
    } else {
      return false;
    }
  }

  static departmentAdmin() {
    return {
      Success: true,
      DepartmentID: this.ROOT_ID,
      DepartmentName: "Quản trị hệ thống",
    };
  }
  static departmentDefault() {
    return {
      Success: true,
      DepartmentID: this.ROOT_ID,
      DepartmentName: "Dùng chung",
    };
  }
  static departmentCurrent() {
    let user = this.getAuthUser();
    return {
      Success: true,
      DepartmentID: user.DepartmentID,
      DepartmentName: user.DepartmentName,
    };
  }

  static userToken() {
    let user = this.getAuthUser();
    if (user?.Success) {
      return user.Token;
    } else {
      return null;
    }
  }

  static setCookieInHour(cookieName, cookieValue, hourToExpire) {
    let date = new Date();
    date.setTime(date.setTime() + hourToExpire * 60 * 60 * 1000);
    document.cookie =
      cookieName + "=" + cookieValue + ";expires=" + date.toUTCString();
  }

  static getCookie(cookieName) {
    var b = document.cookie.match(
      "(^|;)\\s*" + cookieName + "\\s*=\\s*([^;]+)"
    );
    return b ? b.pop() : "";
  }

  static deleteCookie(cookieName) {
    document.cookie =
      cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }

  static generateRecordDetailHtmlTable = (data) => {
    if (data && data.Success) {
      // cau truc row
      const row = (label, value) => {
        return `<p>${label}: ${value}</p>`;
      };
      // chi tiet ho so
      const getStorageStr = () => {
        let str = `${data.StorageLifeName}`;
        if (data.StorageDate) {
          str += ` - Dự kiến đến ${data.StorageDate}`;
        }
        return str;
      };
      const recordDetailTable = `
          ${row("Trạng thái", data.StatusDisplay)}
          ${row("Bảo quản", getStorageStr())}
          ${row("Số biên nhận", data.ReceiptKey)}
          ${row("Mã HS DVCQG", data.AdministrativeFileNumber)}
          ${row("Đơn vị", data.DepartmentName)}
          ${row("Mã định danh", data.CustomerKey)}
          ${row("Họ tên khách hàng", data.CustomerName)}
          ${row("Cơ quan tiếp nhận", data.ReceivingAgency)}
          ${row("Tên thủ tục hành chính", data.AdministrativeProcedureName)}
          ${row("Trích yếu hồ sơ", data.Sumary)}
          ${row("Tên lĩnh vực", data.FieldName)}
          ${row("Ghi chú", data.Note)}
    `;

      // danh sach tai lieu
      const documentsTableHead = () => {
        let head = `
        <tr>
          <td>Số ký hiệu</td>
          <td>Nội dung</td>
          <td></td>
        </tr>
      `;
        return head;
      };
      const documentsTableBody = () => {
        let body = "";
        if (data.Documents && data.Documents.length > 0) {
          for (let i = 0; i < data.Documents.length; i++) {
            const document = data.Documents[i];
            let row = `
            <tr>
              <td>${document.SignatureNumber}</td>
              <td>
                ${document.DocumentGroupName} - ${document.DocumentTypeName}
                <br>
                ${document.Title}
              </td>
              <td>
                - Sở hữu: ${document.Owner}
                - Ngày hiệu lực: ${document.ValidDate}
              </td>
            </tr>
          `;
            body += row;
          }
        }
        return body;
      };
      let documentsTable = `
      <table>
          ${documentsTableHead()}
          ${documentsTableBody()}
      </table>
    `;
      let table = `
      <h2>Chi tiết hồ sơ</h2>
      ${recordDetailTable}
      <br>
      <h2>Danh sách hồ sơ</h2>
      ${documentsTable}
    `;
      return table;
    }
    return;
  };

  // record doc download
  static generateRecordDetailDoc = (data, filename) => {
    const style = `<style>
    table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
  }
  </style>`;
    const preHtml = `<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>${filename}</title>${style}</head><body>`;
    const postHtml = "</body></html>";
    const table = this.generateRecordDetailHtmlTable(data);
    const html = preHtml + table + postHtml;
    const url = `data:application/vnd.ms-word;charset=utf-8,${encodeURIComponent(
      html
    )}`;

    // Specify file name
    filename = filename ? filename + ".doc" : "document.doc";

    // check browser compatibility
    if (navigator && navigator.msSaveOrOpenBlob) {
      const blob = new Blob(["\ufeff", html], {
        type: "application/msword",
      });
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      var downloadLink = document.createElement("a");
      document.body.appendChild(downloadLink);
      downloadLink.href = url;
      downloadLink.download = filename;
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  static excelDownloadXLS(data, filename) {
    let downloadLink;
    const dataType = "application/vnd.ms-excel";
    let tableHTML = this.docxGenerateTable(data.columns, data.rows);
    tableHTML = tableHTML.replace(/ /g, "%20");

    filename = filename ? filename + ".xls" : "export.xls";

    if (navigator && navigator.msSaveOrOpenBlob) {
      var blob = new Blob(["\ufeff", tableHTML], {
        type: dataType,
      });
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      downloadLink = document.createElement("a");
      document.body.appendChild(downloadLink);
      downloadLink.href = "data:" + dataType + ", " + tableHTML;
      downloadLink.download = filename;
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  }

  //WORD-DOCX
  static docxGenerateTable(columns, rows) {
    let tableHead = "";
    let tableData = [];
    for (let c = 0; c < columns.length; c++) {
      const column = columns[c];
      tableHead += `<th>${column.Value}</th>`;
    }
    const keys = [];
    for (let k = 0; k < columns.length; k++) {
      const key = columns[k].Key;
      keys.push(key);
    }

    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      const rowDatas = [];
      for (let k = 0; k < keys.length; k++) {
        const key = keys[k];
        const rowData = row[key];
        if (rowData === null || rowData === undefined) {
          rowDatas.push("");
        } else {
          rowDatas.push(rowData.trim());
        }
      }
      tableData.push(rowDatas);
    }
    let parsedHtmlTableData = "";
    for (let i = 0; i < tableData.length; i++) {
      const row = tableData[i];
      let tr = "";
      for (let d = 0; d < row.length; d++) {
        const td = row[d];
        tr += `<td>${td}</td>`;
      }
      parsedHtmlTableData += `<tr>${tr}</tr>`;
    }
    const table = `<table border="1"><tr>${tableHead}</tr>${parsedHtmlTableData}</table>`;
    return table;
  }

  static docExport(data, filename) {
    const style = `<style>
    table, th, td {
      border: 1px solid black;
      border-collapse: collapse;
    }     
    </style>`;
    const preHtml = `<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>${filename}</title>${style}</head><body>`;
    const postHtml = "</body></html>";
    const table = this.docxGenerateTable(data.columns, data.rows);
    const html = preHtml + table + postHtml;

    const url = `data:application/vnd.ms-word;charset=utf-8,${encodeURIComponent(
      html
    )}`;

    // Specify file name
    filename = filename ? filename + ".doc" : "document.doc";

    // check browser compatibility
    if (navigator && navigator.msSaveOrOpenBlob) {
      const blob = new Blob(["\ufeff", html], {
        type: "application/msword",
      });
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      var downloadLink = document.createElement("a");
      document.body.appendChild(downloadLink);
      downloadLink.href = url;
      downloadLink.download = filename;
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  }

  static removeVietmanmeseCode = (value) => {
    let str = value;
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
    str = str.replace(/Đ/g, "D");
    return str;
  };

  static downloadFileFromLink = (url, filename) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };
}
export default AppUtils;
