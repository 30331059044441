import React, { useEffect, useState, useCallback } from "react";
import {
  SearchOutlined,
  FileExcelOutlined,
  FileWordOutlined,
} from "@ant-design/icons";
import { FileTextOutlined } from "@ant-design/icons";
import DataTable from "../../../shared/components/DataTable/DataTable";
import PageContainer from "../../../shared/components/PageContainer/PageContainer";
import ApiUtils from "../../../utils/ApiUtils";
import AppUtils from "../../../utils/AppUtils";
import { Button, Input, Space, Radio } from "antd";
import CustomerDetail from "./CustomerDetail";
import styled from "styled-components";

const FormInputWrapper = styled.div`
  margin: 8px 0;
`;

const CustomerInfoWrapper = styled.div`
  cursor: ${({ selectable }) => (selectable ? "pointer" : "initial")};
`;

const DEFAULT_PAGINATION = {
  current: 1,
  pageSize: 10,
  total: 0,
};

function DicOfCustomers({ handleCustomerSelected, handleCustomerKeySelected }) {
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [searchCustomerType, setSearchCustomerType] = useState(0);
  const [searchCustomerName, setSearchCustomerName] = useState("");
  const [searchPhoneNumber, setSearchPhoneNumber] = useState("");
  const [searchEmail, setSearchEmail] = useState("");
  const [
    searchCitizenIdentificationNumber,
    setSearchCitizenIdentificationNumber,
  ] = useState("");
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [total, setTotal] = useState(0);

  const columns = [
    {
      title: "STT",
      dataIndex: "RowNumber",
      key: "RowNumber",
    },
    {
      title: "Thông tin khách hàng",
      key: "TitleDisplay",
      render: (_, record) => (
        <CustomerInfoWrapper
          selectable={!!handleCustomerSelected}
          onClick={() => {
            handleCustomerSelected && handleCustomerSelected(record);
          }}
        >
          <a
            href="javascript:void(0);"
            onClick={() =>
              !handleCustomerSelected && openCustomer(record.CustomerID)
            }
            style={{ color: "#242526" }}
          >
            <img
              src={
                record.IsOrganization
                  ? "assets/SmartAdmin/img/govfiles/ic_company_16px.png"
                  : "assets/SmartAdmin/img/govfiles/ic_customer_16px.png"
              }
              alt=""
            />{" "}
            {record.CustomerName}
            <br />
            {record.Ward}
            {record.Ward ? ". - " : ""}
            {record.District}
            {record.District ? ". - " : ""}
            {record.Province}
          </a>
        </CustomerInfoWrapper>
      ),
    },
    {
      title: "Số căn cước",
      key: "CitizenIdentificationNumber",
      render: (_, record) => (
        <CustomerInfoWrapper
          selectable={!!handleCustomerKeySelected}
          onClick={() => {
            handleCustomerKeySelected &&
              handleCustomerKeySelected(record.CitizenIdentificationNumber);
          }}
        >
          <a
            href="javascript:void(0);"
            onClick={() =>
              !handleCustomerKeySelected && openCustomer(record.CustomerID)
            }
            style={{ color: "#242526" }}
          >
            {record.CitizenIdentificationNumber}
          </a>
        </CustomerInfoWrapper>
      ),
    },
    {
      title: "Mã số thuế",
      key: "TaxNumber",
      render: (_, record) => (
        <CustomerInfoWrapper
          selectable={!!handleCustomerKeySelected}
          onClick={() => {
            handleCustomerKeySelected &&
              handleCustomerKeySelected(record.TaxNumber);
          }}
        >
          <a
            href="javascript:void(0);"
            onClick={() =>
              !handleCustomerKeySelected && openCustomer(record.CustomerID)
            }
            style={{ color: "#242526" }}
          >
            {record.TaxNumber}
          </a>
        </CustomerInfoWrapper>
      ),
    },
    {
      title: "",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            icon={<FileTextOutlined />}
            onClick={() => openCustomer(record.CustomerID)}
          >Chi tiết
          </Button>
        </Space>
      ),
    },
  ];

  /*const searchData = useCallback(
    ({ index = 0, count = 10 }) => {
      setLoading(true);
      ApiUtils.customerSearch(
        index,
        count,
        {},
        (data) => {
          setLoading(false);
          setCustomers(data);
          if ((data[0]?.TotalCustomers || 0) === pagination.total) {
            return;
          }
          setPagination({
            ...DEFAULT_PAGINATION,
            total: data[0]?.TotalCustomers || 0,
          });
        }
      );
    },
    [pagination.total]
  );*/

  const searchData = useCallback(() => {
    if (
      !searchCustomerName &&
      !searchPhoneNumber &&
      !searchEmail &&
      !searchCitizenIdentificationNumber
    ) {
      AppUtils.showMessage("Thông báo", "Chưa nhập thông tin tìm kiếm");
      return;
    }
    setLoading(true);
    const { current, pageSize } = pagination;
    let searchData = {
      CustomerType: searchCustomerType,
      CustomerName: searchCustomerName,
      PhoneNumber: searchPhoneNumber,
      Email: searchEmail,
      CitizenIdentificationNumber: searchCitizenIdentificationNumber,
    };
    ApiUtils.customerSearch(
      (current - 1) * pageSize,
      pageSize,
      searchData,
      (responseData) => {
        setLoading(false);
        if (responseData.length === 1 && !responseData[0].Success) {
          if (responseData[0].Message === AppUtils.TOKEN_EXPIRED_MESSAGE) {
            AppUtils.logout();
            return;
          }
          AppUtils.showMessage("Thông báo", responseData[0].Message);
          return;
        }
        setCustomers(responseData);

        const currentTotal = responseData[0]?.TotalRecords || 0;
        if (currentTotal === total) {
          return;
        }
        setTotal(currentTotal);
      }
    );
  }, [
    searchCustomerType,
    searchCustomerName,
    searchPhoneNumber,
    searchEmail,
    searchCitizenIdentificationNumber,
    pagination,
    total,
  ]);

  /*useEffect(() => {
    searchData({});
  }, [searchData]);*/

  const handleTableChange = (pagination) => {
    setPagination(pagination);
    const { current, pageSize } = pagination;
    searchData({ index: (current - 1) * pageSize, count: pageSize });
  };

  //CustomerDetail
  const [selectedCustomer, setSelectedCustomer] = useState({ CustomerID: "" });
  const [isModalDetailVisible, setModalDetailVisible] = useState(false);

  const openCustomer = (CustomerID) => {
    ApiUtils.customerGetById(CustomerID, (responseData) => {
      if (!responseData?.Success) {
        AppUtils.showMessage("Thông báo", responseData.Message);
        return;
      }
      setSelectedCustomer(responseData);
      setModalDetailVisible(true);
    });
  };

  const handleDetailCancel = () => {
    setModalDetailVisible(false);
  };

  //Export-Data
  const exportExcel = () => {
    let searchData = {
      CustomerType: searchCustomerType,
      CustomerName: searchCustomerName,
      PhoneNumber: searchPhoneNumber,
      Email: searchEmail,
      CitizenIdentificationNumber: searchCitizenIdentificationNumber,
    };
    ApiUtils.customerSearchExport(searchData, (responseData) => {
      if (!responseData?.Success) {
        AppUtils.showMessage("Thông báo", responseData?.Message);
        return;
      }
      AppUtils.excelDownloadXLS(responseData, responseData.Name);
    });
  };
  const exportDocx = () => {
    let searchData = {
      CustomerType: searchCustomerType,
      CustomerName: searchCustomerName,
      PhoneNumber: searchPhoneNumber,
      Email: searchEmail,
      CitizenIdentificationNumber: searchCitizenIdentificationNumber,
    };
    ApiUtils.customerSearchExport(searchData, (responseData) => {
      if (!responseData?.Success) {
        AppUtils.showMessage("Thông báo", responseData?.Message);
        return;
      }
      AppUtils.docExport(responseData, responseData.Name);
    });
  };

  return (
    <PageContainer>
      <div className="row">
        <div className="col-md-12">
          <FormInputWrapper>
            Nhóm khách hàng:{" "}
            <Radio.Group
              onChange={(e) => {
                setSearchCustomerType(e.target.value);
              }}
              value={searchCustomerType}
            >
              <Radio value={1}>Tổ chức</Radio>
              <Radio value={2}>Cá nhân</Radio>
              <Radio value={0}>Tất cả</Radio>
            </Radio.Group>
          </FormInputWrapper>
          <FormInputWrapper>
            Tên{" "}
            <Input
              value={searchCustomerName}
              onChange={(event) => {
                setSearchCustomerName(event.target.value);
              }}
            />
          </FormInputWrapper>
          <FormInputWrapper>
            Số điện thoại{" "}
            <Input
              value={searchPhoneNumber}
              onChange={(event) => {
                setSearchPhoneNumber(event.target.value);
              }}
            />
          </FormInputWrapper>
          <FormInputWrapper>
            Email{" "}
            <Input
              value={searchEmail}
              onChange={(event) => {
                setSearchEmail(event.target.value);
              }}
            />
          </FormInputWrapper>
          <FormInputWrapper>
            Mã định danh{" "}
            <Input
              value={searchCitizenIdentificationNumber}
              onChange={(event) => {
                setSearchCitizenIdentificationNumber(event.target.value);
              }}
            />
          </FormInputWrapper>
          <Button icon={<SearchOutlined />} onClick={() => searchData()}>
            Tra cứu
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <DataTable
            data={customers}
            loading={loading}
            columns={columns}
            handleTableChange={handleTableChange}
            pagination={pagination}
          ></DataTable>
        </div>
      </div>
      {!handleCustomerSelected && (
        <div className="row">
          <div className="col-md-12">
            <Button
              className="psc-center-icon"
              onClick={exportExcel}
              icon={<FileExcelOutlined />}
            >
              Excel
            </Button>{" "}
            <Button
              className="psc-center-icon"
              onClick={exportDocx}
              icon={<FileWordOutlined />}
            >
              Word
            </Button>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-md-12">
          <CustomerDetail
            isModalDetailVisible={isModalDetailVisible}
            selectedCustomer={selectedCustomer}
            handleDetailCancel={handleDetailCancel}
          ></CustomerDetail>
        </div>
      </div>
    </PageContainer>
  );
}
export default DicOfCustomers;
