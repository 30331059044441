import React, { useState, useEffect, useMemo, useCallback } from "react";
import useScript from "../../../hooks/useScript";
import ClipLoader from "react-spinners/ClipLoader";
import ApiUtils from "../../../utils/ApiUtils";
import AppUtils from "../../../utils/AppUtils";
import SelectDepartmentRow from "./SelectDepartmentRow";
import DataTable from "../../../shared/components/DataTable/DataTable";
import { Button, Modal } from "antd";
import styled from "styled-components";
import { UnorderedListOutlined } from "@ant-design/icons";

const FullWidthButton = styled(Button)`
  width: 100%;
  text-align: left;
`;

function SelectDepartmentWithAll({
  handleSelectDepartment,
  showSelectAll = true,
  simpleMode = false,
  selectedDepartment = AppUtils.ROOT_ID,
}) {
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("abcd");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [departments, setDepartments] = useState([
    { DepartmentID: "", DepartmentName: "Đang lấy danh sách đơn vị..." },
  ]);
  const [currentDepartment, setCurrentDepartment] = useState({
    DepartmentID: "",
    DepartmentName: "Chọn đơn vị...",
  });

  useScript("plugins/custombox/js/custombox.min.js");
  useScript("plugins/custombox/js/legacy.min.js");

  useEffect(() => {
    if (!AppUtils.userIsSystemAdmin()) {
      let user = AppUtils.getAuthUser();
      setCurrentDepartment({
        DepartmentID: user.DepartmentID,
        DepartmentName: user.DepartmentName,
      });
    }
  }, []);

  useEffect(() => {
    if (selectedDepartment?.DepartmentID !== AppUtils.ROOT_ID) {
      return;
    }
    setCurrentDepartment({
      DepartmentID: "",
      DepartmentName: "Chọn đơn vị...",
    });
  }, [selectedDepartment]);

  const getDepartment = () => {
    if (AppUtils.userIsSystemAdmin()) {
      setIsModalVisible(true);
      setSearchText({});
      setLoading(true);
      ApiUtils.departmentGet((data) => {
        setLoading(false);
        if (data.length === 1 && !data[0].Success) {
          if (data[0].Message == AppUtils.TOKEN_EXPIRED_MESSAGE) {
            AppUtils.logout();
            return;
          }
          AppUtils.showMessage("Thông báo", data[0].Message);
        }
        setDepartments(data);
      });
    } else {
      let user = AppUtils.getAuthUser();
      let d = {
        DepartmentID: user.DepartmentID,
        DepartmentName: user.DepartmentName,
      };
      handleSelectDepartment(d);
    }
  };

  const selectDepartmentHandle = useCallback(
    (DepartmentID) => {
      let d = departments.find((item) => item.DepartmentID === DepartmentID);
      if (d) {
        setCurrentDepartment(d);
        handleSelectDepartment(d);
        setIsModalVisible(false);
      }
    },
    [departments]
  );

  const selectDepartmentDefault = () => {
    let d = {
      DepartmentID: AppUtils.ROOT_ID,
      DepartmentName: currentDepartment.DepartmentName,
    };
    setCurrentDepartment(d);
    handleSelectDepartment(d);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = useMemo(
    () => [
      {
        title: "Danh sách đơn vị",
        dataIndex: "DepartmentName",
        key: "DepartmentName",
        render: (name, record) => (
          <SelectDepartmentRow
            department={record}
            select_me={selectDepartmentHandle}
          />
        ),
      },
    ],
    [selectDepartmentHandle]
  );

  return (
    <>
      <div className="form-group row mb-2">
        {!simpleMode && <h5 class="col-2 col-form-label">Đơn vị:</h5>}
        <div
          className={simpleMode ? "col-12" : showSelectAll ? "col-8" : "col-10"}
        >
          <FullWidthButton
            data-toggle="modal"
            data-target="#modal-01181vg46e80593xi947g33hx15"
            onClick={() => getDepartment()}
          >
            {currentDepartment.DepartmentName}
          </FullWidthButton>
        </div>
        {showSelectAll && !simpleMode ? (
          <div class="col-2">
            <Button
              visible={AppUtils.userIsSystemAdmin()}
              onClick={() => selectDepartmentDefault()}
              className="psc-center-icon"
            >
              <UnorderedListOutlined /> Tất cả đơn vị
            </Button>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="card-box">
            <Modal
              visible={isModalVisible}
              title="Chọn đơn vị"
              onOk={handleOk}
              onCancel={handleCancel}
            >
              <DataTable
                data={departments}
                columns={columns}
                searchText={searchText}
                isSearchable
              ></DataTable>
            </Modal>
          </div>
        </div>
      </div>
      <ClipLoader loading={loading} />
    </>
  );
}
export default SelectDepartmentWithAll;
