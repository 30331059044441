import { Button } from "antd";
import React, { useState, useEffect, useCallback } from "react";
import { FileExcelOutlined, FileWordOutlined } from "@ant-design/icons";
import ApiUtils from "../../../utils/ApiUtils";
import AppUtils from "../../../utils/AppUtils";
import PageContainer from "../../../shared/components/PageContainer/PageContainer";
import DepartmentDetail from "./DepartmentDetail";
import DataTable from "../../../shared/components/DataTable/DataTable";

function Departments() {
  const [departments, setDepartments] = useState([{ DepartmentID: "" }]);
  const [selectedDepartment, setSelectedDepartment] = useState({ DepartmentID: "" });
  const [isModalDetailVisible, setModalDetailVisible] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const columns = [
    {
      title: "Mã",
      key: "DepartmentKey",
      render: (_, record) =>
        <a href="javascript:void(0);" onClick={() => openDepartment(record.DepartmentID)} style={{ color: "#242526" }}>
          {record.DepartmentKey}
        </a>
    },
    {
      title: "Tên",
      key: "DepartmentName",
      render: (_, record) =>
        <a href="javascript:void(0);" onClick={() => openDepartment(record.DepartmentID)} style={{ color: "#242526" }}>
          {record.DepartmentName}
        </a>
    }
  ];

  const getDepartments = useCallback(() => {
    setSearchText({});
    setLoading(true);
    ApiUtils.departmentGet((responseData) => {
      setLoading(false);
      if (responseData.length === 1 && !responseData[0].Success) {
        if (responseData[0].Message === AppUtils.TOKEN_EXPIRED_MESSAGE) {
          AppUtils.logout();
          return;
        }
        AppUtils.showMessage("Thông báo", responseData[0].Message);
        return;
      }
      setDepartments(responseData);
    });
  }, []);

  useEffect(() => {
    getDepartments();
  }, [getDepartments]);

  const handleDetailCancel = () => {
    setModalDetailVisible(false);
  };

  const openDepartment = (id) => {
    ApiUtils.departmentGetById(id, (data) => {
      if (!data?.Success) {
        AppUtils.showMessage("Thông báo", data.Message);
        return;
      }
      setSelectedDepartment(data);
      setModalDetailVisible(true);
    });
  };

  //Export-Data
  const exportExcel = () => {
    ApiUtils.departmentGetExport(
      (responseData) => {
        if (!responseData?.Success) {
          AppUtils.showMessage("Thông báo", responseData?.Message);
          return;
        }
        AppUtils.excelDownloadXLS(responseData, responseData.Name);
      }
    );
  };
  const exportDocx = () => {
    ApiUtils.departmentGetExport(
      (responseData) => {
        if (!responseData?.Success) {
          AppUtils.showMessage("Thông báo", responseData?.Message);
          return;
        }
        AppUtils.docExport(responseData, responseData.Name);
      }
    );
  };

  return (
    <PageContainer>
      <div className="row">
        <div className="col-md-12">
          <DataTable
            loading={loading}
            data={departments}
            columns={columns}
            searchText={searchText}
            isSearchable
          ></DataTable>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <Button
            className="psc-center-icon"
            onClick={exportExcel}
            icon={<FileExcelOutlined />}
          >
            Excel
          </Button>{" "}
          <Button
            className="psc-center-icon"
            onClick={exportDocx}
            icon={<FileWordOutlined />}
          >
            Word
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <DepartmentDetail
            isModalDetailVisible={isModalDetailVisible}
            selectedDepartment={selectedDepartment}
            handleDetailCancel={handleDetailCancel}
          ></DepartmentDetail>
        </div>
      </div>
    </PageContainer>
  );
}
export default Departments;