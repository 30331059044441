import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useContext,
  useRef,
} from "react";
import DataTable from "../../../shared/components/DataTable/DataTable";
import PageContainer from "../../../shared/components/PageContainer/PageContainer";
import SelectDepartmentWithAll from "../Departments/SelectDepartmentWithAll";
import ApiUtils from "../../../utils/ApiUtils";
import AppUtils from "../../../utils/AppUtils";
import RecordSearchBox from "./RecordSearchBox";
import { Button, Space } from "antd";
import { FileExcelOutlined, FileWordOutlined, FileTextOutlined, CloseSquareOutlined } from "@ant-design/icons";
import SearchContent from "../../../shared/components/SearchContent";
import { useSearchParams } from "react-router-dom";
import { HomePageContext } from "../../../AppContexts";
import RecordDetroyReason from "./RecordDetroyReason";
import RecordDetail from "./RecordDetailModal";

export const RecordStatus = {
  all: 0,
  isActive: 1,
  waitToRemove: 2,
  removed: 3,
};

export const RecordStatusInfo = {
  [RecordStatus.isActive]: { label: "Đang hiệu lực", style: "text-success" },
  [RecordStatus.waitToRemove]: { label: "Chờ tiêu hủy", style: "text-warning" },
  [RecordStatus.removed]: { label: "Bị tiêu hủy", style: "text-danger" },
  [RecordStatus.all]: { label: "Tất cả" },
};

const DEFAULT_PAGINATION = {
  current: 1,
  pageSize: 10,
};

function RecordBelowWarningDay() {
  const { setTitle } = useContext(HomePageContext);
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [searchValues, setSearchValues] = useState({});
  const [selectedRecord, setSelectedRecord] = useState({ RecordID: "" });
  const [isModalDetailVisible, setModalDetailVisible] = useState(false);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [total, setTotal] = useState(0);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [params] = useSearchParams();
  const isFirstRun = useRef(true);

  const [columns, setColumns] = useState([]);
  const paramStatus = useMemo(() => {
    return params.get("status") || 0;
  }, [params]);

  useEffect(() => {
    setTitle(
      `Danh sách Hồ Sơ Sắp Hết Hạn bảo Quản`
    );
    return () => {
      setTitle();
    };
  }, [setTitle, paramStatus]);

  useEffect(() => {
    getRecords();
  }, [pagination]);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    setSearchValues({});
    setPagination({ ...DEFAULT_PAGINATION });
  }, [paramStatus]);

  useEffect(() => {
    table_InitCols();
  }, []);

  //Department
  const [selectedDepartment, setSelectedDepartment] = useState({
    DepartmentID: AppUtils.ROOT_ID,
  });
  const handleSelectDepartment = (department) => {
    setPagination({ ...DEFAULT_PAGINATION });
    setSelectedDepartment(department);
  };

  const openDetail = (RecordID) => {
    ApiUtils.recordGetById(RecordID, (data) => {
      if (!data?.Success) {
        AppUtils.showMessage("Thông báo", data?.Message);
        return;
      }
      setSelectedRecord(data);
      setModalDetailVisible(true);
    });
  };

  const handleDetailCancel = () => {
    setModalDetailVisible(false);
  };

  const handleAfterSaved = () => {
    setModalDetailVisible(false);
    getRecords();
  };

  //Data
  const getRecords = useCallback(() => {
    setLoading(true);
    const { current, pageSize } = pagination;
    ApiUtils.recordSearchBelowWarningDay(
      selectedDepartment?.DepartmentID,
      (current - 1) * pageSize,
      pageSize,
      {
        ...searchValues,
        RecordStatus: searchValues.RecordStatus ?? paramStatus,
      },
      (data) => {
        setLoading(false);
        if (data.length === 1 && !data[0].Success) {
          if (data[0].Message === AppUtils.TOKEN_EXPIRED_MESSAGE) {
            AppUtils.logout();
            return;
          }
          AppUtils.showMessage("Thông báo", data[0].Message);
          return;
        }
        setRecords(data);
        const currentTotal = data[0]?.TotalRecord || 0;
        if (currentTotal === total) {
          return;
        }
        setTotal(currentTotal);
      }
    );
  }, [selectedDepartment, searchValues, pagination, paramStatus, total]);

  const table_InitCols = () => {
    let cols = [
      {
        title: "STT",
        dataIndex: "RowNumber",
        key: "RowNumber",
      },
      {
        title: "Mã",
        key: "ID",
        render: (_, record) => (
          <div>
            {record.ReceiptKey ?
              <><img src={"assets/SmartAdmin/img/govfiles/ic_invoice_20px.png"} alt="" />
                {" "}{record.ReceiptKey}</>
              : ""
            }
            {record.AdministrativeFileNumber ? <>
              <br /><img src={"assets/SmartAdmin/img/govfiles/pc_policy_document_20px.png"} alt="" />
              {" "}{record.AdministrativeFileNumber}</>
              : ""}
          </div>
        ),
      },
      {
        title: "Trích yếu",
        key: "Sumary",
        render: (_, record) => (
          <div>
            {record.LastVisitDate ? ""
              : <><img src={"assets/SmartAdmin/img/govfiles/new_gif.gif"} alt="" />
                {" "}</>
            }
            {record.Sumary}
            <br/><img
              src={
                record.IsFreeze
                  ? "assets/SmartAdmin/img/govfiles/ic_freeze_16px.png"
                  : record.IsLocked
                    ? "assets/SmartAdmin/img/govfiles/ic_lock_16px.png"
                    : "assets/SmartAdmin/img/govfiles/ic_edit_16px.png"
              }
              alt=""
            />{" "}
            <span style={{ color: record.StatusColor }}>{record.StatusDisplay}</span>
          </div>
        ),
      },
      {
        title: "Bảo quản đến",
        dataIndex: "StorageDate",
        key: "StorageDate",
      },
      {
        title: "Còn lại",
        dataIndex: "StorageDays",
        key: "StorageDays",
      },
      {
        title: "",
        key: "action",
        render: (_, record) => (
          <Space size="middle">
            <Button
              icon={<FileTextOutlined />}
              onClick={() => openDetail(record.RecordID)}>
              Chi tiết
            </Button>
          </Space>
        ),
      },
    ];
    if (AppUtils.userIsSystemAdmin()) {
      cols.splice(cols.length - 1, 0, {
        title: "Đơn vị",
        dataIndex: "DepartmentName",
        key: "DepartmentName",
      });
    }
    setColumns(cols);
  };
  const handleTableChange = useCallback(
    (pagination) => {
      setPagination(pagination);
      setTotal(pagination.total);
    },
    [setPagination, setTotal]
  );

  //SearchBox
  const handleSearch = useCallback((value) => {
    setSearchValues(value);
    setPagination({ ...DEFAULT_PAGINATION });
    setIsSearchVisible(false);
  }, []);

  const handleCancel = () => {
    setIsSearchVisible(false);
  };

  //Export-Data
  const exportExcel = () => {
    setLoading(true);
    ApiUtils.recordSearchBelowWarningDayExport(
      selectedDepartment?.DepartmentID,
      searchValues,
      (data) => {
        setLoading(false);
        if (!data?.Success) {
          AppUtils.showMessage("Thông báo", data?.Message);
          return;
        }
        AppUtils.excelDownloadXLS(data, data.Name);
      }
    );
  };
  const exportDocx = () => {
    setLoading(true);
    ApiUtils.recordSearchBelowWarningDayExportForWord(
      selectedDepartment?.DepartmentID,
      searchValues,
      (data) => {
        setLoading(false);
        if (!data?.Success) {
          AppUtils.showMessage("Thông báo", data?.Message);
          return;
        }
        AppUtils.docExport(data, data.Name);
      }
    );
  };

  return (
    <PageContainer>
      <SelectDepartmentWithAll
        handleSelectDepartment={handleSelectDepartment}
      />
      <SearchContent
        searchValues={searchValues}
        handleSearch={() => {
          setIsSearchVisible(true);
        }}
      ></SearchContent>
      <DataTable
        data={records}
        loading={loading}
        columns={columns}
        handleTableChange={handleTableChange}
        pagination={{ ...pagination, total }}
      ></DataTable>
      <RecordSearchBox
        isModalVisible={isSearchVisible}
        department={selectedDepartment.DepartmentID}
        handleSearch={handleSearch}
        handleCancel={handleCancel}
        recordStatus={paramStatus}
      ></RecordSearchBox>
      <RecordDetail
        selectedRecord={selectedRecord}
        isModalDetailVisible={isModalDetailVisible}
        handleAfterSaved={handleAfterSaved}
        handleDetailCancel={handleDetailCancel}
      ></RecordDetail>
      <Button
        className="psc-center-icon"
        onClick={exportExcel}
        icon={<FileExcelOutlined />}
      >
        Excel
      </Button>{" "}
      <Button
        className="psc-center-icon"
        onClick={exportDocx}
        icon={<FileWordOutlined />}
      >
        Word
      </Button>
    </PageContainer>
  );
}
export default RecordBelowWarningDay;