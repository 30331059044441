import { HistoryOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { Form, Formik } from "formik";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import ApiUtils from "../../../utils/ApiUtils";
import AppUtils from "../../../utils/AppUtils";
import LogByReference from "../Log/LogByReference";

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

function DocumentDetail({
  selectedDocument,
  isModalVisible,
  handleCancel,
  handleAfterSaved,
}) {
  const [editingLoading, setEditingLoading] = useState(false);
  const [currentDocument, setCurrentDocument] = useState({ DocumentID: "" });
  const [initialForm, setInitialForm] = useState({
    externalList: [],
  });

  useEffect(() => {
    setCurrentDocument(selectedDocument);
    setInitialForm({ externalList: selectedDocument.ExternalData });
  }, [selectedDocument]);

  const openFile = (FileID) => {
    ApiUtils.documentFileGetById(FileID, (responseData) => {
      if (!responseData?.Success) {
        AppUtils.showMessage("Thông báo", responseData?.Message);
        return;
      }
      if (responseData?.DownloadUrl) {
        window.open(responseData?.DownloadUrl);
      } else {
        AppUtils.showMessage("Thông báo", "Văn bản này không có file");
        return;
      }
    });
  };

  const reloadDocument = useCallback(() => {
    ApiUtils.documentGetByIdAndRecord(
      currentDocument.DocumentID,
      currentDocument.RecordID,
      (responseData) => {
        if (!responseData) {
          AppUtils.showMessage("Thông báo", "Lỗi kết nối");
          return;
        }
        if (!responseData?.Success) {
          AppUtils.showMessage("Thông báo", responseData.Message);
          return;
        }
        setCurrentDocument(responseData);
      }
    );
  }, [currentDocument]);

  const saveDocument = useCallback(
    (externalData) => {
      if (!currentDocument?.AllowEdit) {
        AppUtils.showMessage("Không được phép cập nhật!");
        return;
      }
      ApiUtils.documentExternalDataUpdateFields(
        currentDocument.DocumentID,
        externalData,
        (responseData) => {
          if (!responseData) {
            AppUtils.showMessage("Thông báo", "Lỗi kết nối");
            return;
          }
          AppUtils.showMessage("Thông báo", responseData?.Message);
          if (responseData?.Success) {
            reloadDocument();
          }
        }
      );
    },
    [currentDocument, reloadDocument]
  );

  const updateDocumentType = (dt) => {
    if (window.confirm("Xác nhận chuyển sang loại văn bản này?")) {
      ApiUtils.documentUpdateDocumentType(
        currentDocument.DocumentID,
        dt.DocumentTypeID,
        (responseData) => {
          AppUtils.showMessage("Thông báo", responseData?.Message);
          if (responseData?.Success) {
            reloadDocument();
          }
        }
      );
    }
  };

  //Log
  const [isModalLogVisible, setIsModalLogVisible] = useState(false);
  const [referenceId, setReferenceId] = useState("");
  const checkLogs = (id) => {
    setReferenceId(id);
    setIsModalLogVisible(true);
  };
  const checkLogCancel = () => {
    setIsModalLogVisible(false);
  };

  return (
    <Formik
      initialValues={initialForm}
      enableReinitialize={true}
      onSubmit={(values) => {
        saveDocument(values.externalList);
      }}
    >
      {({ handleSubmit }) => {
        return (
          <Form>
            <Modal
              title="Nội dung văn bản"
              visible={isModalVisible}
              onCancel={handleCancel}
              maskClosable={true}
              width="85%"
              footer={[
                <FooterContent>
                  {/*<SelectDocumentType handleSelectDocumentType={updateDocumentType} departmentId={currentDocument.DepartmentID} style={{ marginTop: "10px" }} allowEdit={currentDocument?.AllowEdit} />*/}
                  <div>
                    <Button
                      disabled={!AppUtils.userIsSystemAdmin()}
                      icon={<HistoryOutlined />}
                      onClick={() => checkLogs(currentDocument?.DocumentID)}
                    >
                      Logs
                    </Button>
                    <Button key="back" onClick={handleCancel}>
                      Thoát
                    </Button>
                    {/*<Button
                      onClick={() => handleSubmit()}
                      loading={editingLoading}
                      style={{ color: "#5f8dce" }}
                      disabled={!currentDocument?.AllowEdit}
                    >
                      Lưu
                    </Button>*/}
                  </div>
                </FooterContent>,
              ]}
            >
              <table className="table table-bordered table-striped">
                <tbody>
                  <tr>
                    <td width="30%">
                      <h5>Trạng thái</h5>
                    </td>
                    <td>{currentDocument?.DocumentStatusTitle}</td>
                  </tr>
                  <tr>
                    <td>
                      <h5>Trường hợp</h5>
                    </td>
                    <td>{currentDocument?.Method}</td>
                  </tr>
                  <tr>
                    <td>
                      <h5>Nhóm giấy tờ</h5>
                    </td>
                    <td>{currentDocument?.DocumentGroupName}</td>
                  </tr>
                  <tr>
                    <td>
                      <h5>Loại văn bản</h5>
                    </td>
                    <td>{currentDocument?.DocumentTypeName}</td>
                  </tr>
                  <tr>
                    <td>
                      <h5>Tên giấy tờ</h5>
                    </td>
                    <td>{currentDocument?.Title}</td>
                  </tr>
                  <tr>
                    <td>
                      <h5>Trích yếu</h5>
                    </td>
                    <td>{currentDocument?.Sumary}</td>
                  </tr>
                  <tr>
                    <td>
                      <h5>Số ký hiệu</h5>
                    </td>
                    <td>{currentDocument?.SignatureNumber}</td>
                  </tr>
                  <tr>
                    <td>
                      <h5>Số trang</h5>
                    </td>
                    <td>{currentDocument?.PageCount}</td>
                  </tr>
                  <tr>
                    <td>
                      <h5>Người sở hữu</h5>
                    </td>
                    <td>{currentDocument?.Owner}</td>
                  </tr>
                  <tr>
                    <td>
                      <h5>Mã định danh</h5>
                    </td>
                    <td>{currentDocument?.OwnerKey}</td>
                  </tr>
                  <tr>
                    <td>
                      <h5>Người ký duyệt</h5>
                    </td>
                    <td>{currentDocument?.Signer}</td>
                  </tr>
                  <tr>
                    <td>
                      <h5>Chức vụ người ký</h5>
                    </td>
                    <td>{currentDocument?.SignerTitle}</td>
                  </tr>
                  <tr>
                    <td>
                      <h5>Ngày hiệu lực</h5>
                    </td>
                    <td>{currentDocument?.ValidDate}</td>
                  </tr>
                  <tr>
                    <td>
                      <h5>Thời hạn hiệu lực</h5>
                    </td>
                    <td>{currentDocument?.ValidityPeriod}</td>
                  </tr>
                  {currentDocument?.ExternalData?.map((item) => (
                    <tr>
                      <td>
                        <h5>{item.FieldTitle}</h5>
                      </td>
                      <td>{item.FieldValue}</td>
                    </tr>
                  ))}
                  <tr>
                    <td>
                      <h5>File đính kèm</h5>
                    </td>
                    <td>
                      {currentDocument?.Files?.map((item) => (
                        <>
                          <a
                            href="javascript:void()"
                            onClick={() => openFile(item.FileID)}
                          >
                            <img
                              src="/assets/SmartAdmin/img/govfiles/ic_document_available_16px.png"
                              alt=""
                            />{" "}
                            {item.FileName}
                            <br />
                          </a>
                        </>
                      ))}
                    </td>
                  </tr>
                </tbody>
              </table>
              {/*<DocumentExternalDataEdit externalData={selectedDocument.ExternalData} documentId={currentDocument?.DocumentID} AllowEdit={currentDocument?.AllowEdit} />*/}
              <LogByReference
                referenceId={referenceId}
                referenceType={"document"}
                isModallVisible={isModalLogVisible}
                handleCancel={checkLogCancel}
              />
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
}

export default DocumentDetail;
