import React, { useState, useEffect, useCallback } from "react";
import { FileExcelOutlined, FileWordOutlined, EditOutlined } from "@ant-design/icons";
import ApiUtils from "../../../utils/ApiUtils";
import AppUtils from "../../../utils/AppUtils";
import PageContainer from "../../../shared/components/PageContainer/PageContainer";
import SelectDepartmentWithAll from "../Departments/SelectDepartmentWithAll";
import { Button, DatePicker, Modal, Space } from "antd";
import moment from "moment";
import { FilterOutlined } from "@ant-design/icons";
import DataTable from "../../../shared/components/DataTable/DataTable";

const DefaultDate = moment(new Date()).format(AppUtils.DATE_FORMAT);

function DocumentDownloadLogTotal() {
  const [logData, setLogData] = useState([{}]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState(null);

  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setStartDate(DefaultDate);
    setEndDate(DefaultDate);
    setSelectedDepartment(AppUtils.departmentCurrent());
  }, []);

  const columns = [
    {
      title: "Đơn vị",
      dataIndex: "DepartmentName",
      key: "DepartmentName",
    },
    {
      title: "Tài khoản",
      key: "Fullname",
      render: (_, record) =>
        <>{record.Username} - {record.Fullname}</>
    },
    {
      title: "Từ ngày",
      dataIndex: "FromDate",
      key: "FromDate",
    },
    {
      title: "Đến ngày",
      dataIndex: "ToDate",
      key: "ToDate",
    },
    {
      title: "Lượt tải",
      dataIndex: "TotalDownload",
      key: "TotalDownload",
    },
    {
      title: "Dung lượng",
      dataIndex: "SizeText",
      key: "SizeText",
    },
    {
      title: "",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            icon={<EditOutlined />}
            onClick={() => userDetail(record.UserID)}>
            Chi tiết
          </Button>
        </Space>
      ),
    },
  ];

  const getData = useCallback(() => {
    setLoading(true);
    ApiUtils.documentDownloadLogGetTotal(selectedDepartment?.DepartmentID, startDate, endDate
      , (responseData) => {
        setLoading(false);
        if (!responseData) {
          AppUtils.showMessage("Thông báo", "Lỗi kết nối!");
          return;
        }
        if (responseData.length == 1 && !responseData[0].Success) {
          AppUtils.showMessage("Thông báo", responseData[0].Message);
          return;
        }
        setLogData(responseData);
      });
  }, [selectedDepartment]);

  useEffect(() => {
    getData();
  }, [getData]);


  const handleSelectDepartment = (department) => {
    setSelectedDepartment(department);
  };

  //User Detail
  const [userDetailModal, setUserDetailModal] = useState(false);
  const [userDetailData, setUserDetailData] = useState([]);
  const [userDetailSearchText, setUserDetailSearchText] = useState("");
  const userDetailColumns = [
    {
      title: "Tên văn bản",
      dataIndex: "DocumentTitle",
      key: "DocumentTitle",
    },
    {
      title: "Loại VB",
      dataIndex: "DocumentTypeName",
      key: "DocumentTypeName",
    },
    {
      title: "Tên file",
      dataIndex: "DownloadFileName",
      key: "DownloadFileName",
    },
    {
      title: "Ngày tải",
      dataIndex: "DownloadDate",
      key: "DownloadDate",
    },
    {
      title: "Dung lượng",
      dataIndex: "SizeText",
      key: "SizeText",
    },
    {
      title: "",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            icon={<EditOutlined />}
            onClick={() => openFile(record.DownloadLogId)}>
            Xem file
          </Button>
        </Space>
      ),
    },
  ];
  const userDetailCancel = () => {
    setUserDetailModal(false);
  }
  const userDetail = (UserID) => {
    setLoading(true);
    ApiUtils.documentDownloadLogUserDetail(UserID, startDate, endDate
      , (responseData) => {
        setLoading(false);


        if (!responseData) {
          AppUtils.showMessage("Thông báo", "Lỗi kết nối!");
          return;
        }
        if (responseData.length == 1 && !responseData[0].Success) {
          AppUtils.showMessage("Thông báo", responseData[0].Message);
          return;
        }
        setUserDetailData(responseData);
        setUserDetailModal(true);
      });
  }
  const openFile = (DownloadLogId) => {
    let d = userDetailData.find((item) => item.DownloadLogId === DownloadLogId);
    if (d) {
      window.open(d.DownloadUrl);
    }
  }

  return (
    <PageContainer>
      <div className="row">
        <div className="col-md-12">
          <SelectDepartmentWithAll handleSelectDepartment={handleSelectDepartment} />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          Từ ngày:{" "}
          <DatePicker
            defaultValue={moment()}
            format={AppUtils.DATE_FORMAT}
            onChange={(_, dateString) => {
              setStartDate(dateString);
            }}
          />{" "}
          Đến ngày:
          <DatePicker
            defaultValue={moment()}
            format={AppUtils.DATE_FORMAT}
            onChange={(_, dateString) => {
              setEndDate(dateString);
            }}
          />
          {"   "}
          <Button icon={<FilterOutlined />}
            onClick={getData}>Thống kê</Button>
        </div>
      </div>
      <div className="row" style={{ paddingTop: "10px" }}>
        <div className="col-md-12">
          <DataTable
            data={logData}
            columns={columns}
            loading={loading}
            searchText={searchText}
            isSearchable
          />
        </div>
      </div>
      <div className="row" style={{ paddingTop: "10px" }}>
        <div className="col-md-12">
          <Modal visible={userDetailModal}
            title="Chi tiết tải về"
            onCancel={userDetailCancel}
            maskClosable={true}
            width="90%"
          >
            <DataTable
              data={userDetailData}
              columns={userDetailColumns}
              searchText={userDetailSearchText}
              isSearchable
            ></DataTable>
          </Modal>
        </div>
      </div>
    </PageContainer>
  );
}
export default DocumentDownloadLogTotal;