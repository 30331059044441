import React, { useState, useEffect } from "react";
import { Button, Input, Modal } from "antd";

function RecordDetroyReason({
  detroyReason,
  updateDetroyReason,
  isModallVisible,
  handleCancel,
  handleSave,
}) {
  const [reason, setReason] = useState("");
  useEffect(() => {
    setReason(detroyReason);
  }, [detroyReason]);

  const confirmDetroy = () => {
    updateDetroyReason(reason);
    handleSave && handleSave();
  };
  return (
    <Modal
      title="Nhập lý do hủy"
      visible={isModallVisible}
      onCancel={handleCancel}
      maskClosable={true}
      footer={[
        <Button onClick={() => confirmDetroy()} style={{ color: "#bb0a1e" }}>
          Xác nhận hủy
        </Button>,
        <Button key="back" onClick={handleCancel}>
          Thoát
        </Button>,
      ]}
    >
      <Input
        value={reason}
        onChange={(event) => {
          setReason(event.target.value);
        }}
      />
    </Modal>
  );
}

export default RecordDetroyReason;
