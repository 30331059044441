import React, { useEffect, useState } from "react";
import "./App.css";
import "./shared/styles/variable.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./components/HomePage/HomePage";
import { authRoutes, userRoutes } from "./routes/allRoutes";
import Authmiddleware from "./routes/middleware/Authmiddleware";
import "antd/dist/antd.css";
import NonAuthLayout from "./components/NonLayout/NonAuthLayout";
import { VisitCounterContext } from "./AppContexts";
import ApiUtils from "./utils/ApiUtils";

function App() {
  const [visitCount, setVisitCount] = useState(0);

  useEffect(() => {
    ApiUtils.logConnectInsert(() => {});
    ApiUtils.logConnectCount(0, 0, 0, 0, ({ Message }) => {
      setVisitCount(+Message + 1);
    });
  }, []);

  return (
    <VisitCounterContext.Provider value={{ visitCount }}>
      <Router>
        <Routes>
          {authRoutes.map((route, idx) => (
            <Route
              path={route.path}
              key={idx}
              element={
                <Authmiddleware
                  layout={NonAuthLayout}
                  component={route.component}
                  isAuthProtected={false}
                />
              }
            ></Route>
          ))}

          {userRoutes.map((route, idx) => (
            <Route
              path={route.path}
              key={idx}
              element={
                <Authmiddleware
                  layout={HomePage}
                  component={route.component}
                  isAuthProtected={true}
                />
              }
            ></Route>
          ))}
        </Routes>
      </Router>
    </VisitCounterContext.Provider>
  );
}

export default App;
