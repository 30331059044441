import React from "react";
import { Button, Input, Modal, Switch } from "antd";

function CustomerDetail({
  selectedCustomer,
  isModalDetailVisible,
  handleDetailCancel,
}) {
  return (
    <Modal
      title="Thông tin khách hàng"
      visible={isModalDetailVisible}
      onCancel={handleDetailCancel}
      maskClosable={true}
      footer={[
        <Button key="back" onClick={handleDetailCancel}>
          Thoát
        </Button>,
      ]}
    >
      <table border={2} cellPadding={5} style={{width:"100%"}}>
        <tbody>
          <tr>
            <td colSpan={2}>
              <img src={selectedCustomer.IsOrganization ? 'assets/SmartAdmin/img/govfiles/ic_company_16px.png' : 'assets/SmartAdmin/img/govfiles/ic_customer_16px.png'} alt='' />
              {selectedCustomer.IsOrganization ? " Tổ chức" : " Cá nhân"}
            </td>
          </tr>
          <tr>
            <td>Tên</td>
            <td>{selectedCustomer.CustomerName}</td>
          </tr>
          <tr>
            <td>Địa chỉ</td>
            <td>{selectedCustomer.OfficeAddress}</td>
          </tr>
          <tr>
            <td>Tỉnh thành</td>
            <td>{selectedCustomer.Province}</td>
          </tr>
          <tr>
            <td>Quận huyện</td>
            <td>{selectedCustomer.District}</td>
          </tr>
          <tr>
            <td>Phường xã</td>
            <td>{selectedCustomer.Ward}</td>
          </tr>
          <tr>
            <td>Điện thoại</td>
            <td>{selectedCustomer.PhoneNumber}</td>
          </tr>
          <tr>
            <td>Email</td>
            <td>{selectedCustomer.Email}</td>
          </tr>
          <tr>
            <td>Căn cước công dân</td>
            <td>{selectedCustomer.CitizenIdentificationNumber}</td>
          </tr>
          <tr>
            <td>Mã số thuế</td>
            <td>{selectedCustomer.TaxNumber}</td>
          </tr>
          <tr>
            <td>Liên hệ</td>
            <td>{selectedCustomer.ContactName}</td>
          </tr>
        </tbody>
      </table>
    </Modal>
  );
}

export default CustomerDetail;