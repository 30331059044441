import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useContext,
  useRef,
} from "react";
import DataTable from "../../../shared/components/DataTable/DataTable";
import PageContainer from "../../../shared/components/PageContainer/PageContainer";
import SelectDepartmentWithAll from "../Departments/SelectDepartmentWithAll";
import ApiUtils from "../../../utils/ApiUtils";
import AppUtils from "../../../utils/AppUtils";
import { Button, Space } from "antd";
import NewDocumentDetail from "./NewDocumentDetail";
import DocumentSearchBox from "./DocumentSearchBox";
import {
  FileExcelOutlined,
  FileWordOutlined,
  EditOutlined,
  FieldTimeOutlined,
  UserOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import SearchContent from "../../../shared/components/SearchContent";
import { useSearchParams } from "react-router-dom";
import { HomePageContext } from "../../../AppContexts";
import DocumentEdit from "./DocumentEdit";

const DEFAULT_PAGINATION = {
  current: 1,
  pageSize: 10,
};

export const DocumentStatus = {
  isActive: 1,
  unActive: 2,
  other: 99,
  all: 0,
};

export const DocumentStatusInfo = {
  [DocumentStatus.isActive]: { label: "Còn hiệu lực", style: "text-success" },
  [DocumentStatus.unActive]: { label: "Hết hiệu lực", style: "text-warning" },
  [DocumentStatus.other]: { label: "Khác", style: "text-danger" },
  [DocumentStatus.all]: { label: "Tất cả" },
};

function Documents() {
  const { setTitle } = useContext(HomePageContext);
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [total, setTotal] = useState(0);
  const [selectedDepartment, setSelectedDepartment] = useState({
    DepartmentID: AppUtils.ROOT_ID,
  });
  const [columns, setColumns] = useState([]);

  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState({ DocumentID: "" });
  const [searchValues, setSearchValues] = useState({});
  const isFirstRun = useRef(true);

  const [params] = useSearchParams();

  const paramStatus = useMemo(() => {
    return params.get("status") || 0;
  }, [params]);

  useEffect(() => {
    setTitle(
      `Danh sách Văn Bản ${
        paramStatus !== 0
          ? DocumentStatusInfo[paramStatus]?.label?.toLowerCase()
          : ""
      }`
    );
    return () => {
      setTitle();
    };
  }, [setTitle, paramStatus]);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    setSearchValues({});
    setPagination({ ...DEFAULT_PAGINATION });
  }, [paramStatus]);

  const getDocuments = useCallback(() => {
    setLoading(true);
    const { current, pageSize } = pagination;
    ApiUtils.documentSearch(
      selectedDepartment?.DepartmentID || AppUtils.ROOT_ID,
      (current - 1) * pageSize,
      pageSize,
      {
        ...searchValues,
        DocumentStatus: searchValues.DocumentStatus ?? paramStatus,
      },
      (data) => {
        setLoading(false);
        setDocuments(data);
        const currentTotal = data[0]?.TotalDocument || 0;
        if (currentTotal === total) {
          return;
        }
        setTotal(currentTotal);
      }
    );
  }, [selectedDepartment, searchValues, pagination, paramStatus, total]);

  useEffect(() => {
    getDocuments();
  }, [pagination]);

  useEffect(() => {
    let cols = [
      {
        title: "STT",
        dataIndex: "RowNumber",
        key: "RowNumber",
      },
      {
        title: "Số ký hiệu",
        key: "SignatureNumber",
        render: (_, record) => (
          <>
            <img
              src={"assets/SmartAdmin/img/govfiles/ic_invoice_20px.png"}
              alt=""
            />{" "}
            {record.SignatureNumber}
          </>
        ),
      },
      {
        title: "Tên văn bản",
        dataIndex: "Title",
        key: "Title",
      },
      {
        title: "Loại văn bản",
        dataIndex: "DocumentTypeName",
        key: "DocumentTypeName",
      },
      {
        title: "Thông tin văn bản",
        key: "ValidDate",
        render: (_, record) => (
          <>
            <FieldTimeOutlined /> {record.ValidDate}
            <br />
            <UserOutlined /> {record.Owner}
          </>
        ),
      },
      {
        title: "Tình trạng",
        dataIndex: "DocumentStatusTitle",
        key: "DocumentStatusTitle",
      },
      {
        title: "",
        key: "action",
        render: (_, record) => (
          <Space direction="vertical" size="middle">
            <div>
              <Button
                icon={<FileTextOutlined />}
                onClick={() => openDocument(record.DocumentID)}
              >
                Chi tiết
              </Button>
            </div>
            <div>
              <Button
                disabled={
                  !record?.AllowEdit || !AppUtils.userIsDepartmentAdmin()
                }
                icon={<EditOutlined />}
                onClick={() => editDocument(record.DocumentID)}
              >
                Hiệu chỉnh
              </Button>
            </div>
          </Space>
        ),
      },
    ];
    if (AppUtils.userIsSystemAdmin()) {
      cols.splice(5, 0, {
        title: "Đơn vị",
        dataIndex: "DepartmentName",
        key: "DepartmentName",
      });
    }
    setColumns(cols);
  }, []);

  const handleSelectDepartment = (department) => {
    setPagination({ ...DEFAULT_PAGINATION });
    setSelectedDepartment(department);
  };

  const handleTableChange = (pagination) => {
    setPagination(pagination);
    const { current, pageSize } = pagination;
    getDocuments({ index: (current - 1) * pageSize, count: pageSize });
  };

  const handleSearch = useCallback(
    (value) => {
      setSearchValues(value);
      setPagination({ ...DEFAULT_PAGINATION });
      setIsSearchVisible(false);
    },
    [setPagination, getDocuments]
  );

  const handleCancel = () => {
    setIsSearchVisible(false);
  };

  //Export-Data
  const exportExcel = () => {
    setLoading(true);
    ApiUtils.documentSearchExport(
      selectedDepartment?.DepartmentID,
      searchValues,
      (data) => {
        setLoading(false);
        if (!data?.Success) {
          AppUtils.showMessage("Thông báo", data?.Message);
          return;
        }
        AppUtils.excelDownloadXLS(data, data.Name);
      }
    );
  };
  const exportDocx = () => {
    setLoading(true);
    ApiUtils.documentSearchExport(
      selectedDepartment?.DepartmentID,
      searchValues,
      (data) => {
        setLoading(false);
        if (!data?.Success) {
          AppUtils.showMessage("Thông báo", data?.Message);
          return;
        }
        AppUtils.docExport(data, data.Name);
      }
    );
  };

  //edit Doc
  const [isModalDocumentEditVisible, setModalDocumentEditVisible] =
    useState(false);
  const editDocument = (DocumentID) => {
    ApiUtils.documentGetById(DocumentID, (data) => {
      setSelectedDocument(data);
      setModalDocumentEditVisible(true);
    });
  };

  //detail doc
  const [isModalDocumentVisible, setModalDocumentVisible] = useState(false);
  const openDocument = (DocumentID) => {
    ApiUtils.documentGetById(DocumentID, (data) => {
      setSelectedDocument(data);
      setModalDocumentVisible(true);
    });
  };
  const handleDocumentUpdated = () => {
    setModalDocumentVisible(false);
    setModalDocumentEditVisible(false);
    getDocuments();
  };
  const handleDocumentCancel = () => {
    setModalDocumentVisible(false);
    setModalDocumentEditVisible(false);
  };
  const handleAfterSaveDocumentType = () => {
    getDocuments();
  };

  return (
    <PageContainer>
      <SelectDepartmentWithAll
        handleSelectDepartment={handleSelectDepartment}
      />
      <SearchContent
        searchValues={searchValues}
        handleSearch={() => {
          setIsSearchVisible(true);
        }}
      ></SearchContent>
      <div className="alert alert-success" role="alert">
        Tìm được {total} văn bản...
      </div>
      <DataTable
        data={documents}
        loading={loading}
        columns={columns}
        handleTableChange={handleTableChange}
        pagination={{ ...pagination, total }}
      ></DataTable>
      <NewDocumentDetail
        isModalVisible={isModalDocumentVisible}
        selectedDocument={selectedDocument}
        handleAfterSaved={handleDocumentUpdated}
        handleCancel={handleDocumentCancel}
      />
      <DocumentEdit
        isModalVisible={isModalDocumentEditVisible}
        selectedDocument={selectedDocument}
        handleAfterSaved={handleDocumentUpdated}
        handleCancel={handleDocumentCancel}
        handleAfterSaveDocumentType={handleAfterSaveDocumentType}
      />
      <DocumentSearchBox
        isModalVisible={isSearchVisible}
        department={selectedDepartment.DepartmentID}
        docStatus={paramStatus}
        handleSearch={handleSearch}
        handleCancel={handleCancel}
      />
      <Button
        className="psc-center-icon"
        onClick={exportExcel}
        icon={<FileExcelOutlined />}
      >
        Excel
      </Button>{" "}
      <Button
        className="psc-center-icon"
        onClick={exportDocx}
        icon={<FileWordOutlined />}
      >
        Word
      </Button>
    </PageContainer>
  );
}
export default Documents;
