import { Table } from "antd";
import React, { useState, useEffect, useCallback } from "react";
import ApiUtils from "../../../utils/ApiUtils";
import AppUtils from "../../../utils/AppUtils";
import PageContainer from "../../../shared/components/PageContainer/PageContainer";
import SelectDepartmentWithAll from "../Departments/SelectDepartmentWithAll";
import { Button, DatePicker, Space } from "antd";
import moment from "moment";
import { FilterOutlined } from "@ant-design/icons";

const DefaultDate = moment(new Date()).format(AppUtils.DATE_FORMAT);

function ReportLogTitleCount() {
  const [logData, setLogData] = useState([{}]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState(null);

  

  const columns = [
    {
      title: "Tên",
      dataIndex: "TitleName",
      key: "TitleName",
    },
    {
      title: "Lượt tương tác",
      dataIndex: "TotalLog",
      key: "TotalLog",
    },
  ];

  const getData = useCallback(() => {
    ApiUtils.logGetTitleCount(selectedDepartment?.DepartmentID, startDate, endDate
      , (responseData) => {
        if (!responseData) {
          AppUtils.showMessage("Thông báo", "Lỗi kết nối!");
          return;
        }
        if (responseData.length == 1 && !responseData[0].Success) {
          AppUtils.showMessage("Thông báo", responseData[0].Message);
          return;
        }
        setLogData(responseData);
      });
  }, [selectedDepartment]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    setStartDate(DefaultDate);
    setEndDate(DefaultDate);
    setSelectedDepartment(AppUtils.departmentCurrent());
  }, []);

  const handleSelectDepartment = (department) => {
    setSelectedDepartment(department);
  };

  return (
    <PageContainer>
      <div className="row">
        <div className="col-md-12">
          <SelectDepartmentWithAll handleSelectDepartment={handleSelectDepartment} />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          Từ ngày:{" "}
          <DatePicker
            defaultValue={moment()}
            format={AppUtils.DATE_FORMAT}
            onChange={(_, dateString) => {
              setStartDate(dateString);
            }}
          />{" "}
          Đến ngày:
          <DatePicker
            defaultValue={moment()}
            format={AppUtils.DATE_FORMAT}
            onChange={(_, dateString) => {
              setEndDate(dateString);
            }}
          />
          {"   "}
          <Button icon={<FilterOutlined />}
            onClick={getData}>Thống kê</Button>
        </div>
      </div>
      <div className="row" style={{paddingTop:"10px"}}>
        <div className="col-md-12">
          <Table dataSource={logData} columns={columns} />
        </div>
      </div>
    </PageContainer>
  );
}
export default ReportLogTitleCount;