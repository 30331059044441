import ApiUtils from "../../../utils/ApiUtils";
import moment from "moment";
import AppUtils from "../../../utils/AppUtils";
import { DocumentStatus } from "../Documents/Documents";
import { RecordStatus } from "../Records/Records";

export const actions = {
  updateValue: "updateValue",
  updateValueSuccess: "updateValueSuccess",
  updateTotal: "updateTotal",
  updateTotalSuccess: "updateTotalSuccess",
  loadAccLoginToday: "loadAccLoginToday",
  loadAccLoginTodaySuccess: "loadAccLoginTodaySuccess",
  loadAccRequestUnlock: "loadAccRequestUnlock",
  loadAccRequestUnlockSuccess: "loadAccRequestUnlockSuccess",
};

export const initialData = {
  accLoginToday: {
    data: [],
    loading: false,
  },
  accRequestUnlock: {
    data: [],
    loading: false,
  },
  metrics: {
    recordTotal: {
      title: "HỒ SƠ",
      color: "#000",
      value: 0,
      total: 0,
      loading: false,
      loadingTotal: false,
      apiTotal: ApiUtils.dashboardRecordTotal.bind(ApiUtils, RecordStatus.all),
      api: ApiUtils.dashboardRecordTotalToday.bind(ApiUtils, RecordStatus.all),
    },
    recordActive: {
      title: "CÒN HIỆU LỰC",
      color: "#000",
      value: 0,
      total: 0,
      loading: false,
      loadingTotal: false,
      apiTotal: ApiUtils.dashboardRecordTotal.bind(
        ApiUtils,
        RecordStatus.isActive
      ),
      api: ApiUtils.dashboardRecordTotalToday.bind(
        ApiUtils,
        RecordStatus.isActive
      ),
    },
    recordWaitToRemove: {
      title: "CHỜ TIÊU HỦY",
      color: "#f00",
      value: 0,
      total: 0,
      loading: false,
      loadingTotal: false,
      apiTotal: ApiUtils.dashboardRecordTotal.bind(
        ApiUtils,
        RecordStatus.waitToRemove
      ),
      api: ApiUtils.dashboardRecordTotalToday.bind(
        ApiUtils,
        RecordStatus.waitToRemove
      ),
    },
    recordRemoved: {
      title: "ĐÃ HỦY",
      color: "#f00",
      value: 0,
      total: 0,
      loading: false,
      loadingTotal: false,
      apiTotal: ApiUtils.dashboardRecordTotal.bind(
        ApiUtils,
        RecordStatus.removed
      ),
      api: ApiUtils.dashboardRecordTotalToday.bind(
        ApiUtils,
        RecordStatus.removed
      ),
    },
    recordFreeze: {
      title: "ĐÓNG BĂNG",
      color: "#0f0",
      value: 0,
      total: 0,
      loading: false,
      loadingTotal: false,
      apiTotal: ApiUtils.dashboardRecordFreezeTotal.bind(ApiUtils),
      api: ApiUtils.dashboardRecordFreezeTotalToday.bind(ApiUtils),
    },
    document: {
      title: "VĂN BẢN",
      color: "#000",
      value: 0,
      total: 0,
      loading: false,
      loadingTotal: false,
      apiTotal: ApiUtils.dashboardDocumentTotal.bind(
        ApiUtils,
        DocumentStatus.all
      ),
      api: ApiUtils.dashboardDocumentTotalToday.bind(
        ApiUtils,
        DocumentStatus.all
      ),
    },
  },
};

export function metricReducer(state, action) {
  const key = action.key;
  switch (action.type) {
    case actions.updateValue:
      return {
        ...state,
        metrics: {
          ...state.metrics,
          [key]: { ...state.metrics[key], loading: true },
        },
      };
    case actions.updateValueSuccess:
      return {
        ...state,
        metrics: {
          ...state.metrics,
          [key]: { ...state.metrics[key], loading: false, value: action.value },
        },
      };
    case actions.updateTotal:
      return {
        ...state,
        metrics: {
          ...state.metrics,
          [key]: { ...state.metrics[key], loadingTotal: true },
        },
      };
    case actions.updateTotalSuccess:
      return {
        ...state,
        metrics: {
          ...state.metrics,
          [key]: {
            ...state.metrics[key],
            loadingTotal: false,
            total: action.value,
          },
        },
      };
    case actions.loadAccLoginToday:
      return {
        ...state,
        accLoginToday: {
          ...state.accLoginToday,
          loading: true,
        },
      };
    case actions.loadAccLoginTodaySuccess:
      return {
        ...state,
        accLoginToday: {
          ...state.accLoginToday,
          loading: false,
          data: action.value,
        },
      };
    case actions.loadAccRequestUnlock:
      return {
        ...state,
        accRequestUnlock: {
          ...state.accRequestUnlock,
          loading: true,
        },
      };
    case actions.loadAccRequestUnlockSuccess:
      return {
        ...state,
        accRequestUnlock: {
          ...state.accRequestUnlock,
          loading: false,
          data: action.value,
        },
      };
    default:
      return { ...state };
  }
}

export const middleWare = (action, state, dispatch) => {
  if (!action) {
    return;
  }
  const currentMetric = state.metrics[action.key];
  switch (action.type) {
    case actions.updateValue: {
      currentMetric.api((value) => {
        dispatch({
          type: actions.updateValueSuccess,
          key: action.key,
          value: value.Message,
        });
      });
      break;
    }
    case actions.updateTotal: {
      currentMetric.apiTotal((value) => {
        dispatch({
          type: actions.updateTotalSuccess,
          key: action.key,
          value: value.Message,
        });
      });
      break;
    }
    case actions.loadAccLoginToday: {
      ApiUtils.dashboardLoginToday((value) => {
        dispatch({ type: actions.loadAccLoginTodaySuccess, value });
      });
      break;
    }
    case actions.loadAccRequestUnlock: {
      ApiUtils.recordUnlockRequestGet(
        AppUtils.ROOT_ID,
        "01/01/1990",
        moment().format("DD/MM/YYYY"),
        (value) => {
          dispatch({ type: actions.loadAccRequestUnlockSuccess, value });
        }
      );
      break;
    }
    default: {
      return;
    }
  }
};
