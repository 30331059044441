import React, { useState, useEffect, useMemo, useCallback } from "react";
import useScript from "../../../hooks/useScript";
import ClipLoader from "react-spinners/ClipLoader";
import ApiUtils from "../../../utils/ApiUtils";
import AppUtils from "../../../utils/AppUtils";
import DataTable from "../../../shared/components/DataTable/DataTable";
import { Button, Modal } from "antd";
import styled from "styled-components";

const Wrapper = styled.div`
  display: inline-block;
  width: ${({ isFullWidth }) => (isFullWidth ? "100%" : "auto")};
`;

const FullWidthButton = styled(Button)`
  width: ${({ isFullWidth }) => (isFullWidth ? "100%" : "auto")};
`;

const defualtDocumentType = {
  DocumentTypeID: "",
  DocumentTypeName: "Chọn loại văn bản",
};

function SelectDocumentType({
  handleSelectDocumentType,
  departmentId,
  allowEdit,
  isSelectMode = false,
}) {
  const [doctypes, setDocTypes] = useState([defualtDocumentType]);
  const [selectedDocumentType, setSelectedDocumentType] =
    useState(defualtDocumentType);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("abcd");
  const [isModalVisible, setIsModalVisible] = useState(false);

  useScript("plugins/custombox/js/custombox.min.js");
  useScript("plugins/custombox/js/legacy.min.js");

  const getData = () => {
    setIsModalVisible(true);
    setSearchText("");
    setLoading(true);
    ApiUtils.documentTypeGet(departmentId, (data) => {
      setLoading(false);
      if (data.length == 1 && !data[0].Success) {
        if (data[0].Message == AppUtils.TOKEN_EXPIRED_MESSAGE) {
          AppUtils.logout();
          return;
        }
        AppUtils.showMessage("Thông báo", data[0].Message);
        return;
      }
      setDocTypes(data);
    });
  };

  const selectDocumentTypeHandle = useCallback(
    (documentType) => {
      // let d = doctypes.find(
      //   (item) => item.DocumentTypeID === documentType.DocumentTypeID
      // );
      if (documentType) {
        setSelectedDocumentType(documentType);
        handleSelectDocumentType && handleSelectDocumentType(documentType);
        setIsModalVisible(false);
      }
    },
    [doctypes]
  );

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = useMemo(
    () => [
      {
        title: "Loại văn bản",
        dataIndex: "DocumentTypeName",
        key: "DocumentTypeName",
        render: (name, dType) => (
          <tr>
            <td>
              <a
                href="javascript:void(0)"
                onClick={() => selectDocumentTypeHandle(dType)}
              >
                {dType.DocumentTypeName}
              </a>
            </td>
          </tr>
        ),
      },
    ],
    [selectDocumentTypeHandle]
  );

  return (
    <Wrapper isFullWidth={isSelectMode}>
      <FullWidthButton
        isFullWidth={isSelectMode}
        onClick={() => getData()}
        disabled={!allowEdit}
      >
        {isSelectMode
          ? selectedDocumentType?.DocumentTypeName || "Chọn loại văn bản"
          : "Chọn loại văn bản"}
      </FullWidthButton>
      <Modal
        visible={isModalVisible}
        title="Chọn loại văn bản"
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <DataTable
          data={doctypes}
          columns={columns}
          searchText={searchText}
          isSearchable
        ></DataTable>
      </Modal>

      <ClipLoader loading={loading} />
    </Wrapper>
  );
}
export default SelectDocumentType;
