import { Button, Input, Modal, Space, Table } from "antd";
import React, { useState, useEffect, useCallback } from "react";
import { SearchOutlined, FileExcelOutlined, FileWordOutlined } from "@ant-design/icons";
import ApiUtils from "../../../utils/ApiUtils";
import AppUtils from "../../../utils/AppUtils";
import SelectDepartmentWithAll from "../Departments/SelectDepartmentWithAll";
import PageContainer from "../../../shared/components/PageContainer/PageContainer";
import UserDetail from "./UserDetail";
import DataTable from "../../../shared/components/DataTable/DataTable";

const DEFAULT_PAGINATION = {
  current: 1,
  pageSize: 10,
};

function Users() {
  const [users, setUsers] = useState([{ UserID: "" }]);
  const [selectedUser, setSelectedUser] = useState({ UserID: "" });
  const [isModalDetailVisible, setModalDetailVisible] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [total, setTotal] = useState(0);

  const columns = [
    {
      title: "Tên đăng nhập",
      key: "Username",
      render: (_, record) =>
        <a href="javascript:void(0);" onClick={() => openUser(record.UserID)} style={{ color: "#242526" }}>
          {record.Username}
        </a>
    },
    {
      title: "Họ tên",
      key: "Fullname",
      render: (_, record) =>
        <a href="javascript:void(0);" onClick={() => openUser(record.UserID)} style={{ color: "#242526" }}>
          {record.Fullname}
        </a>
    },
    {
      title: "Đơn vị",
      dataIndex: "DepartmentName",
      key: "DepartmentName",
    },
  ];

  const searchData = useCallback(() => {
    setLoading(true);
    const { current, pageSize } = pagination;
    let searchData = { Search: searchText };
    let department = selectedDepartment?.DepartmentID == AppUtils.ROOT_ID ? "all" : selectedDepartment?.DepartmentID;
    ApiUtils.userSearch(department, (current - 1) * pageSize, pageSize, searchData
      , (responseData) => {
        setLoading(false);
        if (responseData.length === 1 && !responseData[0].Success) {
          if (responseData[0].Message === AppUtils.TOKEN_EXPIRED_MESSAGE) {
            AppUtils.logout();
            return;
          }
          AppUtils.showMessage("Thông báo", responseData[0].Message);
          return;
        }
        setUsers(responseData);
        const currentTotal = responseData[0]?.TotalRecords || 0;
        if (currentTotal === total) {
          return;
        }
        setTotal(currentTotal);
      })
  }, [searchText, selectedDepartment, pagination, total])

  const handleTableChange = useCallback(
    (pagination) => {
      setPagination(pagination);
      setTotal(pagination.total);
    },
    [setPagination, setTotal]
  );

  useEffect(() => {
    searchData();
  }, [pagination]);

  /*const getUsers = useCallback(() => {
    if (selectedDepartment?.DepartmentID == AppUtils.ROOT_ID) {
      ApiUtils.userGetAll((data) => {
        setUsers(data);
      });  
    } else {
      ApiUtils.userGetByDepartment(selectedDepartment?.DepartmentID, (data) => {
        setUsers(data);
      });
    }
    
  }, [selectedDepartment]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);*/

  const handleDetailCancel = () => {
    setModalDetailVisible(false);
  };

  const openUser = (id) => {
    ApiUtils.userGetByID(id, (data) => {
      if (!data?.Success) {
        AppUtils.showMessage("Thông báo", data.Message);
        return;
      }
      setSelectedUser(data);
      setModalDetailVisible(true);
    });
  };

  const handleSelectDepartment = (department) => {
    setSelectedDepartment(department);
  };

  //Export-Data
  const exportExcel = () => {
    let searchData = { Search: searchText };
    let department = selectedDepartment?.DepartmentID == AppUtils.ROOT_ID ? "all" : selectedDepartment?.DepartmentID;

    ApiUtils.userSearchExport(department, searchData
      , (responseData) => {
        if (!responseData?.Success) {
          AppUtils.showMessage("Thông báo", responseData?.Message);
          return;
        }
        AppUtils.excelDownloadXLS(responseData, responseData.Name);
      }
    );
  };
  const exportDocx = () => {
    let searchData = { Search: searchText };
    let department = selectedDepartment?.DepartmentID == AppUtils.ROOT_ID ? "all" : selectedDepartment?.DepartmentID;
    ApiUtils.userSearchExport(department, searchData
      , (responseData) => {
        if (!responseData?.Success) {
          AppUtils.showMessage("Thông báo", responseData?.Message);
          return;
        }
        AppUtils.docExport(responseData, responseData.Name);
      }
    );
  };

  return (
    <PageContainer>
      <SelectDepartmentWithAll handleSelectDepartment={handleSelectDepartment} />
      <div className="row">
        <div className="col-md-12">
          Nội dung tìm kiếm{" "}
          <Input
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value);
            }} />
          <br /><br />
          <Button
            icon={<SearchOutlined />}
            onClick={() => searchData()}
          >
            Tra cứu
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <DataTable
            loading={loading}
            data={users}
            columns={columns}
            handleTableChange={handleTableChange}
            pagination={{ ...pagination, total }}
          ></DataTable>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <Button
            className="psc-center-icon"
            onClick={exportExcel}
            icon={<FileExcelOutlined />}
          >
            Excel
          </Button>{" "}
          <Button
            className="psc-center-icon"
            onClick={exportDocx}
            icon={<FileWordOutlined />}
          >
            Word
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <UserDetail
            isModalDetailVisible={isModalDetailVisible}
            selectedUser={selectedUser}
            handleDetailCancel={handleDetailCancel}
          ></UserDetail>
        </div>
      </div>
    </PageContainer>
  );
}

export default Users;
