import React, { useEffect, useState } from "react";
import { Button, DatePicker, Input, Modal, Select } from "antd";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import AppUtils from "../../../utils/AppUtils";
import * as yup from "yup";
import styled from "styled-components";
import moment from "moment";

function RecordCollectEditStorage({
  editingStorage,
  listStorageLifes,
  isModalVisible,
  handleAfterSaved,
  handleCancel,
}) {
  const [editingLoading, setEditingLoading] = useState(false);
  const [editingDate, setEditingDate] = useState("");

  const validationSchema = yup.object({});

  const formik = useFormik({
    initialValues: {
      StorageLifeID: "",
      StorageDate: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      handleAfterSaved && handleAfterSaved(values);
    },
  });

  useEffect(() => {
    if (!editingStorage) {
      return;
    }
    formik.setValues(editingStorage);
  }, [editingStorage]);

  useEffect(() => {
    setStorageLifeList(listStorageLifes);
  }, [listStorageLifes]);

  const CustomSelect = styled(Select)`
    width: 100%;
  `;
  const [storageLifeList, setStorageLifeList] = useState([]);
  const handleSelectStorageLife = ({ value, label }) => {
    formik.setFieldValue("StorageLifeID", value);
    formik.setFieldValue("StorageLifeName", label);
    let stg = listStorageLifes.find((item) => item.StorageLifeID === value);
    formik.setFieldValue("StorageDate", stg.RecordDefaultValue);
    setEditingDate(stg.RecordDefaultValue);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Modal
        title="Thời hạn bảo quản hồ sơ"
        visible={isModalVisible}
        onOk={formik.submitForm}
        onCancel={handleCancel}
        maskClosable={false}
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={editingLoading}
            onClick={formik.submitForm}
          >
            Cập nhật thời hạn bảo quản
          </Button>,
          <Button key="back" onClick={handleCancel}>
            Thoát
          </Button>,
        ]}
      >
        Ngày tạo hồ sơ
        <Input
          name="CreatedDate"
          value={formik.values.CreatedDate}
          onChange={formik.handleChange}
          readOnly={true}
        />
        <br />
        Chọn thời hạn bảo quản
        <CustomSelect
          name="StorageLifeID"
          placeholder="Chọn thời hạn bảo quản"
          defaultValue={
            editingStorage.StorageLifeID ? editingStorage.StorageLifeID : null
          }
          value={formik.values.StorageLifeID}
          options={storageLifeList}
          showSearch
          allowClear
          labelInValue
          fieldNames={{
            label: "StorageLifeName",
            value: "StorageLifeID",
            options: "options",
          }}
          onChange={(value) => {
            handleSelectStorageLife(value);
          }}
        />
        <br />
        Bảo quản đến
        <DatePicker
          disabled={editingDate}
          defaultValue={moment()}
          format={AppUtils.DATE_FORMAT}
          onChange={(_, dateString) => {
            formik.setFieldValue("StorageDate", dateString);
          }}
          allowClear={true}
        />
      </Modal>
    </form>
  );
}

RecordCollectEditStorage.propTypes = {
  editingStorage: PropTypes.object,
  listStorageLifes: PropTypes.object,
  isModalVisible: PropTypes.bool,
  handleAfterSaved: PropTypes.func,
  handleCancel: PropTypes.func,
};

export default RecordCollectEditStorage;
