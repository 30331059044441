import { HistoryOutlined } from "@ant-design/icons";
import { Button, Input, Modal } from "antd";
import { Form, Formik } from "formik";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import ApiUtils from "../../../utils/ApiUtils";
import AppUtils from "../../../utils/AppUtils";
import LogByReference from "../Log/LogByReference";

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 12px;
`;

const FullWidthRow = styled.div`
  grid-column: 1 / span 4;
`;

const FirstHalfWidthRow = styled.div`
  grid-column: 1 / span 2;
`;

const SecondHalfWidthRow = styled.div`
  grid-column: 3 / span 2;
`;

const FormInput = styled(Input)`
  &.ant-input-disabled {
    color: var(--text-color);
  }
`;

function NewDocumentDetail({ selectedDocument, isModalVisible, handleCancel }) {
  const [editingLoading, setEditingLoading] = useState(false);
  const [currentDocument, setCurrentDocument] = useState({ DocumentID: "" });
  const [initialForm, setInitialForm] = useState({
    externalList: [],
  });

  useEffect(() => {
    setCurrentDocument(selectedDocument);
    setInitialForm({ externalList: selectedDocument.ExternalData });
  }, [selectedDocument]);

  const openFile = (FileID) => {
    ApiUtils.documentFileGetById(FileID, (responseData) => {
      if (!responseData?.Success) {
        AppUtils.showMessage("Thông báo", responseData?.Message);
        return;
      }
      if (responseData?.DownloadUrl) {
        window.open(responseData?.DownloadUrl);
      } else {
        AppUtils.showMessage("Thông báo", "Văn bản này không có file");
        return;
      }
    });
  };

  const reloadDocument = useCallback(() => {
    ApiUtils.documentGetByIdAndRecord(
      currentDocument.DocumentID,
      currentDocument.RecordID,
      (responseData) => {
        if (!responseData) {
          AppUtils.showMessage("Thông báo", "Lỗi kết nối");
          return;
        }
        if (!responseData?.Success) {
          AppUtils.showMessage("Thông báo", responseData.Message);
          return;
        }
        setCurrentDocument(responseData);
      }
    );
  }, [currentDocument]);

  const saveDocument = useCallback(
    (externalData) => {
      if (!currentDocument?.AllowEdit) {
        AppUtils.showMessage("Không được phép cập nhật!");
        return;
      }
      ApiUtils.documentExternalDataUpdateFields(
        currentDocument.DocumentID,
        externalData,
        (responseData) => {
          if (!responseData) {
            AppUtils.showMessage("Thông báo", "Lỗi kết nối");
            return;
          }
          AppUtils.showMessage("Thông báo", responseData?.Message);
          if (responseData?.Success) {
            reloadDocument();
          }
        }
      );
    },
    [currentDocument, reloadDocument]
  );

  const updateDocumentType = (dt) => {
    if (window.confirm("Xác nhận chuyển sang loại văn bản này?")) {
      ApiUtils.documentUpdateDocumentType(
        currentDocument.DocumentID,
        dt.DocumentTypeID,
        (responseData) => {
          AppUtils.showMessage("Thông báo", responseData?.Message);
          if (responseData?.Success) {
            reloadDocument();
          }
        }
      );
    }
  };

  //Log
  const [isModalLogVisible, setIsModalLogVisible] = useState(false);
  const [referenceId, setReferenceId] = useState("");
  const checkLogs = (id) => {
    setReferenceId(id);
    setIsModalLogVisible(true);
  };
  const checkLogCancel = () => {
    setIsModalLogVisible(false);
  };

  return (
    <Formik
      initialValues={initialForm}
      enableReinitialize={true}
      onSubmit={(values) => {
        saveDocument(values.externalList);
      }}
    >
      {({ handleSubmit }) => {
        return (
          <Form>
            <Modal
              title="Nội dung văn bản"
              visible={isModalVisible}
              onCancel={handleCancel}
              maskClosable={true}
              width="85%"
              footer={[
                <FooterContent>
                  <div>
                    <Button
                      disabled={!AppUtils.userIsSystemAdmin()}
                      icon={<HistoryOutlined />}
                      onClick={() => checkLogs(currentDocument?.DocumentID)}
                    >
                      Logs
                    </Button>
                    <Button key="back" onClick={handleCancel}>
                      Thoát
                    </Button>
                  </div>
                </FooterContent>,
              ]}
            >
              {currentDocument && (
                <ContentWrapper>
                  <FirstHalfWidthRow>
                    <h5>Mã định danh</h5>
                    <FormInput disabled value={currentDocument.OwnerKey} />
                  </FirstHalfWidthRow>
                  <SecondHalfWidthRow>
                    <h5>Người sở hữu</h5>
                    <FormInput disabled value={currentDocument.Owner} />
                  </SecondHalfWidthRow>
                  <FullWidthRow>
                    <h5>Tên giấy tờ</h5>
                    <FormInput disabled value={currentDocument.Title} />
                  </FullWidthRow>
                  <FullWidthRow>
                    <h5>Trường hợp</h5>
                    <FormInput disabled value={currentDocument.Method} />
                  </FullWidthRow>
                  <FullWidthRow>
                    <h5>Loại văn bản</h5>
                    <FormInput
                      disabled
                      value={currentDocument.DocumentTypeName}
                    />
                  </FullWidthRow>
                  <FullWidthRow>
                    <h5>Trích yếu</h5>
                    <FormInput disabled value={currentDocument.Sumary} />
                  </FullWidthRow>
                  <FirstHalfWidthRow>
                    <h5>Số ký hiệu</h5>
                    <FormInput
                      disabled
                      value={currentDocument.SignatureNumber}
                    />
                  </FirstHalfWidthRow>
                  <SecondHalfWidthRow>
                    <h5>Ngày hiệu lực</h5>
                    <FormInput disabled value={currentDocument.ValidDate} />
                  </SecondHalfWidthRow>
                  <FirstHalfWidthRow>
                    <h5>Thời hạn hiệu lực</h5>
                    <FormInput
                      disabled
                      value={currentDocument.ValidityPeriod}
                    />
                  </FirstHalfWidthRow>
                  <SecondHalfWidthRow>
                    <h5>Người ký duyệt</h5>
                    <FormInput disabled value={currentDocument.Signer} />
                  </SecondHalfWidthRow>
                  <FirstHalfWidthRow>
                    <h5>Chức vự người ký</h5>
                    <FormInput disabled value={currentDocument.SignerTitle} />
                  </FirstHalfWidthRow>
                  <SecondHalfWidthRow>
                    <h5>Phạm vi có hiệu lực</h5>
                    <FormInput disabled />
                  </SecondHalfWidthRow>
                  <FirstHalfWidthRow>
                    <h5>Tổng số trang tài liệu</h5>
                    <FormInput disabled value={currentDocument.PageCount} />
                  </FirstHalfWidthRow>
                  <SecondHalfWidthRow>
                    <h5>Trạng thái tài liệu</h5>
                    <FormInput
                      disabled
                      value={currentDocument.DocumentStatusTitle}
                    />
                  </SecondHalfWidthRow>
                  {currentDocument.Details?.map(
                    ({ FieldTitle, FieldValue }) => (
                      <FullWidthRow>
                        <h5>{FieldTitle}</h5>
                        <FormInput disabled value={FieldValue} />
                      </FullWidthRow>
                    )
                  )}
                  <FullWidthRow>
                    <h5>File đính kèm</h5>
                    {currentDocument?.Files?.map((item) => (
                      <>
                        <a
                          href="javascript:void()"
                          onClick={() => openFile(item.FileID)}
                        >
                          <img
                            src="/assets/SmartAdmin/img/govfiles/ic_document_available_16px.png"
                            alt=""
                          />{" "}
                          {item.FileName}
                          <br />
                        </a>
                      </>
                    ))}
                  </FullWidthRow>
                </ContentWrapper>
              )}
              {/*<DocumentExternalDataEdit externalData={selectedDocument.ExternalData} documentId={currentDocument?.DocumentID} AllowEdit={currentDocument?.AllowEdit} />*/}
              <LogByReference
                referenceId={referenceId}
                referenceType={"document"}
                isModallVisible={isModalLogVisible}
                handleCancel={checkLogCancel}
              />
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
}

export default NewDocumentDetail;
