import React, { useEffect, useState, useCallback } from "react";
import DataTable from "../../../shared/components/DataTable/DataTable";
import PageContainer from "../../../shared/components/PageContainer/PageContainer";
import SelectDepartmentWithAll from "../Departments/SelectDepartmentWithAll";
import ApiUtils from "../../../utils/ApiUtils";
import AppUtils from "../../../utils/AppUtils";
import { Button, Space } from "antd";
import DocumentDetail from "./DocumentDetail";
import DocumentSearchBox from "./DocumentSearchBox";
import styled from "styled-components";
import { FileExcelOutlined, FileWordOutlined } from "@ant-design/icons";

const DEFAULT_PAGINATION = {
  current: 1,
  pageSize: 10,
  total: 0,
};

const SearchWrapper = styled.div`
  display: flex;
`;

const Wrapper = styled.div`
  flex-direction: column;
`;

function DocumentExpiring() {
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [selectedDepartment, setSelectedDepartment] = useState({
    DepartmentID: AppUtils.ROOT_ID,
  });
  const [columns, setColumns] = useState([]);
  const [isModalDocumentVisible, setModalDocumentVisible] = useState(false);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState({ DocumentID: "" });
  const [docStatus, setDocStatus] = useState("0");
  const [searchValues, setSearchValues] = useState({});

  const getDocuments = useCallback(
    ({ index = 0, count = 10, value = {} }) => {
      setLoading(true);
      ApiUtils.documentSearchExpiring(
        selectedDepartment?.DepartmentID || AppUtils.ROOT_ID,
        index,
        count,
        value,
        (data) => {
          setLoading(false);
          setDocuments(data);
          if ((data[0]?.TotalDocument || 0) === pagination.total) {
            return;
          }
          setPagination({
            ...DEFAULT_PAGINATION,
            total: data[0]?.TotalDocument || 0,
          });
        }
      );
    },
    [selectedDepartment, pagination.total]
  );

  useEffect(() => {
    getDocuments({});
  }, [selectedDepartment]);

  useEffect(() => {
    let cols = [
      {
        title: "STT",
        dataIndex: "RowNumber",
        key: "RowNumber",
      },
      {
        title: "Thông tin văn bản",
        key: "TitleDisplay",
        render: (_, record) => (
          <div>
            <a
              href="javascript:void(0);"
              onClick={() => openDocument(record.DocumentID)}
              style={{ color: "#242526" }}
            >
              {record.Title}
              <br />
              Số ký hiệu: {record.SignatureNumber}. - Loại VB:{" "}
              {record.DocumentTypeName}
              <br />
              Ngày hiệu lực: {record.ValidDate}. - Người sở hữu: {record.Owner}
            </a>
          </div>
        ),
      },
      {
        title: "Số ngày",
        dataIndex: "ExpiringDays",
        key: "ExpiringDays",
      },
      {
        title: "",
        key: "action",
        render: (_, record) => (
          <Space size="middle">
            <Button onClick={() => openDocument(record.DocumentID)}>
              Chi tiết
            </Button>
          </Space>
        ),
      },

    ];
    if (AppUtils.userIsSystemAdmin()) {
      cols.splice(3, 0, {
        title: "Đơn vị",
        dataIndex: "DepartmentName",
        key: "DepartmentName",
      });
    }
    setColumns(cols);
  }, []);

  const handleSelectDepartment = (department) => {
    setPagination(DEFAULT_PAGINATION);
    setSelectedDepartment(department);
  };

  const handleTableChange = (pagination) => {
    setPagination(pagination);
    const { current, pageSize } = pagination;
    getDocuments({ index: (current - 1) * pageSize, count: pageSize });
  };

  const openDocument = (DocumentID) => {
    ApiUtils.documentGetById(DocumentID, (data) => {
      setSelectedDocument(data);
      setModalDocumentVisible(true);
    });
  };
  const handleDocumentUpdated = () => {
    setModalDocumentVisible(false);
  };
  const handleDocumentCancel = () => {
    setModalDocumentVisible(false);
  };

  const handleSearch = useCallback(
    (value) => {
      setSearchValues(value);
      setPagination(DEFAULT_PAGINATION);
      getDocuments({ index: 0, count: DEFAULT_PAGINATION.pageSize, value });
      setIsSearchVisible(false);
    },
    [setPagination, getDocuments]
  );

  const handleCancel = () => {
    setIsSearchVisible(false);
  };

  //Export-Data
  const exportExcel = () => {
    setLoading(true);
    ApiUtils.documentSearchExpiringExport(
      selectedDepartment?.DepartmentID,
      searchValues,
      (data) => {
        setLoading(false);
        if (!data?.Success) {
          AppUtils.showMessage("Thông báo", data?.Message);
          return;
        }
        AppUtils.excelDownloadXLS(data, data.Name);
      }
    );
  };
  const exportDocx = () => {
    setLoading(true);
    ApiUtils.documentSearchExpiringExport(
      selectedDepartment?.DepartmentID,
      searchValues,
      (data) => {
        console.log(data);
        setLoading(false);
        if (!data?.Success) {
          AppUtils.showMessage("Thông báo", data?.Message);
          return;
        }
        AppUtils.docExport(data, data.Name);
      }
    );
  };

  return (
    <PageContainer>
      <SelectDepartmentWithAll
        handleSelectDepartment={handleSelectDepartment}
      />
      <Wrapper className="row">
        <SearchWrapper>
          <Button
            onClick={() => {
              setIsSearchVisible(true);
            }}
          >
            Tìm kiếm
          </Button>
        </SearchWrapper>
        <div className="alert alert-success" role="alert">
          Tìm được {pagination.total} văn bản...
        </div>
        <DataTable
          data={documents}
          loading={loading}
          columns={columns}
          handleTableChange={handleTableChange}
          pagination={pagination}
        ></DataTable>
        <DocumentDetail
          isModalVisible={isModalDocumentVisible}
          selectedDocument={selectedDocument}
          handleAfterSaved={handleDocumentUpdated}
          handleCancel={handleDocumentCancel}
        />
        <DocumentSearchBox
          isModalVisible={isSearchVisible}
          department={selectedDepartment.DepartmentID}
          docStatus={docStatus}
          handleSearch={handleSearch}
          handleCancel={handleCancel}
        />

      </Wrapper>
      <Button
        className="psc-center-icon"
        onClick={exportExcel}
        icon={<FileExcelOutlined />}
      >
        Excel
      </Button>{" "}
      <Button
        className="psc-center-icon"
        onClick={exportDocx}
        icon={<FileWordOutlined />}
      >
        Word
      </Button>
    </PageContainer>
  );
}
export default DocumentExpiring;
