import React, { useState } from "react";
import { HomePageContext } from "../../AppContexts";
import ContentPanel from "./ContentPanel";
import HeaderPanel from "./HeaderPanel";
import LeftPanel from "./LeftPanel";

function HomePage({ children }) {
  const [title, setTitle] = useState("");
  return (
    <HomePageContext.Provider value={{ title, setTitle }}>
      <div class="page-wrapper">
        <div class="page-inner">
          <LeftPanel />
          <div class="page-content-wrapper">
            <HeaderPanel />
            <ContentPanel>{children}</ContentPanel>
          </div>
        </div>
      </div>
    </HomePageContext.Provider>
  );
}
export default HomePage;
