import LanguageUtils from "../lang/LanguageUtils";
import AppUtils from "./AppUtils";

class ApiUtils {
  static BASE_URL = "";
  static ITEM_LIMIT = 50;

  static getCurrentYear() {
    return new Date().getFullYear();
  }

  static getCurrentMonth() {
    let month = new Date().getMonth() + 1;
    return month > 9 ? month + "" : "0" + month;
  }

  static getCurrentDay() {
    let day = new Date().getDay();
    return day > 9 ? day + "" : "0" + day;
  }

  static getCurrentDate() {
    return [
      this.getCurrentDay(),
      this.getCurrentMonth(),
      this.getCurrentYear(),
    ].join("/");
  }

  static request(method, route, params, headers, callback) {
    this.requestApi(this.BASE_URL, method, route, params, headers, callback);
  }
  static requestApi(baseUrl, method, route, params, headers, callback) {
    try {
      fetch(baseUrl + route, {
        method: method,
        headers: new Headers(headers),
        body: params ? JSON.stringify(params) : null,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (!AppUtils.isNull(responseJson)) {
            if (!AppUtils.isNull(responseJson.data)) {
              callback(responseJson.data, null);
            } else if (responseJson.Success) {
              if (responseJson.Message && Array.isArray(responseJson.Message)) {
                callback(responseJson.Message, null);
              } else {
                callback(responseJson, responseJson.Message);
              }
            } else if (Array.isArray(responseJson)) {
              callback(responseJson, null);
            } else {
              if (responseJson.Success && responseJson.Success === true) {
                callback(responseJson, responseJson.Message);
              } else {
                var message;
                if (responseJson.Message) {
                  if (Array.isArray(responseJson.Message)) {
                    callback(responseJson.Message, null);
                    return;
                  } else {
                    message = responseJson.Message;
                  }
                }
                callback(responseJson, message);
              }
            }
          } else {
            callback(responseJson, LanguageUtils.values().message_default);
          }
        })
        .catch((error) => {
          console.log(error.message);
          callback(null, LanguageUtils.values().message_default);
        });
    } catch (error) {
      console.log(error.message);
      callback(null, LanguageUtils.values().message_default);
    }
  }

  static login(username, password, callback) {
    let body = { username, password };
    this.requestApi(
      AppUtils.hostApiUrl,
      "POST",
      "Admin/Auth/Login",
      body,
      {
        "Content-Type": "application/json",
      },
      callback
    );
  }

  //AdministrativeProcedure
  static administrativeProcedureSearch(index, count, data, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "POST",
      `Admin/AdminAdministrativeProcedure/Search?index=${index}&count=${count}`,
      data,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static administrativeProcedureSearchExport(data, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "POST",
      `Admin/AdminAdministrativeProcedure/SearchExport`,
      data,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static administrativeProcedureGet(callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/AdministrativeProcedure/Get`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static administrativeProcedureGetById(id, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/AdministrativeProcedure/GetByID?id=${id}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }

  //Customer
  static customerSearch(index, count, data, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "POST",
      `Admin/Customer/Search?index=${index}&count=${count}`,
      data,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static customerSearchExport(data, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "POST",
      `Admin/Customer/SearchExport`,
      data,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static customerGetById(id, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/Customer/GetByID?id=${id}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }

  //Dashboard
  static dashboardRecordTotal(status, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/Dashboard/RecordTotal?status=${status}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static dashboardRecordTotalToday(status, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/Dashboard/RecordTotalToday?status=${status}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static dashboardRecordFreezeTotal(callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/Dashboard/RecordFreezeTotal`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static dashboardRecordFreezeTotalToday(callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/Dashboard/RecordFreezeTotalToday`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static dashboardDocumentTotal(status, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/Dashboard/DocumentTotal?status=${status}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static dashboardDocumentTotalToday(status, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/Dashboard/DocumentTotalToday?status=${status}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static dashboardLoginToday(callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/Dashboard/LoginToday`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }

  //Department
  static departmentGet(callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      "Admin/Department/Get",
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static departmentGetExport(callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      "Admin/Department/GetExport",
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static departmentGetById(id, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/Department/GetByID?id=${id}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }

  //Document
  static documentSearch(department, index, count, data, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "POST",
      `Admin/Document/Search?department=${department}&index=${index}&count=${count}`,
      data,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static documentSearchExport(department, data, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "POST",
      `Admin/Document/SearchExport?department=${department}`,
      data,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static documentSearchExpiring(department, index, count, data, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "POST",
      `Admin/Document/SearchExpiring?department=${department}&index=${index}&count=${count}`,
      data,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static documentSearchExpiringExport(department, data, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "POST",
      `Admin/Document/SearchExpiringExport?department=${department}`,
      data,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static documentGetById(id, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/Document/GetById?id=${id}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static documentGetFileLinkByDoc(id, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/Document/GetFileLinkByDoc?id=${id}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static documentGetByIdAndRecord(id, record, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/Document/GetById?id=${id}&record${record}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static documentInsert(data, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "POST",
      "Admin/Document/Insert",
      data,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static documentUpdate(data, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "POST",
      "Admin/Document/Update",
      data,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static documentUpdateDocumentType(id, type, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/Document/UpdateDocumentType?id=${id}&type=${type}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }

  //DocumentDownloadLog
  static documentDownloadLogGetTotal(department, startdate, enddate, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/DocumentDownloadLog/GetLogTotal?startDate=${startdate}&endDate=${enddate}&department=${department}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static documentDownloadLogUserDetail(userId, startdate, enddate, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/DocumentDownloadLog/UserDetail?startDate=${startdate}&endDate=${enddate}&userId=${userId}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }

  //DocumentExternalData
  static documentExternalDataGetByGetByDocument(doc, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/DocumentExternalData/GetByDocument?doc=${doc}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static documentExternalDataInsertUpdate(data, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "POST",
      "Admin/DocumentExternalData/InsertUpdate",
      data,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static documentExternalDataUpdateFields(doc, data, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "POST",
      `Admin/DocumentExternalData/UpdateFields?doc=${doc}`,
      data,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static documentExternalDataGetByDeleteByDocument(doc, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/DocumentExternalData/DeleteByDocument?doc=${doc}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }

  //DocumentFile
  static documentFileGetById(id, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/DocumentFile/GetByID?id=${id}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static documentFileGetByRecordForDownload(record, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/DocumentFile/GetByRecordForDownload?record=${record}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }

  //DocumentGroup
  static documentGroupGet(callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/DocumentGroup/Get`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }

  //DocumentType
  static documentTypeGet(department, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/DocumentType/Get?department=${department}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static documentTypeGetByDepartment(department, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/DocumentType/GetByDepartment?department=${department}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static documentTypeGetById(id, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/DocumentType/GetById?id=${id}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static documentTypeInsert(data, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "POST",
      "Admin/DocumentType/Insert",
      data,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static documentTypeUpdate(data, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "POST",
      "Admin/DocumentType/Update",
      data,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static documentTypeUpdateToRoot(id, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/DocumentType/UpdateToRoot?id=${id}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static documentTypeDelete(id, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/DocumentType/Delete?id=${id}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }

  //DocumentTypeField
  static documentTypeFieldGet(doctype, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/DocumentTypeField/Get?doctype=${doctype}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static documentTypeFieldGetById(id, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/DocumentTypeField/GetByID?id=${id}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static documentTypeFieldInsert(docType, data, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "POST",
      `Admin/DocumentTypeField/Insert?docType=${docType}`,
      data,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static documentTypeFieldUpdate(data, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "POST",
      "Admin/DocumentTypeField/Update",
      data,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static documentTypeFieldDelete(id, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/DocumentTypeField/Delete?id=${id}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }

  //Log
  static logSearch(index, count, data, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "POST",
      `Admin/Log/SearchPaging?index=${index}&count=${count}`,
      data,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static logGetTitleCount(department, startdate, enddate, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/Log/TitleCount?startDate=${startdate}&endDate=${enddate}&department=${department}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static logGetByReference(id, type, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/Log/GetByReference?id=${id}&type=${type}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static logGetById(LogID, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/Log/GetById?LogID=${LogID}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }

  //LogConnect
  static logConnectInsert(callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/LogConnect/Insert`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static logConnectCount(year, month, day, week, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/LogConnect/Count?year=${year}&month=${month}&day=${day}&week=${week}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }

  //ReceivingAgency
  static receivingAgencyGet(callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/ReceivingAgency/Get`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }

  //Record
  static recordSearch(department, index, count, data, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "POST",
      `Admin/Record/Search?department=${department}&index=${index}&count=${count}`,
      data,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static recordSearchExport(department, data, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "POST",
      `Admin/Record/SearchExport?department=${department}`,
      data,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static recordSearchExportForWord(department, data, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "POST",
      `Admin/Record/SearchExportForWord?department=${department}`,
      data,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static recordSearchBelowWarningDay(department, index, count, data, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "POST",
      `Admin/Record/SearchBelowWarningDay?department=${department}&index=${index}&count=${count}`,
      data,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static recordSearchBelowWarningDayExport(department, data, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "POST",
      `Admin/Record/SearchBelowWarningDayExport?department=${department}`,
      data,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static recordSearchBelowWarningDayExportForWord(department, data, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "POST",
      `Admin/Record/SearchBelowWarningDayExportForWord?department=${department}`,
      data,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static recordGetById(id, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/Record/GetById?id=${id}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static recordGetByReceiptKey(ReceiptKey, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/Record/GetBySBN?sobiennhan=${ReceiptKey}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static recordGetForEditStorage(RecordID, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/Record/GetForEditStorage?record=${RecordID}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static recordListCustmerKey(department, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/Record/ListCustmerKey?department=${department}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static recordListAdministrativeProcedureName(department, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/Record/ListAdministrativeProcedureName?department=${department}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static recordListFieldName(department, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/Record/ListFieldName?department=${department}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static recordListReceivingAgency(department, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/Record/ListReceivingAgency?department=${department}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static recordInsert(data, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "POST",
      "Admin/Record/Insert",
      data,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static recordUpdate(data, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "POST",
      "Admin/Record/Update",
      data,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static recordUpdateNote(data, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "POST",
      "Admin/Record/UpdateNote",
      data,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static recordUpdateStorageDate(data, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "POST",
      "Admin/Record/UpdateStorageDate",
      data,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static recordUpdateStatus(id, status, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/Record/UpdateStatus?id=${id}&status=${status}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static recordLock(id, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/Record/Lock?id=${id}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static recordDetroy(record, data, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "POST",
      `Admin/Record/Detroy?record=${record}`,
      data,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static recordDelete(recordId, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "POST",
      `Admin/Record/Delete?record=${recordId}`,
      {},
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static recordDetroyBySearch(department, data, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "POST",
      `Admin/Record/DetroyBySearch?department=${department}`,
      data,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }

  //RecordCollect
  static recordCollectGetHS(department, startdate, enddate, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/RecordCollect/GetHoSo?startDate=${startdate}&endDate=${enddate}&department=${department}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static recordCollectGetThongTinHS(sobiennhan, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/RecordCollect/ThongTinHoSo?sobiennhan=${sobiennhan}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static recordCollectInsert(department, data, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "POST",
      `Admin/RecordCollect/Insert?department=${department}`,
      data,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }

  //RecordDocument
  static recordDocumentGetForPrintCatalog(record, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/RecordDocument/GetForPrintCatalog?record=${record}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }

  //RecordFreeze
  static recordFreezeGet(department, startdate, enddate, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/RecordFreeze/Get?startDate=${startdate}&endDate=${enddate}&department=${department}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static recordFreezeGetById(id, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/RecordFreeze/GetByID?id=${id}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static recordFreezeGetByRecord(id, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/RecordFreeze/GetByID?id=${id}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static recordFreezeInsert(data, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "POST",
      "Admin/RecordFreeze/Insert",
      data,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }

  //RecordUnlockRequest
  static recordUnlockRequestGet(department, startdate, enddate, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/RecordUnlockRequest/Get?startDate=${startdate}&endDate=${enddate}&department=${department}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static recordUnlockRequestGetById(id, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/RecordUnlockRequest/GetByID?id=${id}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static recordUnlockRequestConfirm(id, status, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/RecordUnlockRequest/Confirm?id=${id}&status=${status}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }

  //Setting
  static settingGet(callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/Setting/Get`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static settingUpdate(data, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "POST",
      "Admin/Setting/Update",
      data,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static systemSettingGet(callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/SystemSetting/Get`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static systemSettingUpdate(data, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "POST",
      "Admin/SystemSetting/Update",
      data,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }

  //StorageLife
  static storageLifeGet(department, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/StorageLife/Get?department=${department}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static storageLifeGetForSelect(department, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/StorageLife/GetForSelect?department=${department}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static storageLifeGetByDepartment(department, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/StorageLife/GetByDepartment?department=${department}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static storageLifeGetById(id, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/StorageLife/GetById?id=${id}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static storageLifeInsert(data, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "POST",
      "Admin/StorageLife/Insert",
      data,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static storageLifeUpdate(data, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "POST",
      "Admin/StorageLife/Update",
      data,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static storageLifeDelete(id, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/StorageLife/Delete?id=${id}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }

  //User
  static userSearch(department, index, count, data, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "POST",
      `Admin/User/Search?department=${department}&index=${index}&count=${count}`,
      data,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static userSearchExport(department, data, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "POST",
      `Admin/User/SearchExport?department=${department}`,
      data,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static userGetAll(callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/User/Get`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static userGetByDepartment(department, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/User/GetByDepartment?department=${department}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
  static userGetByID(id, callback) {
    this.requestApi(
      AppUtils.hostApiUrl,
      "GET",
      `Admin/User/GetByID?id=${id}`,
      null,
      {
        "Content-Type": "application/json",
        Token: AppUtils.userToken(),
      },
      callback
    );
  }
}

export default ApiUtils;
