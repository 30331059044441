import { Button, Input, Space } from "antd";
import React, { useState, useEffect, useCallback } from "react";
import { FileTextOutlined } from "@ant-design/icons";
import ApiUtils from "../../../utils/ApiUtils";
import AppUtils from "../../../utils/AppUtils";
import PageContainer from "../../../shared/components/PageContainer/PageContainer";
import DataTable from "../../../shared/components/DataTable/DataTable";
import {
  SearchOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import LogDetail from "./LogDetail";

const CustomerInfoWrapper = styled.div`
  cursor: ${({ selectable }) => (selectable ? "pointer" : "initial")};
`;

const DEFAULT_PAGINATION = {
  current: 1,
  pageSize: 20,
};

function LogSearch({ handleCustomerSelected }) {
  const [logs, setLogs] = useState([{ LogID: 0 }]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [total, setTotal] = useState(0);

  const columns = [
    {
      title: "STT",
      dataIndex: "RowNumber",
      key: "RowNumber",
    },
    {
      title: "Thao tác",
      dataIndex: "TitleName",
      key: "TitleName",
    },
    {
      title: "Tài khoản",
      dataIndex: "Username",
      key: "Username",
    },
    {
      title: "Đơn vị",
      dataIndex: "DepartmentName",
      key: "DepartmentName",
    },
    {
      title: "IP",
      dataIndex: "HostIP",
      key: "HostIP",
    },
    {
      title: "Ngày giờ",
      dataIndex: "RequestDate",
      key: "RequestDate",
    },
    {
      title: "Phản hồi",
      dataIndex: "ResponseMSText",
      key: "ResponseMSText",
    },
    {
      title: "",
      key: "action",
      render: (_, record) =>
        <Space size="middle">
          <Button
            icon={<FileTextOutlined />}
            onClick={() => openLog(record.LogID)}>
            Chi tiết
          </Button>
        </Space>
    },
  ];

  useEffect(() => {
    searchData();
  }, [pagination]);

  const searchData = useCallback(() => {
    setLoading(true);
    const { current, pageSize } = pagination;
    let searchData = { Search: searchText };
    ApiUtils.logSearch(
      (current - 1) * pageSize,
      pageSize,
      searchData,
      (responseData) => {
        setLoading(false);
        if (responseData.length === 1 && !responseData[0].Success) {
          if (responseData[0].Message === AppUtils.TOKEN_EXPIRED_MESSAGE) {
            AppUtils.logout();
            return;
          }
          AppUtils.showMessage("Thông báo", responseData[0].Message);
          return;
        }
        setLogs(responseData);
        const currentTotal = responseData[0]?.TotalLogs || 0;
        if (currentTotal === total) {
          return;
        }
        setTotal(currentTotal);
      }
    );
  }, [searchText, pagination, total]);

  const handleTableChange = useCallback(
    (pagination) => {
      setPagination(pagination);
      setTotal(pagination.total);
    },
    [setPagination, setTotal]
  );

  const handleExternalSearchChange = useCallback(() => { });

  //Log-Detail
  const [selectedLog, setSelectedLog] = useState({ LogID: 0 });
  const [isModalDetailVisible, setModalDetailVisible] = useState(false);
  const handleDetailCancel = () => {
    setModalDetailVisible(false);
  };

  const openLog = (id) => {
    ApiUtils.logGetById(id, (responseData) => {
      if (!responseData?.Success) {
        AppUtils.showMessage("Thông báo", responseData.Message);
        return;
      }
      setSelectedLog(responseData);
      setModalDetailVisible(true);
    });
  };

  return (
    <PageContainer>
      <div className="row">
        <div className="col-md-12">
          Tìm kiếm{" "}
          <Input
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value);
            }}
          />
          <br />
          <br />
          <Button icon={<SearchOutlined />} onClick={() => searchData()}>
            Tra cứu
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <DataTable
            loading={loading}
            data={logs}
            columns={columns}
            searchText={searchText}
            isSearchable={false}
            handleTableChange={handleTableChange}
            pagination={{ ...pagination, total }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <LogDetail
            isModalDetailVisible={isModalDetailVisible}
            selectedLog={selectedLog}
            handleDetailCancel={handleDetailCancel}
          ></LogDetail>
        </div>
      </div>
    </PageContainer>
  );
}
export default LogSearch;
