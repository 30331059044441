import React, { useState, useEffect } from "react";
import { Button, Modal, Space } from "antd";
import DataTable from "../../../shared/components/DataTable/DataTable";
import ApiUtils from "../../../utils/ApiUtils";
import AppUtils from "../../../utils/AppUtils";
import RecordCollectEditStorage from "./RecordCollectEditStorage";
import moment from "moment";
import RecordCollectEditDocument from "./RecordCollectEditDocument";

const DefaultDate = moment(new Date()).format(AppUtils.DATE_FORMAT);

function RecordCollectEdit({
  selectedRecord,
  departmentId,
  isModalVisible,
  handleDetailCancel,
  handleAfterSaved,
}) {
  const [editingLoading, setEditingLoading] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({ SoBienNhan: "" });

  useEffect(() => {
    setCurrentRecord(selectedRecord);
  }, [selectedRecord]);

  const collectRecordHandle = () => {
    ApiUtils.recordCollectInsert(departmentId, currentRecord
      , (responseData) => {
        if (!responseData) {
          AppUtils.showMessage("Thông báo", "Lỗi kết nối!");
          return;
        }
        AppUtils.showMessage("Thông báo", responseData.Message);
        if (responseData.Success) {
          handleAfterSaved();
        }
      });
  };

  const openFile = (id) => {
    let doc = currentRecord?.DSVanBan.find((item) => item.Id === id);
    if (doc && doc?.Path) {
      window.open("https://" + doc?.Path);
    } else {
      AppUtils.showMessage("Thông báo", "Văn bản này không có file!");
    }
  }

  //StorageEdit
  const [isModalStorageVisible, setModalStorageVisible] = useState(false);
  const [listStorageLifes, setListStorageLifes] = useState([{}]);
  const [editingStorage, setEditingStorage] = useState({});
  const handleStorageLifeEdit = () => {
    ApiUtils.storageLifeGetForSelect(departmentId
      , (responseData) => {
        if (responseData.length == 1 && !responseData[0].Success) {
          if (responseData[0].Message == AppUtils.TOKEN_EXPIRED_MESSAGE) {
            AppUtils.logout();
            return;
          }
          AppUtils.showMessage("Thông báo", responseData[0].Message);
          return;
        }
        setListStorageLifes(responseData);
        setEditingStorage(
          {
            StorageLifeID: currentRecord.IDLoaiVanBan,
            StorageLifeName: currentRecord.DocumentTypeName,
            StorageDate: currentRecord.ThoiHanHieuLuc_Value,
            CreatedDate: DefaultDate
          }
        );
        setModalStorageVisible(true);
      })
  };
  const handleAfterStoraged = (recordStorage) => {
    let newRecord = currentRecord;
    newRecord.IDThoiHanBaoQuan = recordStorage.StorageLifeID;
    newRecord.StorageLifeName = recordStorage.StorageLifeName;
    newRecord.BaoQuanDen = recordStorage.StorageDate;
    setCurrentRecord(newRecord);
    console.log(newRecord);
    setModalStorageVisible(false);
  }
  const handleAfterStorageCancel = () => {
    setModalStorageVisible(false);
  }

  //Documents
  const [isModalDocumentVisible, setModalDocumentVisible] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(false);
  const editDocument = (Id) => {
    let doc = currentRecord?.DSVanBan.find((item) => item.Id === Id);
    if (doc) {
      setSelectedDocument(doc);
      setModalDocumentVisible(true);
    }
  }
  const documentCancel = () => {
    setModalDocumentVisible(false);
  }

  const docCols = [
    {
      title: "Số ký hiệu",
      key: "SignatureNumber",
      render: (_, record) => (
        <div>
          <a
            href="javascript:void(0);"
            onClick={() => editDocument(record.Id)}
            style={{ color: "#242526" }}
          >
            {record.SoKyHieu}
          </a>
        </div>
      ),
    },
    {
      title: "Nội dung",
      key: "Title",
      render: (_, record) => (
        <div>
          <a
            href="javascript:void(0);"
            onClick={() => editDocument(record.Id)}
            style={{ color: "#242526" }}
          >
            {record.NhomVanBan}
            {record?.DocumentTypeName ? `. - ${record.DocumentTypeName}` : ""}
            <br />
            {record.Ten}
          </a>
        </div>
      ),
    },
    {
      title: " ",
      key: "Others",
      render: (_, record) => (
        <div>
          <a
            href="javascript:void(0);"
            onClick={() => editDocument(record.Id)}
            style={{ color: "#242526" }}
          >
            - Sở hữu: {record.NguoiSoHuu}
            <br />- Ngày hiệu lực: {record.ThoiHanHieuLuc}
          </a>
        </div>
      ),
    },
    {
      title: "",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => openFile(record.Id)}>
            Xem File
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <Modal
      title="Thông tin hồ sơ"
      visible={isModalVisible}
      onCancel={handleDetailCancel}
      maskClosable={false}
      width="70%"
      footer={[
        <Button
          onClick={() => collectRecordHandle()}
          loading={editingLoading}
          style={{ color: "#5f8dce" }}
        >
          Thu thập
        </Button>,
        <Button key="back" onClick={handleDetailCancel}>
          Thoát
        </Button>,
      ]}
    >
      <table border={2} cellPadding={5} style={{ width: "100%" }}>
        <tbody>
          <tr>
            <td width="30%">Bảo quản</td>
            <td>
              <a
                href="javascript:void(0);"
                style={{ color: "#033500" }}
                onClick={() => handleStorageLifeEdit()}
              >
                <img
                  src="/assets/SmartAdmin/img/govfiles/ic_calendar_16px.png"
                  alt=""
                />{" "}
                {currentRecord?.StorageLifeName ? currentRecord?.StorageLifeName : "Chưa thiết lập"}{" "}
                {currentRecord?.ThoiHanHieuLuc_Value
                  ? ` - Dự kiến đến ${currentRecord?.ThoiHanHieuLuc_Value}`
                  : ""}
              </a>
            </td>
          </tr>
          <tr>
            <td>Số biên nhận</td>
            <td>{currentRecord?.SoBienNhan}</td>
          </tr>
          <tr>
            <td>Mã HS DVCQG</td>
            <td>{currentRecord?.MaHoSoDVCQG}</td>
          </tr>
          <tr>
            <td>Mã định danh</td>
            <td>{currentRecord?.MaKhachHang}</td>
          </tr>
          <tr>
            <td>Họ tên khách hàng</td>
            <td>{currentRecord?.HoTenKhachHang}</td>
          </tr>
          <tr>
            <td>Cơ quan tiếp nhận</td>
            <td>{currentRecord?.CoQuanTiepNhan}</td>
          </tr>
          <tr>
            <td>Tên thủ tục hành chính</td>
            <td>{currentRecord?.TenThuTuc}</td>
          </tr>
          <tr>
            <td>Trích yếu hồ sơ</td>
            <td>{currentRecord?.TrichYeu}</td>
          </tr>
          <tr>
            <td>Tên lĩnh vực</td>
            <td>{currentRecord?.TenLinhVuc}</td>
          </tr>
        </tbody>
      </table>
      <h3 style={{ paddingTop: "10px" }}>Danh sách văn bản</h3>
      <DataTable
        data={currentRecord?.DSVanBan}
        columns={docCols}
        pagination={false}
      ></DataTable>
      <RecordCollectEditStorage
        isModalVisible={isModalStorageVisible}
        listStorageLifes={listStorageLifes}
        editingStorage={editingStorage}
        handleAfterSaved={handleAfterStoraged}
        handleCancel={handleAfterStorageCancel}
      />
      <RecordCollectEditDocument
        currentDocument={selectedDocument}
        isModalVisible={isModalDocumentVisible}
        handleCancel={documentCancel}
      />
    </Modal>
  );
}

export default RecordCollectEdit;
