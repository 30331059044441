import React from "react";
import { Input } from "antd";
import DataTable from "../../../shared/components/DataTable/DataTable";
import PropTypes from "prop-types";
import { Field, FieldArray } from "formik";

const externalData_InitColumns = [
  {
    title: "Tiêu đề",
    key: "FieldTitle",
    dataIndex: "FieldTitle",
  },
  {
    title: "Giá trị",
    key: "FieldValue",
    dataIndex: "FieldValue",
    editable: true,
  },
];

const EditableCell = ({
  eddting,
  dataIndex,
  title,
  record,
  index,
  children,
  rowIndex,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {record ? (
        eddting ? (
          <Field
            name={`externalList[${rowIndex}].${dataIndex}`}
            as={Input}
          ></Field>
        ) : (
          record[dataIndex]
        )
      ) : (
        ""
      )}
    </td>
  );
};

function DocumentExternalDataEdit({ externalData = [] }) {
  const mergedColumns = externalData_InitColumns.map((col) => {
    return {
      ...col,
      onCell: (record, rowIndex) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        eddting: col.editable,
        rowIndex,
      }),
    };
  });

  return (
    <>
      {externalData.length ? (
        <>
          <h3 style={{ paddingTop: "10px" }}>Đặc tả</h3>
          <FieldArray
            name="externalList"
            render={(arrayHelpers) => (
              <DataTable
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                data={externalData}
                columns={mergedColumns}
                pagination={false}
              ></DataTable>
            )}
          ></FieldArray>
        </>
      ) : (
        ""
      )}
    </>
  );
}

DocumentExternalDataEdit.propTypes = {
  AllowEdit: PropTypes.bool,
  externalData: PropTypes.func,
  handleSave: PropTypes.func,
};

export default DocumentExternalDataEdit;
