import React, { useCallback, useRef } from "react";
import { Button, Modal } from "antd";
import styled from "styled-components";
import { useReactToPrint } from "react-to-print";

const Wrapper = styled.div``;

function RecordDocumentCatalog({
  currentRecord,
  listDocuments,
  isModalVisible,
  handleCancel,
}) {
  const contentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => contentRef.current,
  });

  return (
    <Modal
      title="Mục lục hồ sơ"
      visible={isModalVisible}
      onCancel={handleCancel}
      maskClosable={true}
      width="85%"
      footer={[
        <Button key="back" onClick={handleCancel}>
          Thoát
        </Button>,
        <Button key="back" onClick={handlePrint}>
          In
        </Button>,
      ]}
    >
      <Wrapper ref={contentRef}>
        <h2>Mục lục hồ sơ {currentRecord.ReceiptKey}</h2>
        <table className="table table-bordered table-striped">
          <tr>
            <th width="10%">STT</th>
            <th>Số, ký hiệu văn bản</th>
            <th>Ngày tháng năm văn bản</th>
            <th>Tên loại và trích yếu nội dung văn bản</th>
            <th>Tác giả văn bản</th>
            <th>Tờ số / Trang số</th>
            <th width="20%">Ghi chú</th>
          </tr>
          {listDocuments?.map((item) => (
            <tr>
              <td>{item.RowNumber}</td>
              <td>{item.SignatureNumber}</td>
              <td>{item.ValidDate}</td>
              <td>
                {item.DocumentTypeName} {item.Sumary}
              </td>
              <td>{item.Signer}</td>
              <td>{item.PageDisplay}</td>
              <td> </td>
            </tr>
          ))}
        </table>
      </Wrapper>
    </Modal>
  );
}

export default RecordDocumentCatalog;
