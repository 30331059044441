import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Table from "antd/lib/table";
import { Input } from "antd";
import isString from "lodash/isString";
import AppUtils from "../../../utils/AppUtils";

const { Search } = Input;

// styled component
const Wrapper = styled.div``;

const Toolbox = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
`;

function DataTable({
  data,
  columns,
  searchText = {},
  isSearchable = false,
  loading,
  handleTableChange,
  bordered = true,
  handleExternalSearchChange,
  ...rest
}) {
  const [sourceData, setSourceData] = useState(data);
  const [textSearch, setTextSearch] = useState(searchText);

  const handleSearch = useCallback(
    (e) => {
      const value = e.target.value;
      setTextSearch(value);
      if (handleExternalSearchChange) {
        handleExternalSearchChange(searchText);
        return;
      }
      setSourceData(
        value
          ? data.filter((item) => {
              return columns.find((col) => {
                const valueToSearch = item[col.key] || item[col.dataIndex];
                if (
                  !valueToSearch ||
                  (typeof valueToSearch !== "string" &&
                    !(valueToSearch instanceof String))
                ) {
                  return false;
                }
                return AppUtils.removeVietmanmeseCode(valueToSearch)
                  .toLowerCase()
                  .trim()
                  .includes(
                    AppUtils.removeVietmanmeseCode(value).toLowerCase().trim()
                  );
              });
            })
          : data
      );
    },
    [sourceData, columns, data]
  );

  useEffect(() => {
    setSourceData(data);
  }, [data]);

  useEffect(() => {
    const actualText = isString(searchText) ? searchText : "";
    setTextSearch(actualText);
    handleSearch({ target: { value: actualText } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  return (
    <Wrapper>
      <Toolbox>
        {isSearchable && (
          <Search
            value={textSearch}
            allowClear={true}
            onChange={handleSearch}
            placeholder="Tìm kiếm"
          ></Search>
        )}
      </Toolbox>

      <Table
        dataSource={sourceData}
        columns={columns}
        pagination={{
          size: "small",
        }}
        loading={loading}
        onChange={handleTableChange}
        bordered={bordered}
        {...rest}
      />
    </Wrapper>
  );
}

export default DataTable;
