import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AppUtils from "../../utils/AppUtils";
import ApiUtils from "../../utils/ApiUtils";
import ClipLoader from "react-spinners/ClipLoader";
import { Button } from "antd";

function LoginPage() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("789789");
  const [loading, setLoading] = useState(false);

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleLogin = (event) => {
    event.stopPropagation();
    if (!username || !password) {
      AppUtils.showMessage(
        "Thông báo",
        "Tên đăng nhập/Mật mã không được để trống!"
      );
      return;
    }
    setLoading(true);
    ApiUtils.login(username, password, (data) => {
      setLoading(false);
      if (!data) {
        AppUtils.showMessage("Thông báo", "Đăng nhập thất bại");
      } else if (!data.Success) {
        AppUtils.showMessage("Thông báo", data.Message);
      } else {
        AppUtils.setAuthUser(data);
        navigate("/");
      }
    });
  };

  return (
    <>
      <div class="psc-box-center">
        <div class="col-sm-12 col-md-6 col-lg-5 col-xl-4 mx-auto">
          <h1 class="fw-500 mb-3 text-center text-uppercase mt-4 text-primary">
            Đăng Nhập Kho Lưu Trữ
          </h1>
          <div class="card p-4 rounded-plus bg-faded">
            <form id="js-login" method="post">
              <div class="form-group mb-2">
                <label class="form-label">Tên đăng nhập</label>
                <input
                  value={username}
                  onChange={handleUsernameChange}
                  className="form-control"
                  type="text"
                  required=""
                  placeholder="Tên đăng nhập"
                />
              </div>
              <div class="form-group mb-2">
                <label class="form-label" asp-for="Input.Password">
                  Mật khẩu
                </label>
                <input
                  value={password}
                  onChange={handlePasswordChange}
                  className="form-control"
                  type="password"
                  required=""
                  placeholder="Mật mã"
                />
              </div>
              <div class="row no-gutters">
                <div class="col-lg-6 mx-auto mt-3">
                  <Button
                    type="primary"
                    className="mx-auto d-flex"
                    onClick={handleLogin}
                  >
                    Đăng nhập
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ClipLoader loading={loading} />
    </>
  );
}

export default LoginPage;
