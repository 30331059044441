import React, { Children, useContext } from "react";
import moment from "moment";
import { useLocation, matchRoutes } from "react-router-dom";
import { RouteNames } from "../../../routes/allRoutes";
import Footer from "../Footer";
import { userRoutes } from "../../../routes/allRoutes";
import { HomePageContext } from "../../../AppContexts";

function ContentPanel({ children }) {
  const { title } = useContext(HomePageContext);
  const { pathname } = useLocation();
  const [{ route }] = matchRoutes(userRoutes, pathname);

  return (
    <>
      <main id="js-page-content" role="main" class="page-content">
        <ol class="breadcrumb page-breadcrumb">
          <li class="breadcrumb-item">
            <a href="javascript:void(0);">Kho Lưu Trữ</a>
          </li>
          {/* <li class="breadcrumb-item">category_1</li>
          <li class="breadcrumb-item">category_2</li> */}
          <li class="breadcrumb-item active">
            {title || RouteNames[route.path]}
          </li>
          <li class="position-absolute pos-top pos-right d-none d-sm-block">
            <span>{moment().format("DD/MM/YYYY")}</span>
          </li>
        </ol>
        <div class="row">
          <div class="col-xl-12">
            {[
              "/Dashboard",
              "/SystemAdminDashboard",
              "/DepartmentAdminDashboard",
            ].includes(route.path) ? (
              <>{children}</>
            ) : (
              <div id="panel-1" class="panel">
                <div class="panel-hdr">
                  <h2>
                    <b>{title || RouteNames[route.path]}</b>
                  </h2>
                </div>
                <div class="panel-container show">
                  <div class="panel-content">{children}</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
      {/* this overlay is activated only when mobile menu is triggered */}
      <div
        class="page-content-overlay"
        data-action="toggle"
        data-class="mobile-nav-on"
      ></div>
      {/* END Page Content */}
      <Footer></Footer>
    </>
  );
}

export default ContentPanel;
