import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useContext,
  useRef,
} from "react";
import DataTable from "../../../shared/components/DataTable/DataTable";
import PageContainer from "../../../shared/components/PageContainer/PageContainer";
import SelectDepartmentWithAll from "../Departments/SelectDepartmentWithAll";
import ApiUtils from "../../../utils/ApiUtils";
import AppUtils from "../../../utils/AppUtils";
import RecordSearchBox from "./RecordSearchBox";
import { Button, Modal, Space } from "antd";
import {
  FileExcelOutlined,
  FileWordOutlined,
  FileTextOutlined,
  EditOutlined,
  CloseSquareOutlined,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import SearchContent from "../../../shared/components/SearchContent";
import { useSearchParams } from "react-router-dom";
import { HomePageContext } from "../../../AppContexts";
import RecordDetroyReason from "./RecordDetroyReason";
import RecordDetail from "./RecordDetailModal";

export const RecordStatus = {
  all: 0,
  isActive: 1,
  waitToRemove: 2,
  removed: 3,
};

export const RecordStatusInfo = {
  [RecordStatus.isActive]: { label: "Đang hiệu lực", style: "text-success" },
  [RecordStatus.waitToRemove]: { label: "Chờ tiêu hủy", style: "text-warning" },
  [RecordStatus.removed]: { label: "Bị tiêu hủy", style: "text-danger" },
  [RecordStatus.all]: { label: "Tất cả" },
};

const DEFAULT_PAGINATION = {
  current: 1,
  pageSize: 10,
};

function Records() {
  const { setTitle } = useContext(HomePageContext);
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [searchValues, setSearchValues] = useState({});

  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [total, setTotal] = useState(0);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [params] = useSearchParams();
  const isFirstRun = useRef(true);

  const paramStatus = useMemo(() => {
    return params.get("status") || 0;
  }, [params]);

  useEffect(() => {
    setTitle(
      `Danh sách Hồ Sơ ${
        paramStatus != 0
          ? RecordStatusInfo[paramStatus]?.label?.toLowerCase()
          : ""
      }`
    );
    return () => {
      setTitle();
    };
  }, [setTitle, paramStatus]);

  useEffect(() => {
    getRecords();
  }, [pagination]);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    setSearchValues({});
    setPagination({ ...DEFAULT_PAGINATION });
  }, [paramStatus]);

  //Department
  const [selectedDepartment, setSelectedDepartment] = useState({
    DepartmentID: AppUtils.ROOT_ID,
  });
  const handleSelectDepartment = (department) => {
    setPagination({ ...DEFAULT_PAGINATION });
    setSelectedDepartment(department);
  };

  //Detail
  const [selectedRecord, setSelectedRecord] = useState({ RecordID: "" });
  const [isModalDetailVisible, setModalDetailVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const openDetail = (RecordID) => {
    ApiUtils.recordGetById(RecordID, (data) => {
      if (!data?.Success) {
        AppUtils.showMessage("Thông báo", data?.Message);
        return;
      }
      setSelectedRecord(data);
      setModalDetailVisible(true);
      setIsEditMode(false);
    });
  };

  const handleDetailCancel = () => {
    setModalDetailVisible(false);
    setIsEditMode(false);
  };

  const handleAfterSaved = () => {
    setModalDetailVisible(false);
    getRecords();
    setIsEditMode(false);
  };

  //Data
  const getRecords = useCallback(() => {
    setLoading(true);
    const { current, pageSize } = pagination;
    ApiUtils.recordSearch(
      selectedDepartment?.DepartmentID,
      (current - 1) * pageSize,
      pageSize,
      {
        ...searchValues,
        RecordStatus: searchValues.RecordStatus ?? paramStatus,
      },
      (data) => {
        setLoading(false);
        setSelectedRowKeys([]);
        if (data.length === 1 && !data[0].Success) {
          if (data[0].Message === AppUtils.TOKEN_EXPIRED_MESSAGE) {
            AppUtils.logout();
            return;
          }
          AppUtils.showMessage("Thông báo", data[0].Message);
          return;
        }
        setRecords(
          data.map((item) => ({
            ...item,
            key: item.RecordID,
          }))
        );
        const currentTotal = data[0]?.TotalRecord || 0;
        if (currentTotal === total) {
          return;
        }
        setTotal(currentTotal);
      }
    );
  }, [selectedDepartment, searchValues, pagination, paramStatus, total]);

  const columns = useMemo(() => {
    let cols = [
      {
        title: "STT",
        dataIndex: "RowNumber",
        key: "RowNumber",
      },
      {
        title: "Mã",
        key: "ID",
        render: (_, record) => (
          <div>
            {record.ReceiptKey ? (
              <>
                <img
                  src={"assets/SmartAdmin/img/govfiles/ic_invoice_20px.png"}
                  alt=""
                />{" "}
                {record.ReceiptKey}
              </>
            ) : (
              ""
            )}
            {record.AdministrativeFileNumber ? (
              <>
                <br />
                <img
                  src={
                    "assets/SmartAdmin/img/govfiles/pc_policy_document_20px.png"
                  }
                  alt=""
                />{" "}
                {record.AdministrativeFileNumber}
              </>
            ) : (
              ""
            )}
          </div>
        ),
      },
      {
        title: "Nội dung",
        key: "Sumary",
        render: (_, record) => (
          <div>
            {record.LastVisitDate ? (
              ""
            ) : (
              <>
                <img
                  src={"assets/SmartAdmin/img/govfiles/new_gif.gif"}
                  alt=""
                />{" "}
              </>
            )}
            {record.Sumary}
          </div>
        ),
      },
      {
        title: "Bảo quản",
        key: "StorageLifeName",
        render: (_, record) => (
          <div>
            {record.StorageLifeName} - Đến ngày {record.StorageDate}
          </div>
        ),
      },
      {
        title: "Tình trạng",
        key: "StatusDisplay",
        render: (_, record) => (
          <div>
            <img
              src={
                record.IsFreeze
                  ? "assets/SmartAdmin/img/govfiles/ic_freeze_16px.png"
                  : record.IsLocked
                  ? "assets/SmartAdmin/img/govfiles/ic_lock_16px.png"
                  : "assets/SmartAdmin/img/govfiles/ic_edit_16px.png"
              }
              alt=""
            />{" "}
            <span style={{ color: record.StatusColor }}>
              {record.StatusDisplay}
            </span>
          </div>
        ),
      },
      {
        title: "",
        key: "action",
        render: (_, record) => (
          <Space direction="vertical" size="middle">
            <Button
              icon={<FileTextOutlined />}
              onClick={() => openDetail(record.RecordID)}
            >
              Chi tiết
            </Button>
            {AppUtils.userIsSystemAdmin() &&
              AppUtils.getAuthUser().Username === "psc" && (
                <>
                  <Button
                    icon={<EditOutlined />}
                    onClick={() => editRecord(record.RecordID)}
                  >
                    Hiệu chỉnh
                  </Button>
                  <Button
                    icon={<DeleteOutlined />}
                    onClick={() => deleteRecord(record.RecordID)}
                  >
                    Xoá
                  </Button>{" "}
                </>
              )}
            {paramStatus == RecordStatus.waitToRemove ? (
              <Button
                icon={<CloseSquareOutlined />}
                onClick={() => detroyRecord(record.RecordID)}
                loading={record.isDestroying}
              >
                Tiêu hủy
              </Button>
            ) : (
              ""
            )}
          </Space>
        ),
      },
    ];
    if (AppUtils.userIsSystemAdmin()) {
      cols.splice(cols.length - 1, 0, {
        title: "Đơn vị",
        dataIndex: "DepartmentName",
        key: "DepartmentName",
      });
    }
    if (paramStatus == RecordStatus.removed) {
      cols.splice(cols.length - 2, 0, {
        title: "Nội dung hủy",
        key: "DetroyReason",
        render: (_, record) => (
          <div>
            {record.DetroyDate}. - {record.DetroyName}
            <br />
            Lý do: {record.DetroyReason}
          </div>
        ),
      });
    }
    return cols;
  }, [paramStatus]);
  const handleTableChange = useCallback(
    (pagination) => {
      setPagination(pagination);
      setTotal(pagination.total);
    },
    [setPagination, setTotal]
  );

  //SearchBox
  const handleSearch = useCallback((value) => {
    setSearchValues(value);
    setPagination({ ...DEFAULT_PAGINATION });
    setIsSearchVisible(false);
  }, []);

  const handleCancel = () => {
    setIsSearchVisible(false);
  };

  //detroyRecord
  const [detroyAllRecordLoading, setDetroyAllRecordLoading] = useState(false);
  const [modalDetroyRecordReason, setModalDetroyRecordReason] = useState(false);
  const [detroyRecordReason, setDetroyRecordReason] = useState("");
  const [detroyRecordID, setDetroyRecordID] = useState("");
  const detroyRecord = (RecordID) => {
    setDetroyRecordID(RecordID);
    setModalDetroyRecordReason(true);
  };
  const detroyRecordConfirm = () => {
    setModalDetroyRecordReason(false);
    ApiUtils.recordDetroy(
      detroyRecordID,
      { Reason: detroyRecordReason },
      (responseData) => {
        if (!responseData) {
          AppUtils.showMessage("Thông báo", "Lỗi kết nối");
          return;
        } else {
          AppUtils.showMessage("Thông báo", responseData.Message);
          setDetroyRecordID("");
          if (responseData.Success) {
            getRecords();
            //reload trang hiện tại
          }
        }
      }
    );
  };

  const detroyAllRecords = () => {
    setDetroyRecordID("");
    setModalDetroyRecordReason(true);
  };

  const detroySelectedRecords = () => {
    setDetroyRecordID("selected");
    setModalDetroyRecordReason(true);
  };

  const detroySelectedRecordConfirm = () => {
    if (selectedRowKeys.length === 0) {
      return;
    }
    setModalDetroyRecordReason(false);
    Modal.confirm({
      title: `Xác nhận tiêu hủy ${selectedRowKeys.length} hồ sơ đã chọn ?`,
      onOk() {
        setRecords(
          records.map((record) => ({
            ...record,
            isDestroying: selectedRowKeys.includes(record.RecordID)
              ? true
              : record.isDestroying,
          }))
        );
        let remainingRecordToDestroy = selectedRowKeys.length;
        selectedRowKeys.forEach((recordID) => {
          ApiUtils.recordDetroy(
            recordID,
            { Reason: detroyRecordReason },
            (responseData) => {
              if (!responseData) {
                AppUtils.showMessage("Thông báo", "Lỗi kết nối");
                return;
              } else {
                setRecords(
                  records.map((record) => ({
                    ...record,
                    isDestroying:
                      recordID === record.RecordID
                        ? false
                        : record.isDestroying,
                  }))
                );
                setDetroyRecordID("");
                if (responseData.Success) {
                  remainingRecordToDestroy -= 1;
                  if (remainingRecordToDestroy === 0) {
                    AppUtils.showMessage("Thông báo", responseData.Message);
                    getRecords();
                    setSelectedRowKeys([]);
                  }
                }
              }
            }
          );
        });
      },
    });
  };

  const detroyAllRecordConfirm = () => {
    setModalDetroyRecordReason(false);
    if (window.confirm("Xác nhận tiêu hủy toàn bộ hồ sơ?")) {
      setDetroyAllRecordLoading(true);
      let data = searchValues;
      data.Reason = detroyRecordReason;
      ApiUtils.recordDetroyBySearch(
        selectedDepartment.DepartmentID,
        data,
        (responseData) => {
          if (!responseData) {
            AppUtils.showMessage("Thông báo", "Lỗi kết nối");
            return;
          } else {
            AppUtils.showMessage("Thông báo", responseData.Message);
            if (responseData.Success) {
              setSearchValues({});
              setPagination({ ...DEFAULT_PAGINATION });
              //search lại từ đầu
            }
          }
        }
      );
    }
  };
  const updateDetroyReason = (reason) => {
    setDetroyRecordReason(reason);
  };
  const detroyRecordCancel = () => {
    setModalDetroyRecordReason(false);
  };

  //Export-Data
  const exportExcel = () => {
    setLoading(true);
    ApiUtils.recordSearchExport(
      selectedDepartment?.DepartmentID,
      searchValues,
      (data) => {
        setLoading(false);
        if (!data?.Success) {
          AppUtils.showMessage("Thông báo", data?.Message);
          return;
        }
        AppUtils.excelDownloadXLS(data, data.Name);
      }
    );
  };
  const exportDocx = () => {
    setLoading(true);
    ApiUtils.recordSearchExportForWord(
      selectedDepartment?.DepartmentID,
      searchValues,
      (data) => {
        setLoading(false);
        if (!data?.Success) {
          AppUtils.showMessage("Thông báo", data?.Message);
          return;
        }
        AppUtils.docExport(data, data.Name);
      }
    );
  };

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = useMemo(
    () => ({
      type: "checkbox",
      selectedRowKeys,
      onChange: onSelectChange,
    }),
    [selectedRowKeys]
  );

  const editRecord = (RecordID) => {
    ApiUtils.recordGetById(RecordID, (data) => {
      if (!data?.Success) {
        AppUtils.showMessage("Thông báo", data?.Message);
        return;
      }
      setSelectedRecord(data);
      setModalDetailVisible(true);
      setIsEditMode(true);
    });
  };

  const createNewRecord = () => {
    setSelectedRecord({ DepartmentID: selectedDepartment.DepartmentID });
    setModalDetailVisible(true);
    setIsEditMode(true);
  };

  const deleteRecord = (RecordID) => {
    AppUtils.recordDelete(RecordID);
  };

  return (
    <PageContainer>
      <SelectDepartmentWithAll
        handleSelectDepartment={handleSelectDepartment}
      />
      <SearchContent
        searchValues={searchValues}
        handleSearch={() => {
          setIsSearchVisible(true);
        }}
      ></SearchContent>
      <div className="alert alert-success" role="alert">
        Tìm được {total} hồ sơ...
      </div>
      <DataTable
        data={records}
        loading={loading}
        columns={columns}
        handleTableChange={handleTableChange}
        pagination={{ ...pagination, total }}
        rowSelection={
          paramStatus == RecordStatus.waitToRemove ? rowSelection : null
        }
      ></DataTable>
      <RecordSearchBox
        isModalVisible={isSearchVisible}
        department={selectedDepartment.DepartmentID}
        handleSearch={handleSearch}
        handleCancel={handleCancel}
        recordStatus={paramStatus}
      ></RecordSearchBox>
      <RecordDetail
        isEditMode={isEditMode}
        selectedRecord={selectedRecord}
        isModalDetailVisible={isModalDetailVisible}
        handleAfterSaved={handleAfterSaved}
        handleDetailCancel={handleDetailCancel}
        department={selectedDepartment.DepartmentID}
      ></RecordDetail>
      <Button
        className="psc-center-icon"
        onClick={createNewRecord}
        icon={<PlusOutlined />}
      >
        Tạo mới
      </Button>{" "}
      <Button
        className="psc-center-icon"
        onClick={exportExcel}
        icon={<FileExcelOutlined />}
      >
        Excel
      </Button>{" "}
      <Button
        className="psc-center-icon"
        onClick={exportDocx}
        icon={<FileWordOutlined />}
      >
        Word
      </Button>
      {paramStatus == RecordStatus.waitToRemove && records.length > 0 ? (
        <>
          <Button
            style={{ color: "#bb0a1e" }}
            onClick={() => detroySelectedRecords()}
            disabled={selectedRowKeys.length === 0}
          >
            Tiêu hồ sơ đã chọn
          </Button>{" "}
          <Button
            style={{ color: "#bb0a1e" }}
            loading={detroyAllRecordLoading}
            onClick={() => detroyAllRecords()}
          >
            Tiêu hủy toàn bộ hồ sơ
          </Button>
        </>
      ) : (
        ""
      )}
      <RecordDetroyReason
        detroyReason={detroyRecordReason}
        updateDetroyReason={updateDetroyReason}
        isModallVisible={modalDetroyRecordReason}
        handleCancel={detroyRecordCancel}
        handleSave={
          detroyRecordID
            ? detroyRecordID === "selected"
              ? detroySelectedRecordConfirm
              : detroyRecordConfirm
            : detroyAllRecordConfirm
        }
      />
    </PageContainer>
  );
}
export default Records;
