import React from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { getIdPath } from "./heplers";

function LeftMenuItem({ menu }) {
  const { pathname, search } = useLocation();

  return (
    <li
      key={menu.ID}
      className={
        getIdPath(menu.ID) === `${pathname}${search}` ? "active" : undefined
      }
    >
      {menu.ID === "line" ? (
        ""
      ) : (
        <Link
          to={getIdPath(menu.ID)}
          title={menu.Name}
          data-filter-tags={menu.Name}
        >
          <span class="nav-link-text">{menu.Name}</span>
        </Link>
      )}
    </li>
  );
}

export default LeftMenuItem;
