import { Spin, Tooltip } from "antd";
import numeral from "numeral";
import React, { useEffect } from "react";
import styled from "styled-components";
import { useReducerWithMiddleware } from "../../../hooks";
import { metricReducer, initialData, middleWare, actions } from "./reducer";
import DataTable from "../../../shared/components/DataTable/DataTable";

const Wrapper = styled.div``;

const MetricGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 8px 0;
`;

const Metric = styled.div`
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  min-width: 170px;

  &:not(:first-of-type) {
    border-left: 1px solid hsl(0, 0%, 90%);
  }
`;

const MetricTitle = styled.div`
  font-size: 1.3rem;
`;

const MetricValue = styled.div`
  font-size: 2rem;
  color: ${({ color }) => color};
`;

const MetricTotal = styled.div`
  font-size: 1rem;
`;

const AccountAnalytic = styled.div`
  display: flex;
  gap: 20px;
`;

const PanelCard = styled.div`
  flex: 1;
`;

const accTodayColumns = [
  {
    title: "Tên đăng nhập",
    dataIndex: "Username",
    key: "Username",
  },
  {
    title: "Họ Tên",
    dataIndex: "Fullname",
    key: "Fullname",
  },
  {
    title: "Đơn vị",
    dataIndex: "DepartmentName",
    key: "DepartmentName",
  },
  {
    title: "Thời Gian",
    dataIndex: "LoginTime",
    key: "LoginTime",
  },
];

const accRequestLoginColumns = [
  {
    title: "Số Biên Nhận",
    dataIndex: "ReceiptKey",
    key: "ReceiptKey",
  },
  {
    title: "Tiêu Đề",
    dataIndex: "Sumary",
    key: "Sumary",
  },
  {
    title: "Đơn vị",
    dataIndex: "DepartmentName",
    key: "DepartmentName",
  },
];

function SystemAdminDashboard() {
  const [dashboardData, dispatch] = useReducerWithMiddleware(
    metricReducer,
    initialData,
    [middleWare]
  );

  useEffect(() => {
    Object.keys(initialData.metrics).forEach((key) => {
      dispatch({ type: actions.updateValue, key });
      dispatch({ type: actions.updateTotal, key });
    });
    dispatch({ type: actions.loadAccLoginToday });
    dispatch({ type: actions.loadAccRequestUnlock });
  }, []);

  return (
    <Wrapper>
      <div id="panel-1" class="panel">
        <div class="panel-hdr">
          <h2>
            <b>Thống kê trong ngày</b>
          </h2>
        </div>
        <div class="panel-container show">
          <div class="panel-content">
            <MetricGroup>
              {Object.keys(dashboardData.metrics)
                .map((key) => dashboardData.metrics[key])
                .map(
                  ({ title, value, total, color, loading, loadingTotal }) => (
                    <Metric>
                      <MetricTitle>{title}</MetricTitle>
                      <MetricValue color={color}>
                        {loading ? (
                          <Spin size="large"></Spin>
                        ) : (
                          numeral(+value).format("0,0")
                        )}
                      </MetricValue>
                      <MetricTotal>
                        {loadingTotal ? (
                          <Spin size="small"></Spin>
                        ) : (
                          <Tooltip title={numeral(+total).format("0,0")}>
                            <b>Tổng:</b> {numeral(+total).format("0.0a")}
                          </Tooltip>
                        )}
                      </MetricTotal>
                    </Metric>
                  )
                )}
            </MetricGroup>
          </div>
        </div>
      </div>
      <AccountAnalytic>
        <PanelCard className="panel">
          <div class="panel-hdr">
            <h2>
              <b>Tài khoản yêu cầu trong ngày</b>
            </h2>
          </div>
          <div class="panel-container show">
            <div class="panel-content">
              <DataTable
                data={dashboardData.accLoginToday.data}
                loading={dashboardData.accLoginToday.loading}
                columns={accTodayColumns}
              ></DataTable>
            </div>
          </div>
        </PanelCard>
        <PanelCard className="panel">
          <div class="panel-hdr">
            <h2>
              <b>Yêu Cầu Mở Khóa</b>
            </h2>
          </div>
          <div class="panel-container show">
            <div class="panel-content">
              <DataTable
                data={dashboardData.accRequestUnlock.data}
                loading={dashboardData.accRequestUnlock.loading}
                columns={accRequestLoginColumns}
              ></DataTable>
            </div>
          </div>
        </PanelCard>
      </AccountAnalytic>
    </Wrapper>
  );
}
export default SystemAdminDashboard;
