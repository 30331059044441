import React from "react";
import { Button, Input, Modal, Switch } from "antd";

function DepartmentDetail({
  selectedDepartment,
  isModalDetailVisible,
  handleDetailCancel,
}) {
  return (
      <Modal
        title="Đơn vị"
        visible={isModalDetailVisible}
        onCancel={handleDetailCancel}
        maskClosable={true}
        footer={[
          <Button key="back" onClick={handleDetailCancel}>
            Thoát
          </Button>,
        ]}
      >
        <br />
        Mã{" "}
        <Input
          name="DepartmentKey"
          value={selectedDepartment.DepartmentKey}
        />
        <br />
        Tên{" "}
        <Input
          name="DepartmentName"
          value={selectedDepartment.DepartmentName}
        />
        Chi tiết{" "}
        <table border={2} cellPadding={5}>
           <thead>
              <tr>
                <td>Key</td>
                <td>Value</td>
              </tr>
           </thead>
           <tbody>
              {
                  selectedDepartment.DepartmentInfo && 
                  Object.keys(JSON.parse(selectedDepartment.DepartmentInfo)).map(function (element) {
                     return JSON.parse(selectedDepartment.DepartmentInfo)[element] ? (<tr>
                       <td>{element}</td>
                       <td>{[JSON.parse(selectedDepartment.DepartmentInfo)[element]]}</td>
                     </tr>) : "";
                  })
              }
           </tbody>
        </table>
      </Modal>
  );
}

export default DepartmentDetail;