import VI from './Language.vi'
import EN from './Language.en'

class LanguageUtils {
  static currentLanguage = null;

  static getCurrentLangCode() {
    if (this.currentLanguage == null) {
      this.currentLanguage = 'vi';
    }
    return this.currentLanguage;
  }
  
  static values() {
    let code = this.getCurrentLangCode();
    if (code === 'en') return EN.lang;
    return VI.lang;
  }
}
export default LanguageUtils;