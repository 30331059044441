import React from "react";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import AppUtils from "../../utils/AppUtils";

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => {
  if (isAuthProtected && AppUtils.getAuthUser()?.Success === false) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: rest.location } }} />
    );
  }

  return (
    <Layout>
      <Component {...rest} />
    </Layout>
  );
};

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
};

export default Authmiddleware;
