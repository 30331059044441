import React, { useState } from "react";
import { Button, Modal } from "antd";
import ApiUtils from "../../../utils/ApiUtils";
import AppUtils from "../../../utils/AppUtils";

function RecordUnlockRequestDetail({
  selectedRequest,
  isModalDetailVisible,
  handleDetailCancel,
  handleAfterSaved
}) {
  const [editingLoading, setEditingLoading] = useState(false);

  const confirmRequest = (status) => {
    setEditingLoading(true);
    ApiUtils.recordUnlockRequestConfirm(selectedRequest.RequestID, status, (data) => {
      AppUtils.showMessage("Thông báo", data?.showMessage);
      setEditingLoading(false);
      handleAfterSaved && handleAfterSaved();
    })
  }
  return (
    <Modal
      title="Yêu cầu mở khóa"
      visible={isModalDetailVisible}
      onCancel={handleDetailCancel}
      maskClosable={true}
      footer={[
        <Button
          disabled={!selectedRequest?.AllowEdit}
          onClick={() => confirmRequest(1)}
          loading={editingLoading}
        >
          Chấp nhận
        </Button>,
        <Button
          disabled={!selectedRequest?.AllowEdit}
          onClick={() => confirmRequest(2)}
          loading={editingLoading}
        >
          Từ chối
        </Button>,
        <Button key="back" onClick={handleDetailCancel}>
          Thoát
        </Button>,
      ]}
    >
      <table border={2} cellPadding={5} style={{ width: "100%" }}>
        <tbody>
          <tr>
            <td colSpan={2}>
              <span style={{ color: (selectedRequest.RequestStatus == 0 ? "blue" : (selectedRequest.RequestStatus == 1 ? "green" : "red")) }}>
                {selectedRequest.RequestStatusTitle}
              </span>
            </td>
          </tr>
          <tr>
            <td>Số biên nhận</td>
            <td>{selectedRequest.ReceiptKey}</td>
          </tr>
          <tr>
            <td>Trích yếu</td>
            <td>{selectedRequest.Sumary}</td>
          </tr>
          <tr>
            <td>Đơn vị</td>
            <td>{selectedRequest.DepartmentName}</td>
          </tr>
          <tr>
            <td>Ngày yêu cầu</td>
            <td>{selectedRequest?.CreatedInfo?.FullInfo}</td>
          </tr>
          <tr>
            <td>Ngày xử lý</td>
            <td>{selectedRequest?.UpdatedInfo?.FullInfo}</td>
          </tr>
        </tbody>
      </table>
    </Modal>
  );
}

export default RecordUnlockRequestDetail;