import { Input } from "antd";
import { Button } from "antd";
import React, { useEffect, useState } from "react";
import ApiUtils from "../../../utils/ApiUtils";
import AppUtils from "../../../utils/AppUtils";
import styled from "styled-components";
import { SaveOutlined } from "@ant-design/icons";

const Wrapper = styled.div``;

const Footer = styled.div`
  padding: 20px;
  display: flex;
  justify-content: end;
  gap: 8px;
`;

function SystemSettings() {
  const [OnegateApiKey, setOnegateApiKey] = useState("");
  const [OnegateAuthentication, setOnegateAuthentication] = useState("");
  const [OnegateListOfAdministrativeProcedures, setOnegateListOfAdministrativeProcedures] = useState("");
  const [OnegateListOfCustomers, setOnegateListOfCustomers] = useState("");
  const [OnegateListOfDepartments, setOnegateListOfDepartments] = useState("");
  const [OnegateListOfUsers, setOnegateListOfUsers] = useState("");
  const [OnegateListOfProvinces, setOnegateListOfProvinces] = useState("");
  const [OnegateListOfDistricts, setOnegateListOfDistricts] = useState("");
  const [OnegateListOfWards, setOnegateListOfWards] = useState("");
  const [OnegateCollectRecords, setOnegateCollectRecords] = useState("");
  const [OnegateRecordInfo, setOnegateRecordInfo] = useState("");
  const [OnegateRecordUnLock, setOnegateRecordUnLock] = useState("");
  const [OnegateUpdateDocumentStatus, setOnegateUpdateDocumentStatus] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    ApiUtils.systemSettingGet((responseData) => {
      if (!responseData) {
        AppUtils.showMessage("Thông báo", "Lỗi kết nối!");
        return;
      } else {
        if (!responseData?.Success) {
          AppUtils.showMessage("Thông báo", responseData.Message);
          return;
        } else {
          setOnegateApiKey(responseData?.OnegateApiKey);
          setOnegateAuthentication(responseData?.OnegateAuthentication);
          setOnegateListOfAdministrativeProcedures(responseData?.OnegateListOfAdministrativeProcedures);
          setOnegateListOfCustomers(responseData?.OnegateListOfCustomers);
          setOnegateListOfDepartments(responseData?.OnegateListOfDepartments);
          setOnegateListOfUsers(responseData?.OnegateListOfUsers);
          setOnegateListOfProvinces(responseData?.OnegateListOfProvinces);
          setOnegateListOfDistricts(responseData?.OnegateListOfDistricts);
          setOnegateListOfWards(responseData?.OnegateListOfWards);
          setOnegateCollectRecords(responseData?.OnegateCollectRecords);
          setOnegateRecordInfo(responseData?.OnegateRecordInfo);
          setOnegateRecordUnLock(responseData?.OnegateRecordUnLock);
          setOnegateUpdateDocumentStatus(responseData?.OnegateUpdateDocumentStatus);
        }
      }
    })
  }

  const updateSettings = () => {
    let data = {
      OnegateApiKey: OnegateApiKey,
      OnegateAuthentication: OnegateAuthentication,
      OnegateListOfAdministrativeProcedures: OnegateListOfAdministrativeProcedures,
      OnegateListOfCustomers: OnegateListOfCustomers,
      OnegateListOfDepartments: OnegateListOfDepartments,
      OnegateListOfUsers: OnegateListOfUsers,
      OnegateListOfProvinces: OnegateListOfProvinces,
      OnegateListOfDistricts: OnegateListOfDistricts,
      OnegateListOfWards: OnegateListOfWards,
      OnegateCollectRecords: OnegateCollectRecords,
      OnegateRecordInfo: OnegateRecordInfo,
      OnegateRecordUnLock: OnegateRecordUnLock,
      OnegateUpdateDocumentStatus: OnegateUpdateDocumentStatus
    }
    ApiUtils.systemSettingUpdate(data
      , (responseData) => {
        if (!responseData) {
          AppUtils.showMessage("Thông báo", "Lỗi kết nối!");
          return;
        } else {
          AppUtils.showMessage("Thông báo", responseData.Message);
          if (responseData.Success) {
            getData();
          }
        }
      })
  }

  return (
    <Wrapper>
      [MC] Api Key (OnegateApiKey)
      <br /><Input
        value={OnegateApiKey}
        onChange={(event) => {
          setOnegateApiKey(event.target.value);
        }} />
      <br /><br />[MC] Api  đăng nhập (OnegateAuthentication)
      <br /><Input
        value={OnegateAuthentication}
        onChange={(event) => {
          setOnegateAuthentication(event.target.value);
        }} />
      <br /><br />[MC] Api danh sách đơn vị (OnegateListOfDepartments)
      <br /><Input
        value={OnegateListOfDepartments}
        onChange={(event) => {
          setOnegateListOfDepartments(event.target.value);
        }} />
      <br /><br />[MC] Api danh sách người dùng (OnegateListOfUsers)
      <br /><Input
        value={OnegateListOfUsers}
        onChange={(event) => {
          setOnegateListOfUsers(event.target.value);
        }} />
      <br /><br />[MC] Api danh sách khách hàng (OnegateListOfCustomers)
      <br /><Input
        value={OnegateListOfCustomers}
        onChange={(event) => {
          setOnegateListOfCustomers(event.target.value);
        }} />
      <br /><br />[MC] Api  danh sách Tỉnh thành (OnegateListOfProvinces)
      <br /><Input
        value={OnegateListOfProvinces}
        onChange={(event) => {
          setOnegateListOfProvinces(event.target.value);
        }} />
      <br /><br />[MC] Api  danh sách Quận huyện (OnegateListOfDistricts)
      <br /><Input
        value={OnegateListOfDistricts}
        onChange={(event) => {
          setOnegateListOfDistricts(event.target.value);
        }} />
      <br /><br />[MC] Api  danh sách Phường xã (OnegateListOfWards)
      <br /><Input
        value={OnegateListOfWards}
        onChange={(event) => {
          setOnegateListOfWards(event.target.value);
        }} />
      <br /><br />[MC] Api thu thập hồ sơ (OnegateCollectRecords)
      <br /><Input
        value={OnegateCollectRecords}
        onChange={(event) => {
          setOnegateCollectRecords(event.target.value);
        }} />
      <br /><br />[MC] Api thông tin hồ sơ (OnegateRecordInfo)
      <br /><Input
        value={OnegateRecordInfo}
        onChange={(event) => {
          setOnegateRecordInfo(event.target.value);
        }} />
      <br /><br />[MC] Api danh sách thử tục hành chính (OnegateListOfAdministrativeProcedures)
      <br /><Input
        value={OnegateListOfAdministrativeProcedures}
        onChange={(event) => {
          setOnegateListOfAdministrativeProcedures(event.target.value);
        }} />
      <br /><br />[MC] Api cập nhật trạng thái hồ sơ sau khi mở khóa (OnegateRecordUnLock)
      <br /><Input
        value={OnegateRecordUnLock}
        onChange={(event) => {
          setOnegateRecordUnLock(event.target.value);
        }} />

      <br /><br />[MC] Cập nhật trạng thái văn bản (OnegateUpdateDocumentStatus)
      <br /><Input
        value={OnegateUpdateDocumentStatus}
        onChange={(event) => {
          setOnegateUpdateDocumentStatus(event.target.value);
        }} />
      <Footer>
        <Button
          icon={<SaveOutlined />}
          onClick={() => updateSettings()}
        >
          Cập nhật
        </Button>
      </Footer>
    </Wrapper>
  )
}
export default SystemSettings;