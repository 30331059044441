import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import DataTable from "../../../shared/components/DataTable/DataTable";
import PageContainer from "../../../shared/components/PageContainer/PageContainer";
import ApiUtils from "../../../utils/ApiUtils";
import AppUtils from "../../../utils/AppUtils";
import SelectDepartmentWithAll from "../Departments/SelectDepartmentWithAll";

const CustomerInfoWrapper = styled.div`
  cursor: ${({ selectable }) => (selectable ? "pointer" : "initial")};
`;

function DicOfReceivingAgency({ handleCustomerSelected }) {
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState({
    DepartmentID: AppUtils.departmentCurrent().DepartmentID,
  });
  const [agencies, setAgencies] = useState([]);

  const columns = [
    {
      title: "Tên",
      key: "Message",
      render: (_, record) => (
        <CustomerInfoWrapper
          onClick={() => {
            handleCustomerSelected && handleCustomerSelected(record);
          }}
          selectable={handleCustomerSelected}
        >
          {record.Message}
        </CustomerInfoWrapper>
      ),
    },
  ];

  useEffect(() => {
    searchData();
  }, [selectedDepartment]);

  const searchData = useCallback(() => {
    setLoading(true);
    //ApiUtils.receivingAgencyGet((responseData) => {
    ApiUtils.recordListReceivingAgency(
      selectedDepartment?.DepartmentID,
      (responseData) => {
        setLoading(false);
        if (!responseData.length) {
          setAgencies(responseData);
          return;
        }
        if (responseData[0].Message === AppUtils.TOKEN_EXPIRED_MESSAGE) {
          AppUtils.logout();
          return;
        }
        if (responseData.length === 1 && !responseData[0].Success) {
          AppUtils.showMessage("Thông báo", responseData[0].Message);
          return;
        }
        setAgencies(responseData);
      }
    );
  }, [selectedDepartment]);

  return (
    <PageContainer>
      <SelectDepartmentWithAll handleSelectDepartment={setSelectedDepartment} />
      <div className="row">
        <div className="col-md-12">
          <DataTable
            loading={loading}
            data={agencies}
            columns={columns}
            searchText={searchText}
            isSearchable={true}
          />
        </div>
      </div>
    </PageContainer>
  );
}
export default DicOfReceivingAgency;
