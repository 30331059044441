import React, { useEffect, useState } from "react";
import { Button, Input, Modal, Radio } from "antd";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import ApiUtils from "../../../utils/ApiUtils";
import AppUtils from "../../../utils/AppUtils";
import * as yup from "yup";
import styled from "styled-components";
import { isEmpty } from "lodash";

const DataFormatWrapper = styled.div`
  margin: 12px 0;
`;

const initialForm = {
  FieldKey: "",
  FieldTitle: "",
  DataFormat: "text",
  SortNumber: 0,
};

function DocumentTypeEditExternalField({
  selectedField,
  documentTypeId,
  isModalEditVisible,
  handleAfterSaved,
  handleEditCancel,
}) {
  const [editingLoading, setEditingLoading] = useState(false);

  const validationSchema = yup.object({
    FieldKey: yup.string().required(),
    FieldTitle: yup.string().required(),
  });

  const formik = useFormik({
    initialValues: initialForm,
    validationSchema,
    onSubmit: async (values) => {
      setEditingLoading(true);
      if (values.FieldID) {
        ApiUtils.documentTypeFieldUpdate(values, (data) => {
          AppUtils.showMessage("Thông báo", data?.Message);
          handleAfterSaved && handleAfterSaved();
          setEditingLoading(false);
        });
      } else {
        ApiUtils.documentTypeFieldInsert(documentTypeId, values, (data) => {
          AppUtils.showMessage("Thông báo", data?.Message);
          handleAfterSaved && handleAfterSaved();
          setEditingLoading(false);
        });
      }
    },
  });

  const deleteFieldHandle = () => {
    ApiUtils.documentTypeFieldDelete(selectedField.FieldID, (data) => {
      AppUtils.showMessage("Thông báo", data?.message);
      setEditingLoading(false);
      handleAfterSaved && handleAfterSaved();
    });
  };

  useEffect(() => {
    if (isEmpty(selectedField)) {
      return;
    }
    if (!selectedField.FieldID) {
      formik.resetForm();
      return;
    }
    formik.setValues(selectedField);
  }, [selectedField]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Modal
        title="Thông tin đặc tả"
        visible={isModalEditVisible}
        onOk={formik.submitForm}
        onCancel={handleEditCancel}
        maskClosable={false}
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={editingLoading}
            onClick={formik.submitForm}
          >
            Lưu
          </Button>,
          <Button
            danger
            disabled={!selectedField?.AllowEdit}
            onClick={deleteFieldHandle}
          >
            Xóa
          </Button>,
          <Button key="back" onClick={handleEditCancel}>
            Thoát
          </Button>,
        ]}
      >
        <br />
        Từ khóa{" "}
        <Input
          name="FieldKey"
          value={formik.values.FieldKey}
          onChange={formik.handleChange}
        />
        <br />
        Tên hiển thị{" "}
        <Input
          name="FieldTitle"
          value={formik.values.FieldTitle}
          onChange={formik.handleChange}
        />
        <DataFormatWrapper>
          Kiểu dữ liệu:{" "}
          <Radio.Group
            onChange={(e) => {
              formik.setFieldValue("DataFormat", e.target.value);
            }}
            value={formik.values.DataFormat}
          >
            <Radio value="text">Chữ</Radio>
            <Radio value="number">Số</Radio>
            <Radio value="date">Ngày</Radio>
          </Radio.Group>
        </DataFormatWrapper>
        Thứ tự{" "}
        <Input
          name="SortNumber"
          value={formik.values.SortNumber}
          onChange={formik.handleChange}
        />
      </Modal>
    </form>
  );
}

DocumentTypeEditExternalField.propTypes = {
  selectedField: PropTypes.object,
  documentTypeId: PropTypes.object,
  isModalEditVisible: PropTypes.bool,
  handleAfterSaved: PropTypes.func,
  handleEditCancel: PropTypes.func,
};

export default DocumentTypeEditExternalField;
