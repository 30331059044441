import React from "react";
import { Button, Modal } from "antd";
import DataTable from "../../../shared/components/DataTable/DataTable";
import AppUtils from "../../../utils/AppUtils";

function RecordCollectEditDocument({
  currentDocument,
  isModalVisible,
  handleCancel,
}) {
  const openFile = () => {
    if (currentDocument?.Path) {
      window.open("https://" + currentDocument?.Path);
    } else {
      AppUtils.showMessage("Thông báo", "Văn bản này không có file!");
    }
  };

  //ExternalData
  const exDataColumns = [
    {
      title: "Tiêu đề",
      dataIndex: "FieldTitle",
      key: "FieldTitle",
    },
    {
      title: "Giá trị",
      dataIndex: "FieldValue",
      key: "FieldValue",
    },
  ];

  return (
    <Modal
      title="Nội dung văn bản"
      visible={isModalVisible}
      onCancel={handleCancel}
      maskClosable={true}
      width="70%"
      footer={[
        <Button key="back" onClick={handleCancel}>
          Thoát
        </Button>,
      ]}
    >
      <table className="table table-bordered table-striped">
        <tbody>
          <tr>
            <td width="30%">Trạng thái</td>
            <td>
              {currentDocument?.TrangThaiTaiLieu == 1
                ? "Còn hiệu lực"
                : currentDocument?.TrangThaiTaiLieu == 2
                ? "Hết hiệu lực"
                : "Không rõ"}
            </td>
          </tr>
          <tr>
            <td>Nhóm giấy tờ</td>
            <td>{currentDocument?.NhomVanBan}</td>
          </tr>
          <tr>
            <td>Loại văn bản</td>
            <td>{currentDocument?.DocumentTypeName}</td>
          </tr>
          <tr>
            <td>Tên giấy tờ</td>
            <td>{currentDocument?.Ten}</td>
          </tr>
          <tr>
            <td>Trích yếu</td>
            <td>{currentDocument?.TrichYeu}</td>
          </tr>
          <tr>
            <td>Số ký hiệu</td>
            <td>{currentDocument?.SoKyHieu}</td>
          </tr>
          <tr>
            <td>Số trang</td>
            <td>{currentDocument?.TongSoTrangTaiLieu}</td>
          </tr>
          <tr>
            <td>Người sở hữu</td>
            <td>{currentDocument?.NguoiSoHuu}</td>
          </tr>
          <tr>
            <td>Mã định danh</td>
            <td>{currentDocument?.MaDinhDanh}</td>
          </tr>
          <tr>
            <td>Người ký duyệt</td>
            <td>{currentDocument?.NguoiKyDuyet}</td>
          </tr>
          <tr>
            <td>Chức vụ người ký</td>
            <td>{currentDocument?.ChucVuNguoiKy}</td>
          </tr>
          <tr>
            <td>Ngày hiệu lực</td>
            <td>{currentDocument?.NgayHieuLuc_Value}</td>
          </tr>
          <tr>
            <td>Thời hạn hiệu lực</td>
            <td>{currentDocument?.ThoiHanHieuLuc_Value}</td>
          </tr>
          <tr>
            <td>File đính kèm</td>
            <td>
              <a
                href="javascript:void(0);"
                style={{ color: "#033500" }}
                onClick={() => openFile()}
              >
                <img
                  src="/assets/SmartAdmin/img/govfiles/ic_document_available_16px.png"
                  alt=""
                />{" "}
                {currentDocument?.FileName}
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      {currentDocument?.DanhSachDacTa?.length > 0 ? (
        <>
          <h3 style={{ paddingTop: "10px" }}>Đặc tả</h3>
          <DataTable
            data={currentDocument.DanhSachDacTa}
            columns={exDataColumns}
            pagination={false}
          ></DataTable>
        </>
      ) : (
        ""
      )}
    </Modal>
  );
}

export default RecordCollectEditDocument;
