import React from "react";

function SelectDepartmentRow({ department, select_me }) {
  const selectDepartmentHandle = (DepartmentID) => {
    select_me(DepartmentID);
  }
  return (
    <tr>
      <td>
        <a href='javascript:void(0)'
          onClick={() => selectDepartmentHandle(department.DepartmentID)}
        >
          {department.DepartmentName}
        </a>
      </td>
    </tr>
  )
}
export default SelectDepartmentRow;