import { Table } from "antd";
import React, { useState, useEffect, useCallback } from "react";
import ApiUtils from "../../../utils/ApiUtils";
import AppUtils from "../../../utils/AppUtils";
import PageContainer from "../../../shared/components/PageContainer/PageContainer";
import RecordUnlockRequestDetail from "./RecordUnlockRequestDetail";
import SelectDepartmentWithAll from "../Departments/SelectDepartmentWithAll";


function RecordUnlockRequests() {
  const [unlockRequests, setUnlockRequests] = useState([{ RequestID: "" }]);
  const [selectedRequest, setSelectedRequest] = useState({ RequestID: "" });
  const [isModalDetailVisible, setModalDetailVisible] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const columns = [
    {
      title: "Hồ sơ",
      key: "ReceiptKey",
      render: (_, record) =>
        <a
          href="javascript:void(0);"
          onClick={() => openDetail(record.RequestID)}
          style={{ color: "#242526" }}
        >
          {record.ReceiptKey}<br />{record.Sumary}
        </a>
    },
    {
      title: "Tình trạng",
      key: "RequestStatusTitle",
      render: (_, record) =>
        <a
          href="javascript:void(0);"
          onClick={() => openDetail(record.RequestID)}
          style={{ color: "#242526" }}
        >
          {record.RequestStatusTitle}
        </a>
    },
    {
      title: "Đơn vị",
      dataIndex: "DepartmentName",
      key: "DepartmentName",
    },
  ];

  const getData = useCallback(() => {
    ApiUtils.recordUnlockRequestGet(selectedDepartment?.DepartmentID, "", "", (data) => {
      setUnlockRequests(data);
    });
  }, [selectedDepartment]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleDetailCancel = () => {
    setModalDetailVisible(false);
  };

  const openDetail = (id) => {
    ApiUtils.recordUnlockRequestGetById(id, (data) => {
      if (!data) {
        return;
      }
      if (!data?.Success) {
        AppUtils.showMessage("Thông báo", data?.Message);
        return;
      }
      setSelectedRequest(data);
      setModalDetailVisible(true);
    });
  };

  const handleAfterSaved = () => {
    setModalDetailVisible(false);
    getData();
  };

  const handleSelectDepartment = (department) => {
    setSelectedDepartment(department);
  };

  return (
    <PageContainer>
      <SelectDepartmentWithAll handleSelectDepartment={handleSelectDepartment} />
      <div className="row">
        <div className="col-md-12">
          <Table dataSource={unlockRequests} columns={columns} />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <RecordUnlockRequestDetail
            isModalDetailVisible={isModalDetailVisible}
            selectedRequest={selectedRequest}
            handleAfterSaved={handleAfterSaved}
            handleDetailCancel={handleDetailCancel}
          ></RecordUnlockRequestDetail>
        </div>
      </div>
    </PageContainer>
  );
}
export default RecordUnlockRequests;