import React from "react";
import styled from "styled-components";
import { Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const ContentArea = styled.div`
  width: 100%;
  min-height: 100px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100px;
  overflow-y: auto;
`;

const RecordSearchDic = {
  StartDate: "Từ Ngày",
  EndDate: "Đến Ngày",
  ReceiptKey: "Số biên nhận",
  AdministrativeFileNumber: "Mã HS DVCQG",
  CustomerKey: "Mã định danh",
  CustomerName: "Tên Khách hàng",
  FieldName: "Lĩnh vực",
  AdministrativeProcedureName: "Thủ tục hành chính",
  ReceivingAgency: "Cơ quan tiếp nhận",
  Sumary: "Trích yếu",
  RecordStatus: "Tình Trạng",
};

function SearchContent({ searchValues = {}, handleSearch }) {
  return (
    <div className="form-group row">
      <h5 class="col-2 col-form-label">Nội dung tìm kiếm</h5>
      <div class="col-8">
        <ContentArea className="psc-disabled">
          {Object.keys(searchValues).map((key) =>
            searchValues[key] ? (
              <div>
                - {RecordSearchDic[key] || key}: {searchValues[key]}
              </div>
            ) : (
              ""
            )
          )}
        </ContentArea>
      </div>
      <div class="col-2">
        <Button
          className="psc-center-icon"
          onClick={() => handleSearch()}
          icon={<SearchOutlined />}
        >
          Tìm kiếm
        </Button>
      </div>
    </div>
  );
}

export default SearchContent;
