import React, { useEffect, useState } from "react";
import { Button, DatePicker, Dropdown, Input, Modal, Select } from "antd";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import ApiUtils from "../../../utils/ApiUtils";
import AppUtils from "../../../utils/AppUtils";
import * as yup from "yup";
import styled from "styled-components";
import moment from "moment";

function RecordEditStorage({
  editingRecord,
  isModalVisible,
  handleAfterSaved,
  handleCancel,
}) {
  const [editingLoading, setEditingLoading] = useState(false);
  const [editingDate, setEditingDate] = useState("");

  const validationSchema = yup.object({});

  const formik = useFormik({
    initialValues: {
      StorageLifeID: "",
      StorageDate: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setEditingLoading(true);
      const newValues = { ...values };
      delete newValues.StorageLifes;
      ApiUtils.recordUpdateStorageDate(newValues, (data) => {
        AppUtils.showMessage("Thông báo", data?.Message);
        setEditingLoading(false);
      });
      handleAfterSaved && handleAfterSaved();
    },
  });

  useEffect(() => {
    if (!editingRecord) {
      return;
    }
    formik.setValues(editingRecord);
  }, [editingRecord]);

  useEffect(() => {
    setStorageLifeList(editingRecord.StorageLifes);
  }, [editingRecord]);

  const CustomSelect = styled(Select)`
    width: 100%;
  `;
  const [storageLifeList, setStorageLifeList] = useState([]);
  const handleSelectStorageLife = (StorageLifeID) => {
    formik.setFieldValue("StorageLifeID", StorageLifeID);
    let stg = editingRecord.StorageLifes.find(
      (item) => item.StorageLifeID === StorageLifeID
    );
    formik.setFieldValue("StorageDate", stg.RecordDefaultValue);
    setEditingDate(stg.RecordDefaultValue);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Modal
        title="Thời hạn bảo quản hồ sơ"
        visible={isModalVisible}
        onOk={formik.submitForm}
        onCancel={handleCancel}
        maskClosable={false}
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={editingLoading}
            onClick={formik.submitForm}
          >
            Cập nhật thời hạn bảo quản
          </Button>,
          <Button key="back" onClick={handleCancel}>
            Thoát
          </Button>,
        ]}
      >
        <div class="form-group mb-3">
          <h5>Ngày tạo hồ sơ </h5>
          <Input
            name="CreatedDate"
            value={formik.values.CreatedDate}
            onChange={formik.handleChange}
            readOnly={true}
            disabled
          />
        </div>
        <div class="form-group mb-3">
          <h5>Hạn bảo quản hiện tại </h5>
          <Input
            name="StorageDate"
            value={formik.values.CurrentStorageDate}
            onChange={formik.handleChange}
            readOnly={true}
            disabled
          />
        </div>
        <div class="form-group mb-3">
          <h5>Chọn thời hạn bảo quản </h5>
          <CustomSelect
            name="StorageLifeID"
            placeholder="Chọn thời hạn bảo quản"
            defaultValue={
              editingRecord.StorageLifeID ? editingRecord.StorageLifeID : null
            }
            value={formik.values.StorageLifeID}
            options={storageLifeList}
            showSearch
            allowClear
            fieldNames={{
              label: "StorageLifeName",
              value: "StorageLifeID",
              options: "options",
            }}
            onChange={(value) => {
              handleSelectStorageLife(value);
            }}
          />
        </div>
        <div class="form-group">
          <h5>Bảo quản đến </h5>
          <DatePicker
            disabled={editingDate}
            defaultValue={moment()}
            format={AppUtils.DATE_FORMAT}
            onChange={(_, dateString) => {
              formik.setFieldValue("StorageDate", dateString);
            }}
            allowClear={true}
          />
        </div>
      </Modal>
    </form>
  );
}

RecordEditStorage.propTypes = {
  editingRecord: PropTypes.object,
  isModalVisible: PropTypes.bool,
  handleAfterSaved: PropTypes.func,
  handleCancel: PropTypes.func,
};

export default RecordEditStorage;
