import { Button, Space } from "antd";
import React, { useState, useEffect, useCallback } from "react";
import { EditOutlined } from "@ant-design/icons";
import ApiUtils from "../../../utils/ApiUtils";
import AppUtils from "../../../utils/AppUtils";
import SelectDepartment from "../Departments/SelectDepartment";
import PageContainer from "../../../shared/components/PageContainer/PageContainer";
import DisOfStorageForm from "./DisOfStorageForm";
import DataTable from "../../../shared/components/DataTable/DataTable";
import SelectDepartmentWithAll from "../Departments/SelectDepartmentWithAll";

function DicOfStorageLife() {
  const [storagelifes, setStorageLifes] = useState([{ StorageLifeID: "" }]);
  const [editingSL, setEditingSL] = useState({ StorageLifeID: "" });
  const [isModalEditVisible, setModalEditVisible] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const columns = [
    {
      title: "Mã",
      dataIndex: "StorageLifeKey",
      key: "StorageLifeKey",
    },
    {
      title: "Tên",
      dataIndex: "StorageLifeName",
      key: "StorageLifeName",
    },
    {
      title: "Cảnh báo (ngày)",
      dataIndex: "WarningDays",
      key: "WarningDays",
    },
    {
      title: "Đơn vị",
      dataIndex: "DepartmentName",
      key: "DepartmentName",
    },
    {
      title: "",
      key: "action",
      render: (_, record) =>
        record.AllowEdit ? (
          <Space size="middle">
            <Button
              icon={<EditOutlined />}
              onClick={() => editStorageLifeHandle(record.StorageLifeID)}>
              Chi tiết
            </Button>
          </Space>
        ) : (
          ""
        ),
    },
  ];

  const getStorageLifes = useCallback(() => {
    if (!selectedDepartment) {
      setSelectedDepartment({ DepartmentID: AppUtils.ROOT_ID });
    }
    setLoading(true);
    ApiUtils.storageLifeGetByDepartment(
      selectedDepartment?.DepartmentID,
      (responseData) => {
        setLoading(false);
        if (!responseData) {
          AppUtils.showMessage("Thông báo", "Lỗi dữ liệu");
          return;
        }
        if (responseData.length == 1 && !responseData[0].Success) {
          AppUtils.showMessage("Thông báo", responseData[0].Message);
          return;
        }
        setStorageLifes(responseData);
      }
    );
  }, [selectedDepartment]);

  useEffect(() => {
    getStorageLifes();
  }, [getStorageLifes]);

  const handleEditCancel = () => {
    setModalEditVisible(false);
  };

  const editStorageLifeHandle = (id) => {
    ApiUtils.storageLifeGetById(id, (data) => {
      if (!data?.Success) {
        AppUtils.showMessage("Thông báo", data.Message);
        return;
      }
      setEditingSL(data);
      setModalEditVisible(true);
    });
  };

  const handleAfterSaved = () => {
    setModalEditVisible(false);
    getStorageLifes();
  };

  const handleSelectDepartment = (department) => {
    setSelectedDepartment(department);
  };

  const handleNewStorageLife = () => {
    setEditingSL({ StorageLifeID: "" });
    setModalEditVisible(true);
  };

  return (
    <PageContainer>
      <SelectDepartmentWithAll
        handleSelectDepartment={handleSelectDepartment}
      ></SelectDepartmentWithAll>
      <div className="row">
        <div className="col-md-12">
          <DataTable
            data={storagelifes}
            loading={loading}
            columns={columns}
            searchText={searchText}
            isSearchable
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <DisOfStorageForm
            isModalEditVisible={isModalEditVisible}
            editingSL={editingSL}
            handleAfterSaved={handleAfterSaved}
            handleEditCancel={handleEditCancel}
          ></DisOfStorageForm>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <Button onClick={() => handleNewStorageLife()}>Tạo mới</Button>
        </div>
      </div>
    </PageContainer>
  );
}

export default DicOfStorageLife;
