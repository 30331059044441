import { Spin } from "antd";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  height: ${({ height }) => height};
  display: grid;
  place-content: center;
`;

function LoadingSpin({ size = "large", height = "150px" }) {
  return (
    <Wrapper height={height}>
      <Spin size={size} />
    </Wrapper>
  );
}

export default LoadingSpin;
