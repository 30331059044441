import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Button, Space } from 'antd';
import ApiUtils from '../../../utils/ApiUtils';
import AppUtils from '../../../utils/AppUtils';
import DataTable from '../../../shared/components/DataTable/DataTable';
import RecordFreezeNew from '../RecordFreezes/RecordFreezeNew';
import RecordEditStorage from './RecordEditStorage';
import DocumentDetail from '../Documents/DocumentDetail';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import LoadingSpin from '../../../shared/components/LoadingSpin';
import { FileWordOutlined, HistoryOutlined } from '@ant-design/icons';
import LogByReference from '../Log/LogByReference';

const Wrapper = styled.div``;

const Footer = styled.div`
  padding: 20px;
  display: flex;
  justify-content: end;
  gap: 8px;
`;

function RecordDetail() {
  const navigate = useNavigate();
  const [editingLoading, setEditingLoading] = useState(false);
  const [currentRecord, setCurrentRecord] = useState();
  const { id } = useParams();

  const getRecordDetail = useCallback(() => {
    ApiUtils.recordGetByReceiptKey(id, (data) => {
      if (!data?.Success) {
        AppUtils.showMessage('Thông báo', data?.Message);
        return;
      }
      setCurrentRecord(data);
    });
  }, [id]);

  useEffect(() => {
    getRecordDetail();
  }, [getRecordDetail]);

  const openDocument = useCallback(
    (DocumentID) => {
      ApiUtils.documentGetByIdAndRecord(
        DocumentID,
        currentRecord.RecordID,
        (data) => {
          setSelectedDocument(data);
          setModalDocumentVisible(true);
        }
      );
    },
    [currentRecord?.RecordID]
  );

  //Tải văn bản
  const openFile = useCallback(
    (DocumentID) => {
      ApiUtils.documentGetFileLinkByDoc(DocumentID, (responseData) => {
        if (!responseData) {
          AppUtils.showMessage('Thông báo', 'Lỗi kết nối!');
          return;
        }
        if (!responseData.Success) {
          AppUtils.showMessage('Thông báo', responseData.Message);
          return;
        }
        let link = 'https://' + responseData?.DownloadUrl;
        window.open(link);
      });
    },
    [currentRecord?.RecordID]
  );
  const handleDownloadAllDocuments = () => {
    if (currentRecord?.Documents?.length > 0) {
      currentRecord.Documents.map((doc, i) => {
        ApiUtils.documentGetFileLinkByDoc(doc.DocumentID, (responseData) => {
          if (!responseData) {
            AppUtils.showMessage('Thông báo', 'Lỗi kết nối!');
            return;
          }
          if (!responseData.Success) {
            AppUtils.showMessage('Thông báo', responseData.Message);
            return;
          }
          let link = 'https://' + responseData?.DownloadUrl;
          AppUtils.downloadFileFromLink(link, responseData.FileName);
        });
      });
    }
  };

  const docCols = useMemo(() => {
    return [
      {
        title: 'Số ký hiệu',
        key: 'SignatureNumber',
        render: (_, record) => (
          <div>
            <a
              href="javascript:void(0);"
              onClick={() => openDocument(record.DocumentID)}
              style={{ color: '#242526' }}
            >
              {record.SignatureNumber}
            </a>
          </div>
        ),
      },
      {
        title: 'Nội dung',
        key: 'Title',
        render: (_, record) => (
          <div>
            <a
              href="javascript:void(0);"
              onClick={() => openDocument(record.DocumentID)}
              style={{ color: '#242526' }}
            >
              {record.DocumentGroupName}
              {record?.DocumentTypeName ? `. - ${record.DocumentTypeName}` : ''}
              <br />
              {record.Title}
            </a>
          </div>
        ),
      },
      {
        title: ' ',
        key: 'Others',
        render: (_, record) => (
          <div>
            <a
              href="javascript:void(0);"
              onClick={() => openDocument(record.DocumentID)}
              style={{ color: '#242526' }}
            >
              - Sở hữu: {record.Owner}
              <br />- Ngày hiệu lực: {record.ValidDate}
            </a>
          </div>
        ),
      },
      {
        title: '',
        key: 'action',
        render: (_, record) => (
          <Space size="middle">
            <Button onClick={() => openFile(record.DocumentID)}>
              Xem File
            </Button>
          </Space>
        ),
      },
    ];
  }, [openDocument, openFile]);

  const [isModalDocumentVisible, setModalDocumentVisible] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState({ DocumentID: '' });

  const handleDocumentUpdated = () => {
    setModalDocumentVisible(false);
    reloadRecord();
  };
  const handleDocumentCancel = () => {
    setModalDocumentVisible(false);
  };

  const reloadRecord = () => {
    ApiUtils.recordGetByReceiptKey(id, (data) => {
      setCurrentRecord(data);
    });
  };

  //Khóa hồ sơ
  const handleLockRecord = () => {
    ApiUtils.recordLock(currentRecord.RecordID, (data) => {
      AppUtils.showMessage('Thông báo', data?.Message);
      reloadRecord();
    });
  };

  //Đóng băng hồ sơ
  const [isModalFreezeVisible, setModalFreezeVisible] = useState(false);
  const [recordFreeze, setRecordFreeze] = useState({ RecordID: '' });
  const handleFreezeRecord = () => {
    setRecordFreeze({
      RecordID: currentRecord.RecordID,
      ReceiptKey: currentRecord.ReceiptKey,
      Sumary: currentRecord.Sumary,
      FreezeContent: '',
    });
    setModalFreezeVisible(true);
  };
  const handleAfterFreezed = () => {
    setModalFreezeVisible(false);
    reloadRecord();
  };
  const handleAfterFreezeCancel = () => {
    setModalFreezeVisible(false);
  };

  //Thiết lập thời hạn bảo quản
  const [isModalStorageVisible, setModalStorageVisible] = useState(false);
  const [recordStorage, setRecordStorage] = useState({ RecordID: '' });
  const handleStorageLifeEdit = () => {
    ApiUtils.recordGetForEditStorage(currentRecord.RecordID, (data) => {
      setRecordStorage(data);
      setModalStorageVisible(true);
    });
  };
  const handleAfterStoraged = () => {
    setModalStorageVisible(false);
    reloadRecord();
  };
  const handleAfterStorageCancel = () => {
    setModalStorageVisible(false);
  };

  //Log
  const [isModalLogVisible, setIsModalLogVisible] = useState(false);
  const [referenceId, setReferenceId] = useState("");
  const checkLogs = (id) => {
    setReferenceId(id);
    setIsModalLogVisible(true);
  };
  const checkLogCancel = () => {
    setIsModalLogVisible(false);
  };

  //Export
  const exportDoc = () => {
    if (currentRecord && currentRecord.Success) {
      AppUtils.generateRecordDetailDoc(
        currentRecord,
        `HO_SO_${currentRecord.ReceiptKey}`
      );
    }
  };

  return (
    <Wrapper>
      {currentRecord ? (
        <>
          <table
            className="table table-bordered table-striped"
            border={2}
            cellPadding={5}
            style={{ width: '100%' }}
          >
            <tbody>
              <tr>
                <td>
                  <h5>Trạng thái</h5>
                </td>
                <td>
                  <span style={{ color: currentRecord?.StatusColor }}>
                    {currentRecord?.StatusDisplay}
                  </span>
                </td>
              </tr>
              <tr>
                <td width="30%">
                  <h5>Bảo quản</h5>
                </td>
                <td>
                  <a
                    href="javascript:void(0);"
                    style={{ color: '#033500' }}
                    onClick={() => handleStorageLifeEdit()}
                  >
                    <img
                      src="assets/SmartAdmin/img/govfiles/ic_calendar_16px.png"
                      alt=""
                    />{' '}
                    {currentRecord?.StorageLifeName}{' '}
                    {currentRecord?.StorageDate
                      ? ` - Dự kiến đến ${currentRecord?.StorageDate}`
                      : ''}
                  </a>
                </td>
              </tr>

              <tr>
                <td>
                  <h5>Số biên nhận</h5>
                </td>
                <td>{currentRecord?.ReceiptKey}</td>
              </tr>
              <tr>
                <td>
                  <h5>Mã HS DVCQG</h5>
                </td>
                <td>{currentRecord?.AdministrativeFileNumber}</td>
              </tr>
              <tr>
                <td>
                  <h5>Đơn vị</h5>
                </td>
                <td>{currentRecord?.DepartmentName}</td>
              </tr>
              <tr>
                <td>
                  <h5>Mã định danh</h5>
                </td>
                <td>{currentRecord?.CustomerKey}</td>
              </tr>
              <tr>
                <td>
                  <h5>Họ tên khách hàng</h5>
                </td>
                <td>{currentRecord?.CustomerName}</td>
              </tr>
              <tr>
                <td>
                  <h5>Cơ quan tiếp nhận</h5>
                </td>
                <td>{currentRecord?.ReceivingAgency}</td>
              </tr>
              <tr>
                <td>
                  <h5>Tên thủ tục hành chính</h5>
                </td>
                <td>{currentRecord?.AdministrativeProcedureName}</td>
              </tr>
              <tr>
                <td>
                  <h5>Trích yếu hồ sơ</h5>
                </td>
                <td>{currentRecord?.Sumary}</td>
              </tr>
              <tr>
                <td>
                  <h5>Tên lĩnh vực</h5>
                </td>
                <td>{currentRecord?.FieldName}</td>
              </tr>
              <tr>
                <td>
                  <h5>Ghi chú</h5>
                </td>
                <td>{currentRecord?.Note}</td>
              </tr>
            </tbody>
          </table>
          <h5 className="py-2">Danh sách văn bản</h5>
          <DataTable
            data={currentRecord?.Documents}
            columns={docCols}
            pagination={false}
          ></DataTable>
          <RecordFreezeNew
            isModalVisible={isModalFreezeVisible}
            editingRecord={recordFreeze}
            handleAfterSaved={handleAfterFreezed}
            handleCancel={handleAfterFreezeCancel}
          />
          <RecordEditStorage
            isModalVisible={isModalStorageVisible}
            editingRecord={recordStorage}
            handleAfterSaved={handleAfterStoraged}
            handleCancel={handleAfterStorageCancel}
          />
          <DocumentDetail
            isModalVisible={isModalDocumentVisible}
            selectedDocument={selectedDocument}
            handleAfterSaved={handleDocumentUpdated}
            handleCancel={handleDocumentCancel}
          />
          <LogByReference
            referenceId={referenceId}
            referenceType={"record"}
            isModallVisible={isModalLogVisible}
            handleCancel={checkLogCancel}
          />
          <Footer>
            <Button
              disabled={
                currentRecord?.IsLocked ||
                currentRecord?.IsFreeze ||
                !AppUtils.userIsAdmin()
              }
              onClick={() => handleLockRecord()}
              style={{ color: '#bb0a1e' }}
            >
              Khóa
            </Button>
            <Button
              disabled={
                currentRecord?.IsFreeze || !AppUtils.userIsSystemAdmin()
              }
              onClick={() => handleFreezeRecord()}
              style={{ color: '#5f8dce' }}
            >Đóng băng
            </Button>
            <Button
              disabled={!AppUtils.userIsAdmin()}
              onClick={() => handleDownloadAllDocuments()}
            >Tải văn bản
            </Button>
            <Button
              disabled={!AppUtils.userIsSystemAdmin()}
              icon={<HistoryOutlined />}
              onClick={() => checkLogs(currentRecord?.RecordID)}
            >Logs
            </Button>
            <Button
              className="psc-center-icon"
              onClick={exportDoc}
              icon={<FileWordOutlined />}
            >
              Word
            </Button>
          </Footer>
        </>
      ) : (
        <LoadingSpin />
      )}
    </Wrapper>
  );
}

export default RecordDetail;
