import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button, Input, Modal, Select, Radio, DatePicker } from "antd";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import styled from "styled-components";
import ApiUtils from "../../../utils/ApiUtils";
import AppUtils from "../../../utils/AppUtils";
import moment from "moment";
import { DocumentStatus, DocumentStatusInfo } from "./Documents";

const CustomSelect = styled(Select)`
  width: 100%;
`;

const DefaultDate = moment(new Date()).format(AppUtils.DATE_FORMAT);

const DateStatus = {
  today: "today",
  startEnd: "startEnd",
  all: "all",
};

const CustomRaidoGroup = styled(Radio.Group)`
  display: flex;
  border: 1px solid ${AppUtils.CONTROL_BORDER_COLOR};
  padding: 4px;
  gap: 4px;
`;

const CustomDocumentGroupRaidoGroup = styled(Radio.Group)`
  display: flex;
  border: 1px solid ${AppUtils.CONTROL_BORDER_COLOR};
  gap: 4px;
  padding: 4px;
  flex-wrap: wrap;
`;

const AllDateRadio = styled(Radio)`
  margin-top: 5px;
`;

function DocumentSearchBox({
  department,
  docStatus,
  isModalVisible,
  handleSearch,
  handleCancel,
}) {
  const currentDepartment = useRef(department);
  const [documentGroupList, setDocumentGroupList] = useState([]);
  const [typeNameList, setTypeNameList] = useState([]);
  const [typeLoading, setTypeLoading] = useState(false);
  const [groupLoading, setGroupLoading] = useState(false);
  const [dateStatus, setDateStatus] = useState(DateStatus.today);

  const formik = useFormik({
    initialValues: {
      StartDate: DefaultDate,
      EndDate: DefaultDate,
      SignatureNumber: "",
      OwnerKey: "",
      Title: "",
      Sumary: "",
      DocumentTypeID: "",
      DocumentStatus: docStatus,
      DocumentGroup: "",
    },
    onSubmit: async (values) => {
      const resultValue = { ...values };
      if (dateStatus === DateStatus.all) {
        resultValue.StartDate = "";
        resultValue.EndDate = "";
      }
      handleSearch && handleSearch(resultValue);
    },
  });

  const onDateStatusChange = (e) => {
    setDateStatus(e.target.value);
  };

  const onTypeNameOpen = useCallback(
    (open) => {
      if (
        !open ||
        (typeNameList.length && department === currentDepartment.current)
      ) {
        return;
      }
      setTypeLoading(true);
      setTypeNameList([]);
      ApiUtils.documentTypeGet(department, (data) => {
        console.log(data);
        setTypeNameList(data);
        currentDepartment.current = department;
        setTypeLoading(false);
      });
    },
    [typeNameList, department]
  );

  const DocumentStatusControls = useMemo(() => {
    if (docStatus === 0) {
      return Object.values(DocumentStatus).map((value) => {
        const { label, style } = DocumentStatusInfo[value];
        return { value, label, style };
      });
    }
    const { label, style } = DocumentStatusInfo[docStatus];
    return [
      {
        value: docStatus,
        label,
        style,
      },
    ];
  }, [docStatus]);

  useEffect(() => {
    if (documentGroupList.length && department === currentDepartment.current) {
      return;
    }
    setGroupLoading(true);
    setDocumentGroupList([]);
    ApiUtils.documentGroupGet((data) => {
      setDocumentGroupList(data);
      currentDepartment.current = department;
      setGroupLoading(false);
    });
  }, [department]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Modal
        title="Tìm kiếm văn bản"
        visible={isModalVisible}
        onOk={formik.submitForm}
        onCancel={handleCancel}
        maskClosable={false}
        width={1000}
        footer={[
          <Button key="submit" type="primary" onClick={formik.submitForm}>
            Tìm kiếm
          </Button>,
          <Button key="back" onClick={handleCancel}>
            Thoát
          </Button>,
        ]}
      >
        <br />
        Ngày nhập{" "}
        <CustomRaidoGroup onChange={onDateStatusChange} value={dateStatus}>
          <Radio value={DateStatus.today}>
            Trong ngày:
            <DatePicker
              disabled={dateStatus !== DateStatus.today}
              defaultValue={moment()}
              format={AppUtils.DATE_FORMAT}
              onChange={(_, dateString) => {
                formik.setFieldValue("StartDate", dateString);
                formik.setFieldValue("EndDate", dateString);
              }}
            />
          </Radio>
          <Radio value={DateStatus.startEnd}>
            Từ ngày:
            <DatePicker
              disabled={dateStatus !== DateStatus.startEnd}
              defaultValue={moment()}
              format={AppUtils.DATE_FORMAT}
              onChange={(_, dateString) => {
                formik.setFieldValue("StartDate", dateString);
              }}
            />{" "}
            Đến ngày:
            <DatePicker
              disabled={dateStatus !== DateStatus.startEnd}
              defaultValue={moment()}
              format={AppUtils.DATE_FORMAT}
              onChange={(_, dateString) => {
                formik.setFieldValue("EndDate", dateString);
              }}
            />
          </Radio>
          <AllDateRadio value={DateStatus.all}>Tất cả</AllDateRadio>
        </CustomRaidoGroup>
        <br />
        Số ký hiệu{" "}
        <Input
          name="SignatureNumber"
          value={formik.values.SignatureNumber}
          onChange={formik.handleChange}
        />
        <br />
        Mã định danh{" "}
        <Input
          name="OwnerKey"
          value={formik.values.OwnerKey}
          onChange={formik.handleChange}
        />
        <br />
        Tên{" "}
        <Input
          name="Title"
          value={formik.values.Title}
          onChange={formik.handleChange}
        />
        <br />
        Trích yếu{" "}
        <Input
          name="Sumary"
          value={formik.values.Sumary}
          onChange={formik.handleChange}
        />
        <br />
        Loại văn bản{" "}
        <CustomSelect
          name="DocumentTypeID"
          placeholder="Please select"
          defaultValue={null}
          loading={typeLoading}
          value={formik.values.DocumentTypeID}
          options={typeNameList}
          showSearch
          allowClear
          fieldNames={{
            label: "DocumentTypeName",
            value: "DocumentTypeID",
            options: "options",
          }}
          onChange={(value) => {
            formik.setFieldValue("DocumentTypeID", value);
          }}
          onDropdownVisibleChange={onTypeNameOpen}
        />
        <br />
        Nhóm giấy tờ
        <CustomDocumentGroupRaidoGroup
          onChange={(e) => {
            formik.setFieldValue("DocumentGroup", e.target.value);
          }}
          value={formik.values.DocumentGroup}
        >
          <Radio value={""}>Tất cả</Radio>
          {documentGroupList.map(({ DocumentGroupID, DocumentGroupName }) => (
            <Radio className="text-success" value={DocumentGroupID}>
              {DocumentGroupName}
            </Radio>
          ))}
        </CustomDocumentGroupRaidoGroup>
        <br />
        Tình Trạng{" "}
        <CustomRaidoGroup
          onChange={(e) => {
            formik.setFieldValue("DocumentStatus", e.target.value);
          }}
          value={formik.values.DocumentStatus}
        >
          {DocumentStatusControls.map(({ label, value, style }) => (
            <Radio className={style} value={value}>
              {label}
            </Radio>
          ))}
        </CustomRaidoGroup>
      </Modal>
    </form>
  );
}

DocumentSearchBox.propTypes = {
  isModalVisible: PropTypes.bool,
  handleSearch: PropTypes.func,
  handleCancel: PropTypes.func,
};

export default DocumentSearchBox;
