import React, { useEffect, useState } from "react";
import LeftMenuItem from "./LeftMenuItem";
import { useLocation } from "react-router-dom";
import { getIdPath } from "./heplers";

function LeftMenuGroup({ menu }) {
  const { pathname, search } = useLocation();
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (
      menu.ListMenus.find(
        (item) => getIdPath(item.ID) === `${pathname}${search}`
      )
    ) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [menu, pathname, search]);

  return (
    <li className={isActive ? "active open" : ""}>
      <a
        href="javascript:void(0);"
        title={menu.Name}
        data-filter-tags={menu.Name}
      >
        <i class={menu.Icon ?? "fal fa-info-circle"}></i>
        <span class="nav-link-text">{menu.Name}</span>
      </a>
      <ul>
        {menu.ListMenus.map((item) => {
          return <LeftMenuItem menu={item} />;
        })}
      </ul>
    </li>
  );
}

export default LeftMenuGroup;
