import React from "react";
import { Button, Modal } from "antd";
import styled from "styled-components";

const JsonDisplayer = ({ json }) => {
  return <pre>{json ? JSON.stringify(JSON.parse(json), null, 4) : ""}</pre>;
};

function LogDetail({ selectedLog, isModalDetailVisible, handleDetailCancel }) {
  return (
    <Modal
      title="Chi tiết LOG"
      visible={isModalDetailVisible}
      onCancel={handleDetailCancel}
      maskClosable={true}
      width={1300}
      footer={[
        <Button key="back" onClick={handleDetailCancel}>
          Thoát
        </Button>,
      ]}
    >
      <table border={2} cellPadding={5} style={{ width: "100%" }}>
        <thead>
          <tr>
            <td>Key</td>
            <td>Value</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Thao tác</td>
            <td>{selectedLog.TitleName}</td>
          </tr>
          <tr>
            <td>Tài khoản</td>
            <td>{selectedLog.Username}</td>
          </tr>
          <tr>
            <td>Đơn vị</td>
            <td>{selectedLog.DepartmentName}</td>
          </tr>
          <tr>
            <td>IP</td>
            <td>{selectedLog.HostIP}</td>
          </tr>
          <tr>
            <td>Thời gian</td>
            <td>{selectedLog.RequestDate}</td>
          </tr>
          <tr>
            <td>Request</td>
            <td>{selectedLog.Request}</td>
          </tr>
          <tr>
            <td>Nội dung</td>
            <td>
              <JsonDisplayer json={selectedLog.Body}></JsonDisplayer>
            </td>
          </tr>
          <tr>
            <td>Kết quả</td>
            <td>
              <JsonDisplayer json={selectedLog.Result}></JsonDisplayer>
            </td>
          </tr>
        </tbody>
      </table>
    </Modal>
  );
}

export default LogDetail;
