import React, { useCallback, useMemo, useRef, useState } from "react";
import { Button, Input, Modal, Select, Radio, DatePicker } from "antd";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import styled from "styled-components";
import ApiUtils from "../../../utils/ApiUtils";
import AppUtils from "../../../utils/AppUtils";
import moment from "moment";
import { RecordStatus, RecordStatusInfo } from "./Records";
import DicOfCustomers from "../Customers/DicOfCustomers";
import DicOfAdministrativeProcedures from "../AdministrativeProcedures/DicOfAdministrativeProcedures";
import DicOfReceivingAgency from "../ReceivingAgency";

const CustomSelect = styled(Select)`
  width: 100%;
`;

const DefaultDate = moment(new Date()).format(AppUtils.DATE_FORMAT);

const DateStatus = {
  today: "today",
  startEnd: "startEnd",
  all: "all",
};

const CustomRaidoGroup = styled(Radio.Group)`
  display: flex;
  gap: 8px;
`;

const RadioWrapper = styled(Radio.Group)`
  display: flex;
  border: 1px solid ${AppUtils.CONTROL_BORDER_COLOR};
  padding: 4px;
`;

const AllDateRadio = styled(Radio)`
  margin-top: 5px;
`;

const CustomerInputWrapper = styled.div`
  display: flex;
  gap: 4px;
`;

function RecordSearchBox({
  department,
  isModalVisible,
  handleSearch,
  handleCancel,
  recordStatus = 0,
}) {
  const currentDepartment = useRef(department);
  const [customerList, setCustomerList] = useState([]);
  const [administrativeProcedureNameList, setAdministrativeProcedureNameList] =
    useState([]);
  const [fieldNameList, setFieldNameList] = useState([]);
  const [customerLoading, setCustomerLoading] = useState(false);
  const [adminLoading, setAdminLoading] = useState(false);
  const [fieldLoading, setFieldLoading] = useState(false);
  const [dateStatus, setDateStatus] = useState(DateStatus.today);
  const [isCustomerListVisible, setIsCustomerListVisible] = useState(false);
  const [isProcedureListVisible, setIsProcedureListVisible] = useState(false);
  const [isReceivingAgencyListVisible, setIsReceivingAgencyListVisible] =
    useState(false);

  const formik = useFormik({
    initialValues: {
      StartDate: DefaultDate,
      EndDate: DefaultDate,
      ReceiptKey: "",
      AdministrativeFileNumber: "",
      ReceivingAgency: "",
      CustomerKey: "",
      CustomerName: "",
      FieldName: "",
      AdministrativeProcedureName: "",
      Sumary: "",
      RecordStatus: recordStatus,
    },
    onSubmit: async (values) => {
      const resultValue = { ...values };
      if (dateStatus === DateStatus.all) {
        resultValue.StartDate = "";
        resultValue.EndDate = "";
      }
      handleSearch && handleSearch(resultValue);
    },
  });

  const RecordStatusControls = useMemo(() => {
    if (recordStatus === 0) {
      return Object.values(RecordStatus).map((value) => {
        const { label, style } = RecordStatusInfo[value];
        return { value, label, style };
      });
    }
    const { label, style } = RecordStatusInfo[recordStatus];
    return [
      {
        value: recordStatus,
        label,
        style,
      },
    ];
  }, [recordStatus]);

  const onCustomerKeyOpen = useCallback(
    (open) => {
      if (
        !open ||
        (customerList.length && department === currentDepartment.current)
      ) {
        return;
      }
      setCustomerLoading(true);
      setCustomerList([]);
      ApiUtils.recordListCustmerKey(department, (data) => {
        setCustomerList(
          data.map((item) => ({
            ...item,
            CustomerName: `${item.CustomerKey} - ${item.CustomerName}`,
          }))
        );
        currentDepartment.current = department;
        setCustomerLoading(false);
      });
    },
    [customerList, department]
  );

  const onDateStatusChange = (e) => {
    setDateStatus(e.target.value);
  };

  const onAdministrativeProcedureNameOpen = useCallback(
    (open) => {
      if (
        !open ||
        (administrativeProcedureNameList.length &&
          department === currentDepartment.current)
      ) {
        return;
      }
      setAdminLoading(true);
      setAdministrativeProcedureNameList([]);
      ApiUtils.recordListAdministrativeProcedureName(department, (data) => {
        setAdministrativeProcedureNameList(data);
        currentDepartment.current = department;
        setAdminLoading(false);
      });
    },
    [administrativeProcedureNameList, department]
  );

  const onFieldNameOpen = useCallback(
    (open) => {
      if (
        !open ||
        (fieldNameList.length && department === currentDepartment.current)
      ) {
        return;
      }
      setFieldLoading(true);
      setFieldNameList([]);
      ApiUtils.recordListFieldName(department, (data) => {
        setFieldNameList(data);
        currentDepartment.current = department;
        setFieldLoading(false);
      });
    },
    [fieldNameList, department]
  );

  const handleCustomersCancel = () => {
    setIsCustomerListVisible(false);
  };

  const handleProcedureCancel = () => {
    setIsProcedureListVisible(false);
  };

  const handleAgenciesCancel = () => {
    setIsReceivingAgencyListVisible(false);
  };

  const onCustomerSelected = (customer) => {
    setIsCustomerListVisible(false);
    formik.setFieldValue("CustomerName", customer.CustomerName);
  };
  const onCustomerKeySelected = (key) => {
    setIsCustomerListVisible(false);
    formik.setFieldValue("CustomerKey", key);
  };

  const onProcedureSelected = (customer) => {
    setIsProcedureListVisible(false);
    formik.setFieldValue("AdministrativeProcedureName", customer.APName);
  };

  const onAgencySelected = (agency) => {
    setIsReceivingAgencyListVisible(false);
    formik.setFieldValue("ReceivingAgency", agency.Message);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Modal
        title="Tìm kiếm hồ sơ"
        visible={isModalVisible}
        onOk={formik.submitForm}
        onCancel={handleCancel}
        maskClosable={false}
        width={1000}
        footer={[
          <Button key="submit" type="primary" onClick={formik.submitForm}>
            Tìm kiếm
          </Button>,
          <Button key="back" onClick={handleCancel}>
            Thoát
          </Button>,
        ]}
      >
        <div class="form-group mb-3">
          <h5>Ngày nhập</h5>
          <RadioWrapper>
            <CustomRaidoGroup onChange={onDateStatusChange} value={dateStatus}>
              <Radio value={DateStatus.today}>
                Trong ngày:
                <DatePicker
                  disabled={dateStatus !== DateStatus.today}
                  defaultValue={moment()}
                  format={AppUtils.DATE_FORMAT}
                  onChange={(_, dateString) => {
                    formik.setFieldValue("StartDate", dateString);
                    formik.setFieldValue("EndDate", dateString);
                  }}
                />
              </Radio>
              <Radio value={DateStatus.startEnd}>
                Từ ngày:
                <DatePicker
                  disabled={dateStatus !== DateStatus.startEnd}
                  defaultValue={moment()}
                  format={AppUtils.DATE_FORMAT}
                  onChange={(_, dateString) => {
                    formik.setFieldValue("StartDate", dateString);
                  }}
                />{" "}
                Đến ngày:
                <DatePicker
                  disabled={dateStatus !== DateStatus.startEnd}
                  defaultValue={moment()}
                  format={AppUtils.DATE_FORMAT}
                  onChange={(_, dateString) => {
                    formik.setFieldValue("EndDate", dateString);
                  }}
                />
              </Radio>
              <AllDateRadio value={DateStatus.all}>Tất cả</AllDateRadio>
            </CustomRaidoGroup>
          </RadioWrapper>
        </div>
        <div class="form-group mb-3">
          <h5>Số biên nhận</h5>
          <Input
            name="ReceiptKey"
            value={formik.values.ReceiptKey}
            onChange={formik.handleChange}
          />
        </div>
        <div class="form-group mb-3">
          <h5>Mã HS DVCQG</h5>
          <Input
            name="AdministrativeFileNumber"
            value={formik.values.AdministrativeFileNumber}
            onChange={formik.handleChange}
          />
        </div>
        <div class="form-group mb-3">
          <h5>Mã định danh</h5>
          <Input
            name="CustomerKey"
            value={formik.values.CustomerKey}
            onChange={formik.handleChange}
          />
        </div>
        <div class="form-group mb-3">
          <h5>Tên Khách hàng</h5>
          <CustomerInputWrapper>
            <Input
              name="CustomerName"
              value={formik.values.CustomerName}
              onChange={formik.handleChange}
            />
            <Button
              onClick={() => {
                setIsCustomerListVisible(true);
              }}
            >
              Tìm kiếm
            </Button>
          </CustomerInputWrapper>
        </div>
        <div class="form-group mb-3">
          <h5>Cơ quan tiếp nhận</h5>
          <CustomerInputWrapper>
            <Input
              name="ReceivingAgency"
              value={formik.values.ReceivingAgency}
              onChange={formik.handleChange}
            />
            <Button
              onClick={() => {
                setIsReceivingAgencyListVisible(true);
              }}
            >
              Tìm kiếm
            </Button>
          </CustomerInputWrapper>
        </div>
        <div class="form-group mb-3">
          <h5>Lĩnh vực</h5>
          <CustomSelect
            name="FieldName"
            placeholder="Please select"
            defaultValue={null}
            loading={fieldLoading}
            value={formik.values.FieldName}
            options={fieldNameList}
            showSearch
            allowClear
            fieldNames={{
              label: "Message",
              value: "Message",
              options: "options",
            }}
            onChange={(value) => {
              formik.setFieldValue("FieldName", value);
            }}
            onDropdownVisibleChange={onFieldNameOpen}
          />
        </div>
        <div class="form-group mb-3">
          <h5>Thủ tục hành chính</h5>
          <CustomerInputWrapper>
            <Input
              name="AdministrativeProcedureName"
              value={formik.values.AdministrativeProcedureName}
              onChange={formik.handleChange}
            />
            <Button
              onClick={() => {
                setIsProcedureListVisible(true);
              }}
            >
              Tìm kiếm
            </Button>
          </CustomerInputWrapper>
        </div>
        <div class="form-group mb-3">
          <h5>Trích yếu</h5>
          <Input
            name="Sumary"
            value={formik.values.Sumary}
            onChange={formik.handleChange}
          />
        </div>
        <div class="form-group mb-0">
          <h5>Tình Trạng</h5>
          <RadioWrapper>
            <CustomRaidoGroup
              onChange={(e) => {
                formik.setFieldValue("RecordStatus", e.target.value);
              }}
              value={formik.values.RecordStatus}
            >
              {RecordStatusControls.map(({ label, value, style }) => (
                <Radio className={style} value={value}>
                  {label}
                </Radio>
              ))}
            </CustomRaidoGroup>
          </RadioWrapper>
        </div>
      </Modal>
      <Modal
        title="Từ điển khách hàng"
        visible={isCustomerListVisible}
        onOk={handleCustomersCancel}
        onCancel={handleCustomersCancel}
        width={800}
      >
        <DicOfCustomers
          handleCustomerSelected={onCustomerSelected}
          handleCustomerKeySelected={onCustomerKeySelected}
        ></DicOfCustomers>
      </Modal>
      <Modal
        title="Từ điển thủ tục hành chính"
        visible={isProcedureListVisible}
        onOk={handleProcedureCancel}
        onCancel={handleProcedureCancel}
        width={800}
      >
        <DicOfAdministrativeProcedures
          handleCustomerSelected={onProcedureSelected}
        ></DicOfAdministrativeProcedures>
      </Modal>
      <Modal
        title="Từ điển cơ quan tiếp nhận"
        visible={isReceivingAgencyListVisible}
        onOk={handleAgenciesCancel}
        onCancel={handleAgenciesCancel}
        width={800}
      >
        <DicOfReceivingAgency
          handleCustomerSelected={onAgencySelected}
        ></DicOfReceivingAgency>
      </Modal>
    </form>
  );
}

RecordSearchBox.propTypes = {
  isModalVisible: PropTypes.bool,
  handleSearch: PropTypes.func,
  handleCancel: PropTypes.func,
};

export default RecordSearchBox;
