import { Input, Button, Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import ApiUtils from "../../../utils/ApiUtils";
import AppUtils from "../../../utils/AppUtils";
import styled from "styled-components";
import { SaveOutlined } from "@ant-design/icons";

const Wrapper = styled.div``;

const Footer = styled.div`
  padding: 20px;
  display: flex;
  justify-content: end;
  gap: 8px;
`;

function Settings() {
  const [documentTypeMaxFields, setDocumentTypeMaxFields] = useState("0");
  const [documentExpireDayRemind, setDocumentExpireDayRemind] = useState("0");
  const [MaxExportRow, setMaxExportRow] = useState("0");
  const [JobUpdateUsers, setJobUpdateUsers] = useState(0);
  const [JobUpdateDepartment, setJobUpdateDepartment] = useState(0);
  const [JobUpdateCustomer, setJobUpdateCustomer] = useState(0);
  const [JobUpdateAdministrativeProcedure, setJobUpdateAdministrativeProcedure] = useState(0);
  const [JobUpdateRecordStatus2, setJobUpdateRecordStatus2] = useState(0);
  const [JobUpdateDocumentStatus2, setJobUpdateDocumentStatus2] = useState(0);


  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    ApiUtils.settingGet((responseData) => {
      if (!responseData) {
        AppUtils.showMessage("Thông báo", "Lỗi kết nối!");
        return;
      } else {
        if (!responseData?.Success) {
          AppUtils.showMessage("Thông báo", responseData.Message);
          return;
        } else {
          setDocumentTypeMaxFields(responseData?.DocumentTypeMaxFields);
          setDocumentExpireDayRemind(responseData?.DocumentExpireDayRemind);
          setMaxExportRow(responseData?.MaxExportRow);
          setJobUpdateUsers(responseData?.JobUpdateUsers);
          setJobUpdateDepartment(responseData?.JobUpdateDepartment);
          setJobUpdateCustomer(responseData?.JobUpdateCustomer);
          setJobUpdateAdministrativeProcedure(responseData?.JobUpdateAdministrativeProcedure);
          setJobUpdateRecordStatus2(responseData?.JobUpdateRecordStatus2);
          setJobUpdateDocumentStatus2(responseData?.JobUpdateDocumentStatus2);
        }
      }
    })
  }

  const updateSettings = () => {
    let data = {
      DocumentTypeMaxFields: documentTypeMaxFields,
      DocumentExpireDayRemind: documentExpireDayRemind,
      MaxExportRow: MaxExportRow,
      JobUpdateUsers: JobUpdateUsers,
      JobUpdateDepartment: JobUpdateDepartment,
      JobUpdateCustomer: JobUpdateCustomer,
      JobUpdateAdministrativeProcedure: JobUpdateAdministrativeProcedure,
      JobUpdateRecordStatus2: JobUpdateRecordStatus2,
      JobUpdateDocumentStatus2: JobUpdateDocumentStatus2,
    }
    ApiUtils.settingUpdate(data
      , (responseData) => {
        if (!responseData) {
          AppUtils.showMessage("Thông báo", "Lỗi kết nối!");
          return;
        } else {
          AppUtils.showMessage("Thông báo", responseData.Message);
          if (responseData.Success) {
            getData();
          }
        }
      })
  }

  const onChangeJobUpdateUsers = () => {
    setJobUpdateUsers(1 - JobUpdateUsers);
  }
  const onChangeJobUpdateDepartment = () => {
    setJobUpdateDepartment(1 - JobUpdateDepartment);
  }
  const onChangeJobUpdateCustomer = () => {
    setJobUpdateCustomer(1 - JobUpdateCustomer);
  }
  const onChangeJobUpdateAdministrativeProcedure = () => {
    setJobUpdateAdministrativeProcedure(1 - JobUpdateAdministrativeProcedure);
  }
  const onChangeJobUpdateRecordStatus2 = () => {
    setJobUpdateRecordStatus2(1 - JobUpdateRecordStatus2);
  }
  const onChangeJobUpdateDocumentStatus2 = () => {
    setJobUpdateDocumentStatus2(1 - JobUpdateDocumentStatus2);
  }

  return (
    <Wrapper>
      Số trường đặc tả tối đa
      <br /><Input
        value={documentTypeMaxFields}
        onChange={(event) => {
          setDocumentTypeMaxFields(event.target.value);
        }} />
      <br /><br />Số ngày tài liệu sắp hết hiệu lực
      <br /><Input
        value={documentExpireDayRemind}
        onChange={(event) => {
          setDocumentExpireDayRemind(event.target.value);
        }} />
      <br /><br />Số dòng dữ liệu cho phép xuất file
      <br /><Input
        value={MaxExportRow}
        onChange={(event) => {
          setMaxExportRow(event.target.value);
        }} />
      <br /><br />
      <Checkbox
        checked={JobUpdateDepartment == 1}
        onChange={onChangeJobUpdateDepartment}
      >Tự động Cập nhật danh sách Đơn vị</Checkbox>
      <br /><br />
      <Checkbox
        checked={JobUpdateUsers == 1}
        onChange={onChangeJobUpdateUsers}
      >Tự động Cập nhật danh sách Tài khoản người dùng</Checkbox>
      <br /><br />
      <Checkbox
        checked={JobUpdateCustomer == 1}
        onChange={onChangeJobUpdateCustomer}
      >Tự động Cập nhật danh sách Khách hàng</Checkbox>
      <br /><br />
      <Checkbox
        checked={JobUpdateAdministrativeProcedure == 1}
        onChange={onChangeJobUpdateAdministrativeProcedure}
      >Tự động Cập nhật danh sách Thủ tục hành chính</Checkbox>
      <br /><br />
      <Checkbox
        checked={JobUpdateRecordStatus2 == 1}
        onChange={onChangeJobUpdateRecordStatus2}
      >Tự động Cập nhật Trạng tháo hồ sơ chờ tiêu hủy</Checkbox>
      <br /><br />
      <Checkbox
        checked={JobUpdateDocumentStatus2 == 1}
        onChange={onChangeJobUpdateDocumentStatus2}
      >Tự động Cập nhật Trạng thái văn bản hết hiệu lực</Checkbox>
      <Footer>
        <Button
          icon={<SaveOutlined />}
          onClick={() => updateSettings()}
        >
          Cập nhật
        </Button>
      </Footer>
    </Wrapper>
  )
}
export default Settings;