import React, { useEffect, useState } from "react";
import { Button, Input, Modal, InputNumber, Radio } from "antd";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import ApiUtils from "../../../utils/ApiUtils";
import AppUtils from "../../../utils/AppUtils";
import * as yup from "yup";
import styled from "styled-components";

const FullInputNumber = styled(InputNumber)`
  width: 100%;
  display: block;
`;

const CustomRadioGroup = styled(Radio.Group)`
  display: block;
  line-height: 30px;
`;

const UNIT = {
  day: "day",
  month: "month",
  year: "year",
  none: "none",
};

function DisOfStorageForm({
  editingSL,
  isModalEditVisible,
  handleAfterSaved,
  handleEditCancel,
}) {
  const [editingLoading, setEditingLoading] = useState(false);

  const validationSchema = yup.object({
    StorageLifeKey: yup.string().required(),
  });

  const formik = useFormik({
    initialValues: {
      StorageLifeKey: "",
      StorageLifeName: "",
      StorageValue: "",
      Unit: "year",
      WarningDays: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setEditingLoading(true);
      if (values.StorageLifeID) {
        ApiUtils.storageLifeUpdate(values, (data) => {
          AppUtils.showMessage("Thông báo", data?.Message);
          setEditingLoading(false);
        });
      } else {
        ApiUtils.storageLifeInsert(values, (data) => {
          AppUtils.showMessage("Thông báo", data?.Message);
          setEditingLoading(false);
        });
      }
      handleAfterSaved && handleAfterSaved();
    },
  });

  const deleteStorageLifeHandle = () => {
    ApiUtils.storageLifeDelete(editingSL.StorageLifeID, (data) => {
      AppUtils.showMessage("Thông báo", data?.Message);
      setEditingLoading(false);
      handleAfterSaved && handleAfterSaved();
    });
  };

  useEffect(() => {
    if (!editingSL) {
      return;
    }
    if (!editingSL.StorageLifeID) {
      formik.resetForm();
      return;
    }
    formik.setValues(editingSL);
  }, [editingSL]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Modal
        title="Thời hạn bảo quản"
        visible={isModalEditVisible}
        onOk={formik.submitForm}
        onCancel={handleEditCancel}
        maskClosable={false}
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={editingLoading}
            onClick={formik.submitForm}
          >
            Lưu
          </Button>,
          <Button
            danger
            disabled={!editingSL?.AllowEdit}
            onClick={deleteStorageLifeHandle}
          >
            Xóa
          </Button>,
          <Button key="back" onClick={handleEditCancel}>
            Thoát
          </Button>,
        ]}
      >
        <br />
        Mã{" "}
        <Input
          name="StorageLifeKey"
          value={formik.values.StorageLifeKey}
          onChange={formik.handleChange}
        />
        <br />
        Tên{" "}
        <Input
          name="StorageLifeName"
          value={formik.values.StorageLifeName}
          onChange={formik.handleChange}
        />
        {formik.values.Unit !== UNIT.none && (
          <>
            <br />
            Thời gian{" "}
            <FullInputNumber
              name="StorageValue"
              value={formik.values.StorageValue}
              onChange={(value) => {
                formik.setFieldValue("StorageValue", value);
              }}
            />{" "}
          </>
        )}
        Đơn vị tính{" "}
        <CustomRadioGroup
          onChange={(e) => {
            formik.setFieldValue("Unit", e.target.value);
            if (e.target.value === UNIT.none) {
              formik.setFieldValue("StorageValue", 0);
              formik.setFieldValue("WarningDays", 0);
            }
          }}
          value={formik.values.Unit}
        >
          <Radio value={UNIT.day}>Ngày</Radio>
          <Radio value={UNIT.month}>Tháng</Radio>
          <Radio value={UNIT.year}>Năm</Radio>
          <Radio value={UNIT.none}>Không có</Radio>
        </CustomRadioGroup>
        {formik.values.Unit !== UNIT.none && (
          <>
            Ngày cảnh báo{" "}
            <FullInputNumber
              name="WarningDays"
              value={formik.values.WarningDays}
              onChange={(value) => {
                formik.setFieldValue("WarningDays", value);
              }}
            />{" "}
          </>
        )}
      </Modal>
    </form>
  );
}

DisOfStorageForm.propTypes = {
  editingSL: PropTypes.object,
  isModalEditVisible: PropTypes.bool,
  handleAfterSaved: PropTypes.func,
  handleEditCancel: PropTypes.func,
};

export default DisOfStorageForm;
