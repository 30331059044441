import React from "react";
import { Button, Modal } from "antd";

function UserDetail({
  selectedUser,
  isModalDetailVisible,
  handleDetailCancel,
}) {
  return (
    <Modal
      title="Thông tin người dùng"
      visible={isModalDetailVisible}
      onCancel={handleDetailCancel}
      maskClosable={true}
      footer={[
        <Button key="back" onClick={handleDetailCancel}>
          Thoát
        </Button>,
      ]}
    >
      <table border={2} cellPadding={5} style={{ width: "100%" }}>
        <tbody>
          <tr>
            <td colSpan={2}>
              <img src={selectedUser.IsAdmin ? 'assets/SmartAdmin/img/govfiles/ic_userroleadmin_16px.png' : 'assets/SmartAdmin/img/govfiles/ic_user_16px.png'} alt='' />
              {selectedUser.IsAdmin ? " Quản trị" : " Nhân viên"}
            </td>
          </tr>
          <tr>
            <td>Tên đăng nhập</td>
            <td>{selectedUser.Username}</td>
          </tr>
          <tr>
            <td>Họ tên</td>
            <td>{selectedUser.Fullname}</td>
          </tr>
          <tr>
            <td>Đơn vị</td>
            <td>{selectedUser.DepartmentName}</td>
          </tr>
          <tr>
            <td>Email</td>
            <td>{selectedUser.Email}</td>
          </tr>
          <tr>
            <td>Điện thoại</td>
            <td>{selectedUser.PhoneNumber}</td>
          </tr>
          <tr>
            <td>Số CCCD</td>
            <td>{selectedUser.CitizenIdentificationNumber}</td>
          </tr>
          <tr>
            <td>Địa chỉ</td>
            <td>{selectedUser.HomeAddress}</td>
          </tr>
          <tr>
            <td>Tỉnh thành</td>
            <td>{selectedUser.Province}</td>
          </tr>
          <tr>
            <td>Quận huyện</td>
            <td>{selectedUser.District}</td>
          </tr>
          <tr>
            <td>Phường xã</td>
            <td>{selectedUser.Village}</td>
          </tr>
          <tr>
            <td>Ghi chú</td>
            <td>{selectedUser.Note}</td>
          </tr>
        </tbody>
      </table>
    </Modal>
  );
}

export default UserDetail;