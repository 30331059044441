import React, { useContext } from "react";
import { useScript } from "../../../hooks";
import LeftMenuGroup from "./LeftMenuGroup";
import LeftMenuItem from "./LeftMenuItem";
import AppUtils from "../../../utils/AppUtils";
import { VisitCounterContext } from "../../../AppContexts";
import styled from "styled-components";

const VisitCount = styled.div`
  padding: 4px 10px 8px 32px;
  color: white;
  font-weight: bold;
`;

function LeftPanel() {
  const { visitCount } = useContext(VisitCounterContext);

  useScript("/assets/SmartAdmin/js/app.bundle.js");
  return (
    <aside class="page-sidebar">
      <div class="page-logo">
        <a
          href="#"
          class="page-logo-link press-scale-down d-flex align-items-center position-relative"
          data-toggle="modal"
          data-target="#modal-shortcut"
        >
          <img
            src="/assets/SmartAdmin/img/govfiles/logo.png"
            alt="SmartAdmin WebApp"
            aria-roledescription="logo"
          />
          <span class="page-logo-text mr-1">Kho lưu trữ điện tử</span>
          <span class="position-absolute text-white opacity-50 small pos-top pos-right mr-2 mt-n2">
            Tỉnh Khánh Hòa
          </span>
          <i class="fal fa-angle-down d-inline-block ml-1 fs-lg color-primary-300"></i>
        </a>
      </div>

      <nav id="js-primary-nav" class="primary-nav" role="navigation">
        <div class="nav-filter">
          <div class="position-relative">
            <input
              type="text"
              id="nav_filter_input"
              placeholder="Filter menu"
              class="form-control"
              tabindex="0"
            />
            <a
              href="#"
              onclick="return false;"
              class="btn-primary btn-search-close js-waves-off"
              data-action="toggle"
              data-class="list-filter-active"
              data-target=".page-sidebar"
            >
              <i class="fal fa-chevron-up"></i>
            </a>
          </div>
        </div>
        <div class="info-card">
          <img
            src="/assets/SmartAdmin/img/govfiles/avatars/avatar-admin.png"
            class="profile-image rounded-circle"
            alt=""
          />
          <div class="info-card-text">
            <a href="#" class="d-flex align-items-center text-white">
              <span class="text-truncate text-truncate-sm d-inline-block">
                {AppUtils.getAuthUser()?.Fullname}
              </span>
            </a>
            <span class="d-inline-block text-truncate text-truncate-sm">
              {AppUtils.getAuthUser()?.DepartmentName}
            </span>
          </div>
          <img
            src="/assets/SmartAdmin/img/card-backgrounds/cover-2-lg.png"
            class="cover"
            alt="cover"
          />
          <a
            href="#"
            onclick="return false;"
            class="pull-trigger-btn"
            data-action="toggle"
            data-class="list-filter-active"
            data-target=".page-sidebar"
            data-focus="nav_filter_input"
          >
            <i class="fal fa-angle-down"></i>
          </a>
        </div>
        <ul id="js-nav-menu" class="nav-menu">
          {AppUtils.getAuthUser().UserMenus?.map((menu) => {
            return menu.ListMenus?.length > 0 ? (
              <LeftMenuGroup menu={menu} />
            ) : (
              <LeftMenuItem menu={menu} />
            );
          })}
        </ul>
        <div class="filter-message js-filter-message bg-success-600"></div>
      </nav>
      <VisitCount>Tổng truy cập: {visitCount}</VisitCount>
      <div class="nav-footer shadow-top">
        <a
          href="#"
          onclick="return false;"
          data-action="toggle"
          data-class="nav-function-minify"
          class="hidden-md-down"
        >
          <i class="ni ni-chevron-right"></i>
          <i class="ni ni-chevron-right"></i>
        </a>
        <ul class="list-table m-auto nav-footer-buttons">
          <li>
            <a
              href="javascript:void(0);"
              data-toggle="tooltip"
              data-placement="top"
              title="Chat logs"
            >
              <i class="fal fa-comments"></i>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0);"
              data-toggle="tooltip"
              data-placement="top"
              title="Support Chat"
            >
              <i class="fal fa-life-ring"></i>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0);"
              data-toggle="tooltip"
              data-placement="top"
              title="Make a call"
            >
              <i class="fal fa-phone"></i>
            </a>
          </li>
        </ul>
      </div>
    </aside>
  );
}

export default LeftPanel;
