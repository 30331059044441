import { Input, Button, Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import ApiUtils from "../../../utils/ApiUtils";
import AppUtils from "../../../utils/AppUtils";
import styled from "styled-components";
import { SaveOutlined } from "@ant-design/icons";

const Wrapper = styled.div``;

const Footer = styled.div`
  padding: 20px;
  display: flex;
  justify-content: end;
  gap: 8px;
`;

function AccountInfo() {
  return (
    <Wrapper>
      <table
        className="table table-bordered table-striped"
        border={2}
        cellPadding={5}
        style={{ width: '100%' }}
      >
        <tbody>
          <tr>
            <td>
              <h5>UserID</h5>
            </td>
            <td>
              {AppUtils.getAuthUser().UserID}
            </td>
          </tr>
          <tr>
            <td>
              <h5>UserKey</h5>
            </td>
            <td>
              {AppUtils.getAuthUser().UserKey}
            </td>
          </tr>
          <tr>
            <td>
              <h5>Username</h5>
            </td>
            <td>
              {AppUtils.getAuthUser().Username}
            </td>
          </tr>
          <tr>
            <td>
              <h5>Fullname</h5>
            </td>
            <td>
              {AppUtils.getAuthUser().Fullname}
            </td>
          </tr>
          <tr>
            <td>
              <h5>Email</h5>
            </td>
            <td>
              {AppUtils.getAuthUser().Email}
            </td>
          </tr>
          <tr>
            <td>
              <h5>DepartmentID</h5>
            </td>
            <td>
              {AppUtils.getAuthUser().DepartmentID}
            </td>
          </tr>
          <tr>
            <td>
              <h5>DepartmentKey</h5>
            </td>
            <td>
              {AppUtils.getAuthUser().DepartmentKey}
            </td>
          </tr>
          <tr>
            <td>
              <h5>DepartmentName</h5>
            </td>
            <td>
              {AppUtils.getAuthUser().DepartmentName}
            </td>
          </tr>
          <tr>
            <td>
              <h5>IsInUse</h5>
            </td>
            <td>
              {AppUtils.getAuthUser().IsInUse ? "YES" : "NO"}
            </td>
          </tr>
          <tr>
            <td>
              <h5>IsAdmin</h5>
            </td>
            <td>
              {AppUtils.getAuthUser().IsAdmin ? "YES" : "NO"}
            </td>
          </tr>
          <tr>
            <td>
              <h5>IsDelete</h5>
            </td>
            <td>
              {AppUtils.getAuthUser().IsDelete ? "YES" : "NO"}
            </td>
          </tr>
          <tr>
            <td>
              <h5>Token</h5>
            </td>
            <td>
              {AppUtils.userToken()}
            </td>
          </tr>
        </tbody>
      </table>
    </Wrapper>
  )
}
export default AccountInfo;