import React, { useState, useEffect, useRef, useCallback } from "react";
import { Button, DatePicker, Input, Modal, Select, Space } from "antd";
import { HistoryOutlined } from "@ant-design/icons";
import ApiUtils from "../../../utils/ApiUtils";
import AppUtils from "../../../utils/AppUtils";
import SelectDocumentType from "../DocumentTypes/SelectDocumentType";
import DocumentExternalDataEdit from "./DocumentExternalDataEdit";
import { Formik, Form } from "formik";
import styled from "styled-components";
import moment from "moment";
import LogByReference from "../Log/LogByReference";

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CustomSelect = styled(Select)`
  width: 100%;
`;

function DocumentEdit({
  selectedDocument,
  isModalVisible,
  handleCancel,
  handleAfterSaved,
  recordId,
  handleAfterSaveDocumentType,
}) {
  const [editingLoading, setEditingLoading] = useState(false);
  const [currentDocument, setCurrentDocument] = useState({ DocumentID: "" });
  const [initialForm, setInitialForm] = useState({
    externalList: [],
  });
  const [documentGroupList, setDocumentGroupList] = useState([]);
  const [documentGroupListLoading, setDocumentGroupListLoading] =
    useState(false);

  useEffect(() => {
    setCurrentDocument(selectedDocument);
    setInitialForm({
      ...selectedDocument,
      externalList: selectedDocument.ExternalData,
    });
  }, [selectedDocument]);

  const openFile = (FileID) => {
    ApiUtils.documentFileGetById(FileID, (responseData) => {
      if (!responseData?.Success) {
        AppUtils.showMessage("Thông báo", responseData?.Message);
        return;
      }
      if (responseData?.DownloadUrl) {
        window.open(responseData?.DownloadUrl);
      } else {
        AppUtils.showMessage("Thông báo", "Văn bản này không có file");
        return;
      }
    });
  };

  const reloadDocument = useCallback(() => {
    ApiUtils.documentGetByIdAndRecord(
      currentDocument.DocumentID,
      currentDocument.RecordID,
      (responseData) => {
        if (!responseData) {
          AppUtils.showMessage("Thông báo", "Lỗi kết nối");
          return;
        }
        if (!responseData?.Success) {
          AppUtils.showMessage("Thông báo", responseData.Message);
          return;
        }
        setCurrentDocument(responseData);
      }
    );
  }, [currentDocument]);

  const saveDocument = useCallback(
    (values) => {
      if (!currentDocument?.AllowEdit && currentDocument.DocumentID) {
        AppUtils.showMessage("Không được phép cập nhật!");
        return;
      }
      if (values.DocumentID) {
        ApiUtils.documentUpdate(values, (responseData) => {
          if (!responseData) {
            AppUtils.showMessage("Thông báo", "Lỗi kết nối");
            return;
          }
          AppUtils.showMessage("Thông báo", responseData?.Message);
          if (responseData?.Success) {
            handleAfterSaved();
          }
        });
      } else {
        const newValues = { ...values, RecordID: recordId };
        ApiUtils.documentInsert(newValues, (responseData) => {
          if (!responseData) {
            AppUtils.showMessage("Thông báo", "Lỗi kết nối");
            return;
          }
          AppUtils.showMessage("Thông báo", responseData?.Message);
          if (responseData?.Success) {
            handleAfterSaved();
          }
        });
      }
    },
    [currentDocument]
  );

  const updateDocumentType = (dt) => {
    if (window.confirm("Xác nhận chuyển sang loại văn bản này?")) {
      ApiUtils.documentUpdateDocumentType(
        currentDocument.DocumentID,
        dt.DocumentTypeID,
        (responseData) => {
          AppUtils.showMessage("Thông báo", responseData?.Message);
          if (responseData?.Success) {
            reloadDocument();
            handleAfterSaved();
          }
        }
      );
    }
  };

  const onDocumentGroupOpen = useCallback(
    (open) => {
      if (!open || documentGroupList.length) {
        return;
      }
      setDocumentGroupListLoading(true);
      setDocumentGroupList([]);
      ApiUtils.documentGroupGet((data) => {
        setDocumentGroupList(data);
        setDocumentGroupListLoading(false);
      });
    },
    [documentGroupList]
  );

  //Log
  const [isModalLogVisible, setIsModalLogVisible] = useState(false);
  const [referenceId, setReferenceId] = useState("");
  const checkLogs = (id) => {
    setReferenceId(id);
    setIsModalLogVisible(true);
  };
  const checkLogCancel = () => {
    setIsModalLogVisible(false);
  };

  return (
    <Formik
      initialValues={initialForm}
      enableReinitialize={true}
      onSubmit={(values) => {
        saveDocument(values);
      }}
    >
      {({ handleSubmit, values, handleChange, setFieldValue }) => {
        return (
          <Form>
            <Modal
              title="Hiệu chỉnh văn bản"
              visible={isModalVisible}
              onCancel={handleCancel}
              maskClosable={true}
              width="85%"
              footer={[
                <FooterContent>
                  <SelectDocumentType
                    handleSelectDocumentType={updateDocumentType}
                    departmentId={currentDocument.DepartmentID}
                    style={{ marginTop: "10px" }}
                    allowEdit={currentDocument?.AllowEdit}
                  />
                  <div>
                    <Button
                      onClick={() => handleSubmit()}
                      loading={editingLoading}
                      style={{ color: "#5f8dce" }}
                      disabled={
                        !currentDocument?.AllowEdit &&
                        currentDocument.DocumentID
                      }
                    >
                      Lưu
                    </Button>
                    <Button
                      disabled={!AppUtils.userIsSystemAdmin()}
                      icon={<HistoryOutlined />}
                      onClick={() => checkLogs(currentDocument?.DocumentID)}
                    >
                      Logs
                    </Button>
                    <Button key="back" onClick={handleCancel}>
                      Thoát
                    </Button>
                  </div>
                </FooterContent>,
              ]}
            >
              <table className="table table-bordered table-striped">
                <tbody>
                  <tr>
                    <td width="30%">
                      <h5>Trạng thái</h5>
                    </td>
                    <td>{currentDocument?.DocumentStatusTitle}</td>
                  </tr>
                  <tr>
                    <td>
                      <h5>Nhóm giấy tờ</h5>
                    </td>
                    <td>{currentDocument?.DocumentGroupName}</td>
                  </tr>
                  <tr>
                    <td>
                      <h5>Loại văn bản</h5>
                    </td>
                    <td>
                      {currentDocument?.DocumentID ? (
                        currentDocument?.DocumentTypeName
                      ) : (
                        <SelectDocumentType
                          handleSelectDocumentType={(docType) => {
                            setFieldValue(
                              "DocumentTypeID",
                              docType.DocumentTypeID
                            );
                          }}
                          isSelectMode={true}
                          allowEdit={true}
                        />
                      )}
                    </td>
                  </tr>
                  {!currentDocument?.DocumentID && (
                    <tr>
                      <td>
                        <h5>Nhóm văn bản</h5>
                      </td>
                      <td>
                        <CustomSelect
                          name="DocumentGroupID"
                          placeholder="Please select"
                          defaultValue={null}
                          loading={documentGroupListLoading}
                          value={values.DocumentGroupID}
                          options={documentGroupList}
                          showSearch
                          allowClear
                          fieldNames={{
                            label: "DocumentGroupName",
                            value: "DocumentGroupID",
                            options: "options",
                          }}
                          onChange={(value) => {
                            setFieldValue("DocumentGroupID", value);
                          }}
                          onDropdownVisibleChange={onDocumentGroupOpen}
                        />
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>
                      <h5>Tên giấy tờ</h5>
                    </td>
                    <td>
                      <Input
                        name="Title"
                        value={values.Title}
                        onChange={handleChange}
                      ></Input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5>Trích yếu</h5>
                    </td>
                    <td>
                      <Input
                        name="Sumary"
                        value={values.Sumary}
                        onChange={handleChange}
                      ></Input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5>Số ký hiệu</h5>
                    </td>
                    <td>
                      <Input
                        name="SignatureNumber"
                        value={values.SignatureNumber}
                        onChange={handleChange}
                      ></Input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5>Số trang</h5>
                    </td>
                    <td>
                      <Input
                        name="PageCount"
                        value={values.PageCount}
                        onChange={handleChange}
                      ></Input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5>Người sở hữu</h5>
                    </td>
                    <td>
                      <Input
                        name="Owner"
                        value={values.Owner}
                        onChange={handleChange}
                      ></Input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5>Mã định danh</h5>
                    </td>
                    <td>
                      <Input
                        name="OwnerKey"
                        value={values.OwnerKey}
                        onChange={handleChange}
                      ></Input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5>Người ký duyệt</h5>
                    </td>
                    <td>
                      <Input
                        name="Signer"
                        value={values.Signer}
                        onChange={handleChange}
                      ></Input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5>Chức vụ người ký</h5>
                    </td>
                    <td>
                      <Input
                        name="SignerTitle"
                        value={values.SignerTitle}
                        onChange={handleChange}
                      ></Input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5>Ngày hiệu lực</h5>
                    </td>
                    <td>
                      <DatePicker
                        value={
                          values.ValidDate
                            ? moment(values.ValidDate, AppUtils.DATE_FORMAT)
                            : null
                        }
                        format={AppUtils.DATE_FORMAT}
                        onChange={(_, dateString) => {
                          setFieldValue("ValidDate", dateString);
                        }}
                        allowClear
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5>Thời hạn hiệu lực</h5>
                    </td>
                    <td>
                      <DatePicker
                        value={
                          values.ValidityPeriod
                            ? moment(
                                values.ValidityPeriod,
                                AppUtils.DATE_FORMAT
                              )
                            : null
                        }
                        format={AppUtils.DATE_FORMAT}
                        onChange={(_, dateString) => {
                          setFieldValue("ValidityPeriod", dateString);
                        }}
                        allowClear
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5>File đính kèm</h5>
                    </td>
                    <td>
                      {currentDocument?.Files?.map((item) => (
                        <>
                          <a
                            href="javascript:void()"
                            onClick={() => openFile(item.FileID)}
                          >
                            <img
                              src="/assets/SmartAdmin/img/govfiles/ic_document_available_16px.png"
                              alt=""
                            />{" "}
                            {item.FileName}
                            <br />
                          </a>
                        </>
                      ))}
                    </td>
                  </tr>
                </tbody>
              </table>
              <DocumentExternalDataEdit
                externalData={selectedDocument.ExternalData}
                documentId={currentDocument?.DocumentID}
                AllowEdit={currentDocument?.AllowEdit}
              />
              <LogByReference
                referenceId={referenceId}
                referenceType={"document"}
                isModallVisible={isModalLogVisible}
                handleCancel={checkLogCancel}
              />
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
}

export default DocumentEdit;
