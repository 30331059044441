import { Button, DatePicker, Space } from "antd";
import React, { useState, useEffect, useMemo } from "react";
import { FileAddOutlined } from "@ant-design/icons";
import DataTable from "../../../shared/components/DataTable/DataTable";
import PageContainer from "../../../shared/components/PageContainer/PageContainer";
import ApiUtils from "../../../utils/ApiUtils";
import AppUtils from "../../../utils/AppUtils";
import moment from "moment";
import useScript from "../../../hooks/useScript";
import RecordCollectEdit from "./RecordCollectEdit";
import SelectDepartmentWithAll from "../Departments/SelectDepartmentWithAll";
import styled from "styled-components";

const FilterWrapper = styled.div`
  margin-bottom: 20px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const DefaultDate = moment(new Date()).format(AppUtils.DATE_FORMAT);

function RecordCollect() {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState({
    DepartmentID: "",
  });

  const handleSelectDepartment = (department) => {
    setSelectedDepartment(department);
  };

  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [searchText, setSearchText] = useState("");

  useScript("plugins/custombox/js/custombox.min.js");
  useScript("plugins/custombox/js/legacy.min.js");

  useEffect(() => {
    setSelectedDepartment({
      DepartmentID: "FC81708E-9693-45A6-9980-3D01AC20528B",
      DepartmentName: "Sở Nội vụ",
    });
    setStartDate("17/08/2021");
    setEndDate("18/08/2021");
    setSearchText("");
  }, []);

  const columns = useMemo(
    () => [
      {
        title: "Số biên nhận",
        dataIndex: "SoBienNhan",
        key: "SoBienNhan",
      },
      {
        title: "Trích yếu",
        key: "TrichYeu",
        render: (_, record) => (
          <div>
            {record.IsSaved ?
              <img src={"assets/SmartAdmin/img/govfiles/ic_ok_16px.png"} alt="" />
              : <img src={"assets/SmartAdmin/img/govfiles/new_gif.gif"} alt="" />
            }{" "}
            <a
              href="javascript:void(0);"
              onClick={() => collectRecord(record.SoBienNhan)}
              style={{ color: "#242526" }}
            >
              {record.TrichYeu}
            </a>
          </div>
        ),
      },
      {
        title: "Lĩnh vực",
        key: "TenLinhVuc",
        render: (_, record) => (
          <div>
            <a
              href="javascript:void(0);"
              onClick={() => collectRecord(record.SoBienNhan)}
              style={{ color: "#242526" }}
            >
              {record.TenLinhVuc}
            </a>
          </div>
        ),
      },
      {
        title: "",
        key: "action",
        render: (_, record) =>
          !record.IsSaved ? (
            <Space size="middle">
              <Button 
              icon={<FileAddOutlined />}
              onClick={() => collectRecord(record.SoBienNhan)}>
                Thu thập
              </Button>
            </Space>
          ) : (
            ""
          ),
      },
    ],
    []
  );

  const getData = () => {
    if (
      !selectedDepartment.DepartmentID ||
      selectedDepartment?.DepartmentID == AppUtils.ROOT_ID
    ) {
      AppUtils.showMessage("Thông báo", "Chưa chọn đơn vị");
      return;
    }
    setSearchText({});
    setLoading(true);
    ApiUtils.recordCollectGetHS(
      selectedDepartment.DepartmentID,
      startDate,
      endDate,
      (responseData) => {
        setLoading(false);
        if (responseData.length == 1 && !responseData[0].Success) {
          if (responseData[0].Message == AppUtils.TOKEN_EXPIRED_MESSAGE) {
            AppUtils.logout();
            return;
          }
          AppUtils.showMessage("Thông báo", responseData[0].Message);
          return;
        }
        setRecords(responseData);
      }
    );
  };

  const [isModalEditVisible, setIsModalEditVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});

  const collectRecord = (SoBienNhan) => {
    ApiUtils.recordCollectGetThongTinHS(SoBienNhan, (responseData) => {
      if (!responseData) {
        AppUtils.showMessage("Thông báo", "Lỗi kết nối");
        return;
      }
      if (!responseData.Success) {
        AppUtils.showMessage("Thông báo", responseData.Message);
        return;
      }
      setSelectedRecord(responseData);
      setIsModalEditVisible(true);
    });
  };
  const handleAfterSaved = () => {
    setIsModalEditVisible(false);
  };
  const handleDetailCancel = () => {
    setIsModalEditVisible(false);
  };

  return (
    <PageContainer>
      <FilterWrapper>
        <SelectDepartmentWithAll
          showSelectAll={false}
          handleSelectDepartment={handleSelectDepartment}
        />
        <div className="row">
          <div className="col-2">
            <h5>Thời gian: </h5>
          </div>
          <div className="col-10">
            Từ ngày:{" "}
            <DatePicker
              defaultValue={moment()}
              format={AppUtils.DATE_FORMAT}
              onChange={(_, dateString) => {
                setStartDate(dateString);
              }}
            />{" "}
            Đến ngày:{" "}
            <DatePicker
              defaultValue={moment()}
              format={AppUtils.DATE_FORMAT}
              onChange={(_, dateString) => {
                setEndDate(dateString);
              }}
            />
          </div>
        </div>
        <ButtonWrapper>
          <Button onClick={getData}>Tra cứu hồ sơ</Button>
        </ButtonWrapper>
      </FilterWrapper>
      <DataTable
        data={records}
        loading={loading}
        columns={columns}
        searchText={searchText}
        isSearchable
      />
      <RecordCollectEdit
        isModalVisible={isModalEditVisible}
        selectedRecord={selectedRecord}
        departmentId={selectedDepartment?.DepartmentID}
        handleAfterSaved={handleAfterSaved}
        handleDetailCancel={handleDetailCancel}
      />
    </PageContainer>
  );
}
export default RecordCollect;
