import { FileTextOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useCallback, useEffect, useState } from "react";
import DataTable from "../../../shared/components/DataTable/DataTable";
import ApiUtils from "../../../utils/ApiUtils";
import AppUtils from "../../../utils/AppUtils";
import LogDetail from "./LogDetail";

function LogByReference({
  referenceId,
  referenceType,
  isModallVisible,
  handleCancel,
}) {
  const [logs, setLogs] = useState([{}]);

  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const columns = [
    {
      title: "STT",
      dataIndex: "RowNumber",
      key: "RowNumber",
    },
    {
      title: "Thao tác",
      dataIndex: "TitleName",
      key: "TitleName",
    },
    {
      title: "Thời gian",
      dataIndex: "RequestDate",
      key: "RequestDate",
    },
    {
      title: "Tài khoản",
      key: "Username",
      render: (_, record) => (
        <>
          {record.Username}
          <br />
          {record.DepartmentName}
        </>
      ),
    },
    {
      title: "IP",
      dataIndex: "HostIP",
      key: "HostIP",
    },
    {
      title: "",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            icon={<FileTextOutlined />}
            onClick={() => openLog(record.LogID)}
          >
            Chi tiết
          </Button>
        </Space>
      ),
    },
  ];

  const getLog = useCallback(() => {
    setSearchText({});
    setLoading(true);
    ApiUtils.logGetByReference(referenceId, referenceType, (responseData) => {
      setLoading(false);
      if (responseData.length === 1 && !responseData[0].Success) {
        if (responseData[0].Message === AppUtils.TOKEN_EXPIRED_MESSAGE) {
          AppUtils.logout();
          return;
        }
        AppUtils.showMessage("Thông báo", responseData[0].Message);
        return;
      }
      setLogs(responseData);
    });
  }, [referenceId, referenceType]);

  useEffect(() => {
    if (!referenceId) {
      return;
    }
    getLog();
  }, [referenceId]);

  //Log-Detail
  const [selectedLog, setSelectedLog] = useState({ LogID: 0 });
  const [isModalDetailVisible, setModalDetailVisible] = useState(false);
  const handleDetailCancel = () => {
    setModalDetailVisible(false);
  };

  const openLog = (id) => {
    ApiUtils.logGetById(id, (responseData) => {
      if (!responseData?.Success) {
        AppUtils.showMessage("Thông báo", responseData.Message);
        return;
      }
      setSelectedLog(responseData);
      setModalDetailVisible(true);
    });
  };

  return (
    <Modal
      title="Chi tiết LOG"
      visible={isModallVisible}
      onCancel={handleCancel}
      maskClosable={true}
      width={1000}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Thoát
        </Button>,
      ]}
    >
      <div className="row">
        <div className="col-md-12">
          <DataTable
            loading={loading}
            data={logs}
            columns={columns}
            searchText={searchText}
            isSearchable
          ></DataTable>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <LogDetail
            isModalDetailVisible={isModalDetailVisible}
            selectedLog={selectedLog}
            handleDetailCancel={handleDetailCancel}
          ></LogDetail>
        </div>
      </div>
    </Modal>
  );
}
export default LogByReference;
