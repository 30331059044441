import React from "react";
import AppUtils from "../../../utils/AppUtils";

function DepartmentAdminDashboard() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-xs-12">
          <div className="page-title-box">
            <h4 className="page-title">Quản trị hệ thống</h4>
            <ol className="breadcrumb p-0 m-0">
              <li>
                <a href="#">{AppUtils.getAuthUser().Fullname}</a>
              </li>
            </ol>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-2 col-md-4 col-sm-6">
          <div className="card-box widget-box-one">
            <i className="mdi mdi-chart-areaspline widget-one-icon"></i>
            <div className="wigdet-one-content">
              <p className="m-0 text-uppercase font-600 font-secondary text-overflow" title="Statistics">Hồ sơ</p>
              <h2>34578 <small><i className="mdi mdi-arrow-up text-success"></i></small></h2>
              <p className="text-muted m-0"><b>Tổng:</b> 30.4k</p>
            </div>
          </div>
        </div>
        <div className="col-lg-2 col-md-4 col-sm-6">
          <div className="card-box widget-box-one">
            <i className="mdi mdi-account-convert widget-one-icon"></i>
            <div className="wigdet-one-content">
              <p className="m-0 text-uppercase font-600 font-secondary text-overflow" title="User Today">Còn hiệu lực</p>
              <h2>895 <small><i className="mdi mdi-arrow-down text-danger"></i></small></h2>
              <p className="text-muted m-0"><b>Tổng:</b> 1250</p>
            </div>
          </div>
        </div>
        <div className="col-lg-2 col-md-4 col-sm-6">
          <div className="card-box widget-box-one">
            <i className="mdi mdi-layers widget-one-icon"></i>
            <div className="wigdet-one-content">
              <p className="m-0 text-uppercase font-600 font-secondary text-overflow" title="User This Month">Chờ tiêu hủy</p>
              <h2>52410 <small><i className="mdi mdi-arrow-up text-success"></i></small></h2>
              <p className="text-muted m-0"><b>Tổng:</b> 40.33k</p>
            </div>
          </div>
        </div>
        <div className="col-lg-2 col-md-4 col-sm-6">
          <div className="card-box widget-box-one">
            <i className="mdi mdi-av-timer widget-one-icon"></i>
            <div className="wigdet-one-content">
              <p className="m-0 text-uppercase font-600 font-secondary text-overflow" title="Request Per Minute">Đã hủy</p>
              <h2>652 <small><i className="mdi mdi-arrow-down text-danger"></i></small></h2>
              <p className="text-muted m-0"><b>Tổng:</b> 956</p>
            </div>
          </div>
        </div>
        <div className="col-lg-2 col-md-4 col-sm-6">
          <div className="card-box widget-box-one">
            <i className="mdi mdi-account-multiple widget-one-icon"></i>
            <div className="wigdet-one-content">
              <p className="m-0 text-uppercase font-600 font-secondary text-overflow" title="Total Users">Đóng băng</p>
              <h2>3245 <small><i className="mdi mdi-arrow-down text-danger"></i></small></h2>
              <p className="text-muted m-0"><b>Tổng:</b> 20k</p>
            </div>
          </div>
        </div>
        <div className="col-lg-2 col-md-4 col-sm-6">
          <div className="card-box widget-box-one">
            <i className="mdi mdi-download widget-one-icon"></i>
            <div className="wigdet-one-content">
              <p className="m-0 text-uppercase font-600 font-secondary text-overflow" title="New Downloads">Văn bản</p>
              <h2>78541 <small><i className="mdi mdi-arrow-up text-success"></i></small></h2>
              <p className="text-muted m-0"><b>Tổng:</b> 50k</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="card-box">
            <h4 className="header-title m-t-0 m-b-30">Tài khoản truy cập trong ngày</h4>
            <div className="table-responsive">
              <table className="table table table-hover m-0">
                <thead>
                  <tr>
                    <th>Tên đăng nhập</th>
                    <th>Họ tên</th>
                    <th>Đơn vị</th>
                    <th>Thời gian</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><img src='assets/SmartAdmin/img/govfiles/ic_customer_16px.png' alt='' />&nbsp;nguyenvana</td>
                    <td>
                      <h5 className="m-0">Nguyễn Văn A</h5>
                      <p className="m-0 text-muted font-13"><small>Quản trị viên</small></p>
                    </td>
                    <td>Ban dân tộc</td>
                    <td>17:00:51</td>
                  </tr>
                  <tr>
                    <td><img src='assets/SmartAdmin/img/govfiles/ic_customer_16px.png' alt='' />&nbsp;nguyenvana</td>
                    <td>
                      <h5 className="m-0">Nguyễn Văn A</h5>
                      <p className="m-0 text-muted font-13"><small>Quản trị viên</small></p>
                    </td>
                    <td>Ban dân tộc</td>
                    <td>17:00:51</td>
                  </tr>
                  <tr>
                    <td><img src='assets/SmartAdmin/img/govfiles/ic_customer_16px.png' alt='' />&nbsp;nguyenvana</td>
                    <td>
                      <h5 className="m-0">Nguyễn Văn A</h5>
                      <p className="m-0 text-muted font-13"><small>Quản trị viên</small></p>
                    </td>
                    <td>Ban dân tộc</td>
                    <td>17:00:51</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="card-box">
            <h4 className="header-title m-t-0 m-b-30">Yêu cầu mở khóa</h4>
            <div className="table-responsive">
              <table className="table table table-hover m-0">
                <thead>
                  <tr>
                    <th>Số biên nhận</th>
                    <th>Tiêu đề</th>
                    <th>Đơn vị</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><img src='assets/SmartAdmin/img/govfiles/ic_lock_16px.png' alt='' />&nbsp;8792200045</td>
                    <td>15.3.22.2</td>
                    <td>PSC Test UBND cấp Huyện</td>
                  </tr>
                  <tr>
                    <td><img src='assets/SmartAdmin/img/govfiles/ic_lock_16px.png' alt='' />&nbsp;8792200045</td>
                    <td>15.3.22.2</td>
                    <td>PSC Test UBND cấp Huyện</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default DepartmentAdminDashboard;