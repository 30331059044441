import React from "react";
import { Button, Input, Modal, Switch } from "antd";

function AdministrativeProcedureDetail({
  selectedAP,
  isModalDetailVisible,
  handleDetailCancel,
}) {
  return (
      <Modal
        title="Thủ tục hành chính"
        width="70%"
        visible={isModalDetailVisible}
        onCancel={handleDetailCancel}
        maskClosable={true}
        footer={[
          <Button key="back" onClick={handleDetailCancel}>
            Thoát
          </Button>,
        ]}
      >
        <br />
        Mã{" "}
        <Input
          name="StorageLifeKey"
          value={selectedAP.APKey}
        />
        <br />
        Tên{" "}
        <Input
          name="StorageLifeName"
          value={selectedAP.APName}
        />
        Chi tiết{" "}
        <table border={2} cellPadding={5} width="100%">
           <thead>
              <tr>
                <td>Key</td>
                <td>Value</td>
              </tr>
           </thead>
           <tbody>
              {
                  selectedAP.Content && 
                  Object.keys(JSON.parse(selectedAP.Content)).map(function (element) {
                     return <tr>
                       <td>{element}</td>
                       <td>{[JSON.parse(selectedAP.Content)[element]]}</td>
                     </tr>;
                  })
              }
           </tbody>
        </table>
      </Modal>
  );
}

export default AdministrativeProcedureDetail;