import React, { useContext } from "react";
import { AppContext } from "../../../contexts";
import AppUtils from "../../../utils/AppUtils";

function HeaderPanel() {
  const handleLogout = () => {
    AppUtils.logout();
  };

  return (
    <header class="page-header" role="banner">
      <div class="page-logo">
        <a
          href="#"
          class="page-logo-link press-scale-down d-flex align-items-center position-relative"
          data-toggle="modal"
          data-target="#modal-shortcut"
        >
          <img
            src="/assets/SmartAdmin/img/govfiles/logo.png"
            alt="SmartAdmin WebApp"
            aria-roledescription="logo"
          ></img>
          <span class="page-logo-text mr-1">SmartAdmin WebApp</span>
          <span class="position-absolute text-white opacity-50 small pos-top pos-right mr-2 mt-n2"></span>
          <i class="fal fa-angle-down d-inline-block ml-1 fs-lg color-primary-300"></i>
        </a>
      </div>
      {/* DOC: nav menu layout change shortcut */}
      <div class="hidden-md-down dropdown-icon-menu position-relative">
        <a
          href="#"
          class="header-btn btn js-waves-off"
          data-action="toggle"
          data-class="nav-function-hidden"
          title="Hide Navigation"
        >
          <i class="ni ni-menu"></i>
        </a>
        <ul>
          <li>
            <a
              href="#"
              class="btn js-waves-off"
              data-action="toggle"
              data-class="nav-function-minify"
              title="Minify Navigation"
            >
              <i class="ni ni-minify-nav"></i>
            </a>
          </li>
          <li>
            <a
              href="#"
              class="btn js-waves-off"
              data-action="toggle"
              data-class="nav-function-fixed"
              title="Lock Navigation"
            >
              <i class="ni ni-lock-nav"></i>
            </a>
          </li>
        </ul>
      </div>
      {/* DOC: mobile button appears during mobile width */}
      <div class="hidden-lg-up">
        <a
          href="#"
          class="header-btn btn press-scale-down"
          data-action="toggle"
          data-class="mobile-nav-on"
        >
          <i class="ni ni-menu"></i>
        </a>
      </div>

      <div class="ml-auto d-flex">
        {/* app user menu */}
        <div>
          <a
            href="#"
            data-toggle="dropdown"
            title="drlantern@gotbootstrap.com"
            class="header-icon d-flex align-items-center justify-content-center ml-2"
          >
            <img
              src="/assets/SmartAdmin/img/govfiles/avatars/avatar-admin.png"
              class="profile-image rounded-circle"
              alt=""
            ></img>
          </a>
          <div class="dropdown-menu dropdown-menu-animated dropdown-lg">
            <div class="dropdown-header bg-trans-gradient d-flex flex-row py-4 rounded-top">
              <div class="d-flex flex-row align-items-center mt-1 mb-1 color-white">
                <span class="mr-2">
                  <img
                    src="/assets/SmartAdmin/img/govfiles/avatars/avatar-admin.png"
                    class="rounded-circle profile-image"
                    alt=""
                  ></img>
                </span>
                <div class="info-card-text">
                  <div class="fs-lg text-truncate text-truncate-lg">
                    {AppUtils.getAuthUser()?.Fullname}
                  </div>
                  <span class="text-truncate text-truncate-md opacity-80">
                    {AppUtils.getAuthUser()?.Email}
                  </span>
                </div>
              </div>
            </div>
            <div class="dropdown-divider m-0"></div>
            {/* <a href="#" class="dropdown-item" data-action="app-reset">
              <span data-i18n="drpdwn.reset_layout">Reset Layout</span>
            </a>
            <a
              href="#"
              class="dropdown-item"
              data-toggle="modal"
              data-target=".js-modal-settings"
            >
              <span data-i18n="drpdwn.settings">Settings</span>
            </a> */}
            <div class="dropdown-divider m-0"></div>
            <a href="#" class="dropdown-item" data-action="app-fullscreen">
              <span data-i18n="drpdwn.fullscreen">Toàn màn hình</span>
              <i class="float-right text-muted fw-n">F11</i>
            </a>
            <a href="#" class="dropdown-item" data-action="app-print">
              <span data-i18n="drpdwn.print">In</span>
              <i class="float-right text-muted fw-n">Ctrl + P</i>
            </a>
            {/* <div class="dropdown-multilevel dropdown-multilevel-left">
              <div class="dropdown-item">Language</div>
              <div class="dropdown-menu">
                <a
                  href="#?lang=fr"
                  class="dropdown-item"
                  data-action="lang"
                  data-lang="fr"
                >
                  Français
                </a>
                <a
                  href="#?lang=en"
                  class="dropdown-item active"
                  data-action="lang"
                  data-lang="en"
                >
                  English (US)
                </a>
                <a
                  href="#?lang=es"
                  class="dropdown-item"
                  data-action="lang"
                  data-lang="es"
                >
                  Español
                </a>
                <a
                  href="#?lang=ru"
                  class="dropdown-item"
                  data-action="lang"
                  data-lang="ru"
                >
                  Русский язык
                </a>
                <a
                  href="#?lang=jp"
                  class="dropdown-item"
                  data-action="lang"
                  data-lang="jp"
                >
                  日本語
                </a>
                <a
                  href="#?lang=ch"
                  class="dropdown-item"
                  data-action="lang"
                  data-lang="ch"
                >
                  中文
                </a>
              </div>
            </div> */}
            <div class="dropdown-divider m-0"></div>
            <a class="dropdown-item fw-500 pt-3 pb-3" onClick={handleLogout}>
              <span>Đăng xuất</span>
              <span class="float-right fw-n"></span>
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}

export default HeaderPanel;
